import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion, AccordionSummary, useStyles, TT } from "./helpers";
import { Grid } from "@material-ui/core";

export default function Team() {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Avatar
        alt="dr hab. Gabriela Dziamska-Lenart, prof. UAM"
        src={process.env.PUBLIC_URL + "/images/team/A.jpg"}
        className={classes.avatar}
      />
      <CardContent>
        <Typography variant="h6" component="h2">
          dr hab. Gabriela Dziamska-Lenart, prof. UAM
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Pracownia Leksykograficzna
          <br />
          Uniwersytet im. Adama Mickiewicza w Poznaniu
        </Typography>
      </CardContent>

      <CardActions>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          href="mailto:gabriela.dziamska-lenart@amu.edu.pl"
        >
          E-mail
        </Button>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          href="https://orcid.org/0000-0002-6484-0557"
          target="_blank"
        >
          ORCID
        </Button>
      </CardActions>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Więcej</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Card className={classes.photoCard}>
                <img
                  className={classes.photo}
                  alt="zdjęcie uczestnika"
                  src={process.env.PUBLIC_URL + "/images/team/A1.jpg"}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="justify"
                className={classes.personDesc}
              >
                Można o mnie powiedzieć, że jestem poznańską pyrą, bo całe moje
                życie związane jest z Poznaniem i okolicą.
                <TT
                  title={
                    <>
                      <i>ktoś</i> wyssał <i>coś</i> z mlekiem matki: ‘ktoś po
                      rodzicach odziedziczył cechy charakteru, predyspozycje,
                      usposobienie itd.’
                    </>
                  }
                  label="Z mlekiem matki wyssałam"
                />
                {" "}powołanie nauczycielskie, a po tacie odziedziczyłam
                zainteresowania polonistyczne i całą szafę słowników, dzięki
                którym od 40 lat
                <TT
                  title={
                    <>
                      <i>ktoś</i> jest z <i>czymś</i> za pan brat: ‘ktoś zna się
                      na czymś bardzo dobrze, jest specjalistą w jakiejś
                      dziedzinie’
                    </>
                  }
                  label="jestem z frazeologią za pan brat"
                />
                . Na drugie imię mam{" "}
                <TT
                  title={
                    <>Zosia Samosia: ‘samodzielna, niezależna kobieta, indywidualistka’</>
                  }
                  label="Zosia Samosia"
                />{" "}
                i od dzieciństwa, poznając świat, lubiłam{" "}
                <TT
                  title={
                    <>
                      <i>ktoś</i>{" "}
                      chodzi własnymi / swoimi ścieżkami / drogami: ‘ktoś
                      kieruje się w życiu własnymi zasadami, nie słucha rad
                      innych, jest niezależny’
                    </>
                  }
                  label="chodzić własnymi ścieżkami"
                />
                . Jako Sophia poszukuję w życiu mądrości, kierując się na
                przemian raz{" "}
                <TT
                  title={<>głos serca: ‘uczucia, emocje’</>}
                  label="głosem serca"
                />
                , raz{" "}
                <TT
                  title={<>głos rozumu: ‘racjonalna ocena sytuacji’</>}
                  label="głosem rozumu"
                />
                . Wyznaję zasadę{" "}
                <TT
                  title={<>złoty środek: ‘równowaga, brak skrajności’</>}
                  label="złotego środka"
                />
                i szukam równowagi w codziennym życiu, łącząc rolę{" "}
                <TT
                  title={
                    <>
                      strażniczka domowego ogniska: ‘żona, matka, kobieta
                      dbająca o spokój w rodzinie, pani domu’
                    </>
                  }
                  label="strażniczki domowego ogniska"
                />
                , matki, żony i nauczycielki, badaczki języka polskiego i od
                niedawna profesorki UAM. Nieustannie zadziwiają mnie zmiany w
                języku i wraz ze studentami staram się je odkrywać, a opisując
                je,{" "}
                <TT
                  title={
                    <>
                      <i>ktoś</i> idzie z duchem czasu: ‘ktoś jest
                      nowoczesny, zna najnowsze, popularne zwyczaje’
                    </>
                  }
                  label="iść z duchem czasu"
                />
                .
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
