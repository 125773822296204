import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import HomeIcon from "@material-ui/icons/Home";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import WorkIcon from "@material-ui/icons/Work";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import { Button } from "@material-ui/core";
import { useEffect } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoImg: {
      margin: "auto",
      display: "block",
      maxWidth: "300px",
      maxHeight: "100px",
    },
    button: {
      margin: theme.spacing(1),
    },
    lectureImg: {
      margin: "auto",
      marginBottom: 2,
      display: "block",
      maxWidth: "60%",
      borderRadius: 8,
      border: "1px solid black",
      borderColor: "rgba(0,0,0,0.2)",
    },
    wrapIcon: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "& > svg": {
        marginRight: 10,
      },
    },
  })
);

function PaperVocabulary() {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Interaktywne ćwiczenia";
  }, []);

  return (
    <>
      <Typography variant="h5" component="h1">
        Interaktywne ćwiczenia
      </Typography>
      <Typography variant="subtitle2" gutterBottom style={{ marginBottom: 20 }}>
        zestaw ćwiczeń przygotowanych przez
        <br />
        dr Zuzanę Obertovą oraz dr Magdalenę Zakrzewską-Verdugo
      </Typography>

      <Card>
        <CardContent style={{ padding: 40, paddingBottom: 20 }}>
          <Typography variant="h6" className={classes.wrapIcon}>
            <HomeIcon /> Dach nad głową
          </Typography>
          <Typography variant="body1" className={classes.wrapIcon}>
            ćwiczenia na poziomie A1
          </Typography>
          <Typography variant="body2" className={classes.wrapIcon} gutterBottom>
            dr Zuzana Obertová
          </Typography>
          <br />
          <Typography variant="body2" align="justify">
            Lekcja jest przeznaczona dla uczących się języka polskiego jako
            obcego na poziomie A1. Tematycznie jest związana z mieszkaniem,
            szukaniem i urządzaniem mieszkania – może zatem stanowić materiał
            uzupełniający do zajęć na ten temat. Z jednej strony uczący się mają
            okazję, żeby powtórzyć słownictwo dotyczące mieszkania, z drugiej
            strony poznają dziesięć związków frazeologicznych, osadzonych w
            danym kontekście. Ponieważ chodzi o podstawowy poziom znajomości
            języka polskiego jako obcego, frazeologizmy zostały dobrane ze
            względu na jasną motywację ich znaczenia oraz stosunkowo proste
            komponenty, z których większość należy do zakresu zagadnień
            leksykalnych dla poziomu A1.
            <br />
            <br />
            Poszczególne ćwiczenia rozwijają przede wszystkim umiejętność
            rozumienia tekstu pisanego. Ćwiczenie IV zostało uzupełnione o
            nagranie dialogu, można go zatem wykorzystać zarówno jako ćwiczenie
            do czytania, jak i do słuchania. Ćwiczenia VI – IX łączą czytanie z
            gramatyką, są one powtórzeniem odmiany czasowników w czasie
            teraźniejszym. Ostatnie ćwiczenie rozwija kompetencję pisania w
            języku polskim.
            <br />
            <br />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<DirectionsWalkIcon />}
              href="#/cwiczenia-a1"
            >
              Przejdź do ćwiczeń
            </Button>
          </Typography>
          <br />
          <br />
          <hr />
          <br />
          <br />

          <Typography variant="h6" className={classes.wrapIcon}>
            <FlightTakeoffIcon /> Wakacyjne podróże
          </Typography>
          <Typography variant="body1" className={classes.wrapIcon}>
            ćwiczenia na poziomie A2/B1
          </Typography>
          <Typography variant="body2" className={classes.wrapIcon} gutterBottom>
            dr Magdalena Zakrzewska-Verdugo
          </Typography>
          <br />
          <Typography variant="body2" align="justify">
            Niniejsza lekcja jest przeznaczona do wykorzystania ze studentami na
            poziomie zaawansowania A2/B1. Jest ona zaplanowana jako nawiązanie
            do lekcji pod tym samym tytułem znajdującej się w publikacji Polski
            na A. Część II. Podręcznik języka polskiego dla Słowaków (2014)
            autorstwa Moniki Krzempek. Lekcja ta tematycznie związana jest z
            turystyką, a także rozszerza zasób słownictwa studentów przede
            wszystkim o aspekt frazeologiczny. Cała lekcja oparta jest na jednym
            adaptowanym tekście autentycznym dotyczącym ekoturystyki.
            <br />
            <br />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<DirectionsWalkIcon />}
              href="#/cwiczenia-a2-b1"
            >
              Przejdź do ćwiczeń
            </Button>
          </Typography>
          <br />
          <br />
          <hr />
          <br />
          <br />

          <Typography variant="h6" className={classes.wrapIcon}>
            <RecordVoiceOverIcon /> Dyskutant pełną gębą
          </Typography>
          <Typography variant="body1" className={classes.wrapIcon}>
            ćwiczenia na poziomie B2
          </Typography>
          <Typography variant="body2" className={classes.wrapIcon} gutterBottom>
            dr Magdalena Zakrzewska-Verdugo
          </Typography>
          <br />
          <Typography variant="body2" align="justify">
            Niniejsza lekcja przeznaczona jest dla studentów na poziomie
            zaawansowania językowego B2. Koncentruje się przede wszystkim na
            kształceniu sprawności mówienia, zarówno na podstawie wypowiedzi
            monologowej, dyskusji, jak i komentarza do przeczytanych tekstów. W
            lekcji pojawia się również bogata frazeologia, z którą uczniowie
            zostają zapoznani w ramach poszerzania zasobu słownictwa.
            <br />
            <br />
            Tematem lekcji są problemy dyskusyjne, kontrowersyjne, dlatego też
            warto wprowadzić przed właściwą częścią zajęć pewne reguły dotyczące
            dyskusji, aby uniknąć nieprzyjemnych sytuacji. Przede wszystkim
            należy powiedzieć uczestnikom, że ich poglądy nie będą oceniane pod
            względem etycznym, a jedynie pod względem poprawności językowej, a
            także poprosić o szacunek do innych dyskutantów, nie obrażanie i nie
            wyśmiewanie się nawzajem. Oczywiście lektor jako moderator powinien
            stanowić tu wzór zachowania i, o ile to możliwe, wstrzymać się od
            jakichkolwiek stronniczych wypowiedzi.
            <br />
            <br />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<DirectionsWalkIcon />}
              href="#/cwiczenia-b2"
            >
              Przejdź do ćwiczeń
            </Button>
          </Typography>
          <br />
          <br />
          <hr />
          <br />
          <br />

          <Typography variant="h6" className={classes.wrapIcon}>
            <WorkIcon /> Język biznesowy
          </Typography>
          <Typography variant="body1" className={classes.wrapIcon}>
            ćwiczenia na poziomie C1
          </Typography>
          <Typography variant="body2" className={classes.wrapIcon} gutterBottom>
            dr Magdalena Zakrzewska-Verdugo
          </Typography>
          <br />
          <Typography variant="body2" align="justify">
            Niniejsza lekcja przeznaczona jest dla studentów uczących się języka
            polskiego na poziomie zaawansowania C1. Jest ona zaplanowana na
            jednostkę lekcyjną trwającą co najmniej 90 min. Tematycznie
            koncentruje się na pracy, a przede wszystkim na stylu urzędowym
            wykorzystywanym w dokumentach takich, jak umowa o pracę. Pojawia się
            w niej również zagadnienie poprawnego zapisu skrótów, użycie
            łączników zdaniowych, leksyka związana z planem dnia w firmie oraz
            frazeologia, dzięki której następuje przejście od stylu urzędowego
            do stylu potocznego związanego ze swobodnym opowiadaniem o swoim
            harmonogramie zajęć i komunikacją w zespole.
            <br />
            <br />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<DirectionsWalkIcon />}
              href="#/cwiczenia-c1"
            >
              Przejdź do ćwiczeń
            </Button>
          </Typography>
          <br />
          <br />
          <hr />
          <br />
          <br />

          <Typography variant="h6" className={classes.wrapIcon}>
            <WhatshotIcon /> Igraszki z ogniem w przekładzie literackim
          </Typography>
          <Typography variant="body1" className={classes.wrapIcon}>
            ćwiczenia na poziomie C1
          </Typography>
          <Typography variant="body2" className={classes.wrapIcon} gutterBottom>
            dr Zuzana Obertová
          </Typography>
          <br />
          <Typography variant="body2" align="justify">
            Proponowany materiał dydaktyczny na temat frazeologii w przekładzie
            literackim jest przeznaczony dla osób uczących się języka polskiego
            jako obcego na poziomie celującym w C1. Lekcja przedstawia
            podstawowe strategie w tłumaczeniu kanonicznych postaci
            frazeologizmów i przygotowuje studentów do ich rozpoznania w tekście
            literackim.
            <br />
            <br />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<DirectionsWalkIcon />}
              href="#/cwiczenia-igraszki-z-ogniem"
            >
              Przejdź do ćwiczeń
            </Button>
          </Typography>
          <br />
        </CardContent>
      </Card>
    </>
  );
}

export default PaperVocabulary;
