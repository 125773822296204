import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { useEffect } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoImg: {
      margin: "auto",
      display: "block",
      maxWidth: "300px",
      maxHeight: "100px",
    },
    button: {
      margin: theme.spacing(1),
    },
    lectureImg: {
      margin: "auto",
      marginBottom: 2,
      display: "block",
      maxWidth: "400px",
      borderRadius: 8,
      border: "1px solid black",
      borderColor: "rgba(0,0,0,0.2)",

      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
    },
  })
);

function PaperVocabulary() {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Słownik frazeologiczny z ćwiczeniami";
  }, []);

  return (
    <>
      <Typography variant="h5" component="h1">
        Słownik frazeologiczny z ćwiczeniami
      </Typography>
      <Typography variant="subtitle2" gutterBottom style={{ marginBottom: 20 }}>
        dla uczących się języka polskiego jako obcego
      </Typography>

      <Card>
        <CardContent style={{ padding: 40, paddingBottom: 20 }}>
          <Typography variant="h6" noWrap>
            <img
              alt="Okładka słownika"
              className={classes.lectureImg}
              // style={{ width: 400 }}
              src={
                process.env.PUBLIC_URL + "/images/paper-vocabulary/okladka.jpg"
              }
            />
          </Typography>
          <br />
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<MenuBookIcon />}
            href="https://www.wydawnictworys.com/media/products/1dd91cf45af225ded23cc589936fe7f0/attachments/pl_PL/slownik-frazeologiczny-z-cwiczeniami-dla-uczacych-sie-jezyka-polskiego-jako-obcego-online-format-pdf.pdf"
          >
            Słownik w formacie PDF
          </Button>
          <br />
          <br />
          <Typography paragraph align="justify">
            Wiadomo, że związki frazeologiczne pełnią ważne funkcje językowe:
            pragmatyczną, ekspresywną, informacyjną oraz artystyczną.
            Frazeologizmy pozwalają także na przekazanie cennych doświadczeń
            kulturowych, informacji związanych z historią, obyczajowością,
            systemem wartości i sposobem interpretowania świata typowym dla
            danej społeczności językowej.
            <br />
            <br />
            Uważamy, że propagowanie kulturowych i stylistycznych aspektów
            nauczania frazeologii odgrywa niebagatelną rolę w przybliżaniu
            studentom nie tylko języka polskiego, lecz także historii, kultury
            naszego narodu oraz w ułatwianiu im swobodnego funkcjonowania wśród
            Polaków.
            <br />
            <br />
            Można założyć, że większość podręczników przeznaczonych do
            praktycznej nauki języka polskiego jako obcego kształci w zakresie
            tzw. kompetencji frazeologicznej (frazeologia związana z częściami
            ciała, domem rodzinnym, naturą itd.). Brakuje jednak wykazu
            najnowszych, współczesnych frazeologizmów, którymi posługują się
            natywni użytkownicy języka polskiego w codziennej komunikacji. Nie
            dysponujemy gotowym, ogólnodostępnym wykazem takich połączeń
            językowych. Liczba opracowań wykorzystujących frazeologię w
            nauczaniu języka polskiego jako obcego nie jest duża, nie ma
            aktualnych materiałów odpowiadających na potrzeby cudzoziemców.
            <br />
            <br />W odpowiedzi na te braki powstał nasz pomysł, by stworzyć{" "}
            <i>
              Słownik frazeologiczny z ćwiczeniami dla uczących się języka
              polskiego jako obcego
            </i>
            . Uznaliśmy bowiem, że metodycznie uzasadnione jest uczenie tego, co
            potrzebne. Przedmiotem i nauczania powinny stać się frazeologizmy
            należące do różnych kręgów tematyczno-pojęciowych związanych np. z
            opisem wyglądu zewnętrznego, oceną cech charakteru, określenia
            należące do takich pól tematycznych, jak: praca, dom, rodzina itp.,
            czyli te podane w katalogu tematycznym (Katalogu B) dla poziomu
            znajomości JP B1 w Standardach Wymagań Egzaminacyjnych. Stworzony
            przez nas podział tematyczny zawiera te pola, z których uczący się
            języka polskiego jako obcego poznają na różnych poziomach
            zaawansowania (A1-C1).
            <br />
            <br />
            Leksykon gromadzi około 1700 związków frazeologicznych umieszczonych
            w trzydziestu polach tematycznych. Uprościliśmy mikrostrukturę
            artykułu hasłowego tak, by osoba ucząca się języka polskiego jako
            obcego z łatwością mogła się nim posługiwać. Indeks ilustruje
            bogactwo polskiej frazeologii i jest dowodem na kulturowe jej
            uwarunkowanie (dwukierunkowe). Dołączone ćwiczenia mogą inspirować
            lektorów do twórczego wykorzystywania frazeologii w dydaktyce języka
            polskiego jako obcego.
            <br />
            <br />
            Artykuł hasłowy w słowniku składa się z następujących elementów:
            <br />
            1. hasło (postać hasłowa frazeologizmu)
            <br />
            2. wariant hasła (przy frazeologizmach wielokształtnych)
            <br />
            3. kwalifikator (przy frazeologizmach nacechowanych stylistycznie)
            <br />
            4. definicja znaczenia
            <br />
            5. dodatkowa informacja pragmalingwistyczna (przy frazeologizmach
            ekspresywnych, przy związkach wyrazowych używanych w szczególnych
            sytuacjach komunikacyjnych lub występujących najczęściej w
            określonej formie gramatycznej)
            <br />
            6. przykład użycia (niektóre artykuły hasłowe zawierają po dwa
            cytaty. Dzieje się tak w przypadku haseł odnoszących się do osób i
            do rzeczy oraz w przypadku haseł z członami wariantywnymi
            (zapisywanymi po ukośniku). Jeśli odnotowujemy więcej niż jeden
            przykład użycia frazeologizmu, oddzielamy je od siebie znakiem #).
            <br />
            Przykładowe artykuły hasłowe prezentujemy poniżej.
            <br />
            <img
              alt="przykładowy artykuł 1"
              className={classes.lectureImg}
              src={process.env.PUBLIC_URL + "/images/paper-vocabulary/1.png"}
            />
            <img
              alt="przykładowy artykuł 2"
              className={classes.lectureImg}
              src={process.env.PUBLIC_URL + "/images/paper-vocabulary/2.jpg"}
            />
            <img
              alt="przykładowy artykuł 3"
              className={classes.lectureImg}
              src={process.env.PUBLIC_URL + "/images/paper-vocabulary/3.jpg"}
            />
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default PaperVocabulary;
