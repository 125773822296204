import Typography from "@material-ui/core/Typography";
import MessageIcon from "@material-ui/icons/Message";
import SubjectIcon from "@material-ui/icons/Subject";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import InfoIcon from "@material-ui/icons/Info";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { useStyles, getModalStyle } from "./helpers";
import { Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Lecture1 from "../Recordings/Lecture1";
import { useState } from "react";

function A() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.container}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-with-lecture"
        aria-describedby="lecture-recording-with-presentation"
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={classes.close} onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <Lecture1 />
        </div>
      </Modal>

      <Typography variant="h6" noWrap>
        <img
          alt="Zdjęcie z wykładu"
          className={classes.lectureImg}
          src={process.env.PUBLIC_URL + "/images/lectures/1_zdj.jpg"}
        />
      </Typography>
      <br />
      <Typography paragraph align="left">
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <SubjectIcon />{" "}
          <b>
            <i>Wprowadzenie do polskiej frazeologii</i>
          </b>
        </Typography>
        dr hab. Gabriela Dziamska-Lenart, prof. UAM
        <Typography variant="body2">
          30.10.2020, Uniwersytet Komeńskiego, wykład
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h2"
          className={classes.wrapIcon}
          gutterBottom
        >
          <OndemandVideoIcon />{" "}
          <b>
            <i>Nagranie wykładu</i>
          </b>
        </Typography>
        <Typography variant="body2">
          <Button variant="outlined" color="primary" onClick={handleOpen}>
            <PlayCircleOutlineIcon /> &nbsp; Wykład + prezentacja
          </Button>
        </Typography>
        <br />
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <InfoIcon /> Opis
        </Typography>
        <Typography variant="body1" align="justify">
          Wykład inaugurujący projekt to pierwsze spotkanie z planowanego cyklu
          warsztatów w Bratysławie dla studentów uczących się języka polskiego w
          ramach studiów środkowoeuropejskich na Uniwersytecie Komeńskiego.
          Celem zajęć było zapoznanie studentów na różnych poziomach
          zaawansowania (B1-C1) z podstawowymi zagadnieniami frazeologii jako
          nauki oraz zwiększenie ich „kompetencji frazeologicznej” prowadzącej
          do wzrostu kompetencji komunikacyjnej ze względu na to, że znajomość
          frazeologii, obecnej w codziennych kontaktach i w różnorodnych
          tekstach kultury popularnej, umożliwia obcokrajowcom zrozumienie
          zachowań językowych rodzimych użytkowników języka i aktywne
          uczestnictwo w życiu danej wspólnoty językowej.
        </Typography>
        <br />
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <MessageIcon /> Z anonimowych ankiet studenckich
        </Typography>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Mnie się podobało. Zajęcia są bardzo interesujące, frazeologizmy
          zabawne. Myślę, że nauczyłam się czegoś nowego. Im więcej słucham
          języka polskiego, tym bardziej mi się podoba”.
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Bardzo ciekawe zajęcia, bardzo mi się podobała część teoretyczna, bo
          interesuje mnie frazeologia.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Student</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Zajęcia były interesujące, wielu nowych rzeczy nauczyłam się i bardzo
          mi się podobało, że lekcje prowadziła pani profesor z Uniwersytetu
          Adama Mickiewicza w Poznaniu. Moja wiedza o frazeologii z pewnością
          wzrosła i znów mogłam zobaczyć, że języki polski i słowacki są dość
          podobne.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Zajęcia były bardzo ciekawe, nauczyłam się wielu frazeologizmów,
          których wcześniej nie słyszałam / znałam i to poszerzyło moje
          słownictwo. Wiele z frazeologizmów było bardzo zabawnych.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
      </Typography>
    </div>
  );
}

export default A;
