import { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import A from "./A";
import B from "./B";
import C from "./C";
import D from "./D";
import E from "./E";
import F from "./F";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userCard: {
      margin: "0 5px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  })
);

export default function Team() {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Zespół";
  }, []);

  return (
    <>
      <Typography variant="h4" component="h1">
        Zespół
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        osoby, które brały udział w projekcie
      </Typography>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        alignContent="flex-start"
        alignItems="flex-start"
        p={1}
        m={1}
        style={{ maxWidth: "100%" }}
      >
        <Box
          alignSelf="flex-start"
          className={classes.userCard}
          id="dziamska-lenart"
        >
          <A />
        </Box>
        <Box alignSelf="flex-start" className={classes.userCard} id="flicinski">
          <B />
        </Box>
        <Box alignSelf="flex-start" className={classes.userCard} id="obertova">
          <C />
        </Box>
        <Box
          alignSelf="flex-start"
          className={classes.userCard}
          id="wozniak-wrzesinska"
        >
          <D />
        </Box>
        <Box
          alignSelf="flex-start"
          className={classes.userCard}
          id="zakrzewska-verdugo"
        >
          <E />
        </Box>
        <Box alignSelf="flex-start" className={classes.userCard} id="zgrzywa">
          <F />
        </Box>
      </Box>
    </>
  );
}
