import {
  withStyles,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordion from "@material-ui/core/Accordion";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Chip from "@material-ui/core/Chip";

export const Accordion = withStyles({
  root: {
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      border: "none",
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    avatar: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      margin: "20px auto 0",
    },
    photoCard: {
      fontSize: 0,
      marginRight: 16,
      [theme.breakpoints.down("xs")]: {
        width: "50%",
        margin: "auto",
        marginBottom: 20,
      },
    },
    photo: {
      width: "100%",
      margin: "0",
    },
    card: {
      maxWidth: 560,
      margin: "auto",
      marginBottom: 20,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    button: {
      color: "#2196F3",
    },
    tt: {
      fontSize: 14,
      background: "rgba(60, 60, 60, 0.9)",
      border: "1px solid rgba(255, 255, 255, 0.2)",
      fontWeight: 400,
      padding: 10,
    },
    personDesc: {
      "& .MuiChip-root": {
        borderRadius: 4,
        "& > span": {
          padding: 3,
        },
      },
    },
  })
);

export const TT = ({ title, label }: { title: any; label: string }) => {
  const classes = useStyles();
  return (
    <Tooltip
      TransitionComponent={Zoom}
      title={title}
      placement="top"
      classes={{ tooltip: classes.tt }}
      enterTouchDelay={1}
      leaveTouchDelay={5000}
    >
      <Chip label={label} variant="outlined" size="small" />
    </Tooltip>
  );
};
