import Typography from "@material-ui/core/Typography";
import MessageIcon from "@material-ui/icons/Message";
import SubjectIcon from "@material-ui/icons/Subject";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import InfoIcon from "@material-ui/icons/Info";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { useStyles, getModalStyle } from "./helpers";
import { Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Lecture6 from "../Recordings/Lecture6";
import Lecture7 from "../Recordings/Lecture7";
import { useState } from "react";

function D() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [modalStyle] = useState(getModalStyle);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
  };

  return (
    <div className={classes.container}>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-with-lecture"
        aria-describedby="lecture-recording-with-presentation"
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={classes.close} onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <Lecture6 />
        </div>
      </Modal>

      <Modal
        open={open2}
        onClose={handleClose}
        aria-labelledby="modal-with-lecture"
        aria-describedby="lecture-recording-with-presentation"
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={classes.close} onClick={() => setOpen2(false)}>
            <CloseIcon />
          </div>
          <Lecture7 />
        </div>
      </Modal> */}

      <Typography variant="h6" noWrap>
        {/* <img
          alt="Zdjęcie z wykładu"
          className={classes.lectureImg}
          src={process.env.PUBLIC_URL + "/images/lectures/4_zdj.jpg"}
        /> */}
      </Typography>
      <br />
      <Typography paragraph align="left">
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <SubjectIcon />{" "}
          <b>
            <i>Wprowadzenie do leksykografii frazeologicznej</i>
          </b>
        </Typography>
        dr Piotr Fliciński
        <Typography variant="body2">
          4-5.03.2021, Uniwersytet Komeńskiego, warsztaty
        </Typography>
        <br />
        {/* <Typography
          variant="h6"
          component="h2"
          className={classes.wrapIcon}
          gutterBottom
        >
          <OndemandVideoIcon />{" "}
          <b>
            <i>Nagranie wykładu</i>
          </b>
        </Typography>
        <Typography variant="body2">
          <Button variant="outlined" color="primary" onClick={handleOpen}>
            <PlayCircleOutlineIcon /> &nbsp; 1. Wykład + prezentacja
          </Button>
          <br />
          <br />
          <Button variant="outlined" color="primary" onClick={handleOpen2}>
            <PlayCircleOutlineIcon /> &nbsp; 2. Wykład + prezentacja
          </Button>
        </Typography> 
        <br />*/}
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <InfoIcon /> Opis
        </Typography>
        <Typography variant="body1" align="justify">
          Zajęcia miały charakter teoretyczny i praktyczny, zostały zaplanowane
          na 8 godzin dydaktycznych. Wykłady (2 x 45) przybliżyły studentom
          słowackim najbardziej charakterystyczne przykłady opisu
          frazeograficznego, a także etapy jego tworzenia: wyodrębnienie
          frazeologizmu z tekstu, zebranie materiału ilustracyjnego,
          wyabstrahowanie znaczenia (znaczeń) frazeologizmu, tworzenie formy
          słownikowej jednostki leksykalnej oraz mikrostruktury hasła
          słownikowego. Podsumowaniem części wykładowej były przykłady
          porównawcze haseł pochodzących z różnych współczesnych słowników
          internetowych języka polskiego. Na zakończenie ukazane zostały
          możliwości w zakresie układu makrostrukturalnego przy odniesieniu do
          wybranych frazeologizmów. Po tych warsztatach studenci otrzymali
          bowiem wykaz frazeologizmów (wraz z ich uproszczonymi definicjami i
          przykładami użyć), których odpowiedników przyszło im poszukiwać. Te
          frazeologizmy oraz sposoby objaśniania nie były dla studentów zupełnie
          nowe, ponieważ sukcesywnie pojawiały się podczas wcześniejszych zajęć.
          Celem dwudniowych warsztatów było uświadomienie uczestnikom, że sami
          byli w stanie stworzyć analogiczne – słowackie – artykuły hasłowe.
        </Typography>
        <br />
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <MessageIcon /> Z anonimowych ankiet studenckich
        </Typography>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Dziękuję bardzo za frazeologizmy slangowe!”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Student</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Na tym wykładzie nauczyłam się bardzo wielu nowych informacji na
          temat pracy ze słownikami. Szczególnie pomocne było dowiedzieć się
          więcej o strukturze. Po zajęciach, stało się znacznie łatwiejsze
          orientować się podczas pracy ze słownikami. Nawet gdy niektóre
          informacje były trochę trudne do zrozumienia na początku, przykłady i
          praktyczne zadania z wyszukiwaniem frazeologizmów w tekstach bardzo
          pomogły “uporządkować” zdobytą wiedzę.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Zajęcia się mi bardzo podobały, chociaż czasami były trochę trudne.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Student</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Należy mi stwierdzić, że zajęcia jawiły mi się bardzo ciekawe. Były
          prowadzone w sposób, aby człowiek właściwie zrozumiał zakres, o którym
          była mowa. Oceniając przedmiot obecnie, przyznaję, że moje
          umiejętności ze względu na słowniki zostały po prowadzonych zajęciach
          podniesione.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Student</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Bardzo ciekawe zajęcia i podoba mi się to, że pan doktor pokazał dużo
          online i papierowych słowników i sposób, jak działają.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Student</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Zajęcia były bardzo interesujące. Dziękuję bardzo.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          Zajęcia były bardzo interesujące. Nauczyłam się czegoś nowego.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Zajęcia dotąd były interesujące i czegoś nowego dowiedziałam się o
          słownikach oraz o tym, jak szukać informacji i orientować się w nich.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Dziękuje za zajęcia, były bardzo ciekawe. Lektor był bardzo zabawny i
          miły. ”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Student</div>
          </div>
        </Paper>
      </Typography>
    </div>
  );
}

export default D;
