import { useEffect, useState } from "react";
import { Card, useMediaQuery, useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import YouTube, { Options } from "react-youtube";
import { useStyles } from "../Lectures/helpers";

const videoId = "jZBrn24wbQM";
const lectureNo = 2;
const slidesSeconds = [
  { s: 1, from: 397, to: 420 },
  { s: 2, from: 420, to: 443 },
  { s: 3, from: 1419, to: 1495 },
  { s: 4, from: 2434, to: 2486 },
  { s: 5, from: 2486, to: 2634 },
  { s: 6, from: 2711, to: 2784 },
  { s: 7, from: 2878, to: 2935 },
  { s: 8, from: 3132, to: 3181 },
  { s: 9, from: 3528, to: 3576 },
  { s: 10, from: 4266, to: 4286 },
  { s: 11, from: 4312, to: 4424 },
  { s: 12, from: 4424, to: 4859 },
  { s: 13, from: 4984, to: 5393 },
  { s: 14, from: 6296, to: 6713 },
  { s: 15, from: 6713, to: 6871 },
  { s: 16, from: 6871, to: 7077 },
  { s: 17, from: 7229, to: 7326 },
  { s: 18, from: 8373, to: 8550 },
  { s: 19, from: 11855, to: 11874 },
];

const handleDragStart = (e: any) => e.preventDefault();
const images = slidesSeconds.map((s) => (
  <img
    src={process.env.PUBLIC_URL + `/slides/${lectureNo}/${s.s}.jpg`}
    style={{ maxWidth: 1070, width: "100%" }}
    onDragStart={handleDragStart}
    alt="Slajd"
  />
));

const getSlideBySecond = (second: number) => {
  let result = 1;
  slidesSeconds.forEach((el) => {
    if (el.from <= second && el.to > second) result = el.s;
  });
  return result;
};

function Lecture2() {
  const [currentSecond, setCurrentSecond] = useState<number | undefined>(
    undefined
  );
  const [yt, setYt] = useState<any | undefined>(undefined);
  const [currentSlide, setCurrentSlide] = useState(0);

  const updateCurrentSecond = (ytTime: number) =>
    setCurrentSecond(Math.floor(ytTime));

  useEffect(() => {
    if (!yt) return;

    const interval = setInterval(
      () => updateCurrentSecond(yt.getCurrentTime()),
      1000
    );
    return () => clearInterval(interval);
  }, [yt]);

  useEffect(() => {
    if (currentSecond) {
      const foundedSlide = getSlideBySecond(currentSecond);
      if (foundedSlide !== currentSlide)
        setCurrentSlide(foundedSlide ? foundedSlide : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSecond]);

  const opts = (playerHeight: string): Options => ({
    width: "100%",
    height: playerHeight, //"500",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 0,
    },
  });

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const playerHeight = isXs ? "200" : isSm ? "350" : "500";

  const onReady = (event: any) => {
    setYt(event.target);
  };

  const classes = useStyles();

  return (
    <div style={{ height: "100%" }}>
      <Typography variant="h5" component="h1" className={classes.modalHeader}>
        Przeszłość Polski i dawna kultura w zwierciadle frazeologii
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        dr Ewelina Woźniak-Wrzesińska
      </Typography>
      <br />

      <Card style={{ width: "100%", maxWidth: 1070, lineHeight: 0 }}>
        <YouTube
          id="YT2"
          videoId={videoId}
          opts={opts(playerHeight)}
          onReady={onReady}
        />
      </Card>
      <br />

      <Card style={{ width: "100%", maxWidth: 1070 }}>
        <AliceCarousel
          mouseTracking={false}
          items={images}
          activeIndex={currentSlide - 1}
          disableDotsControls
          disableButtonsControls
          autoHeight
        />
      </Card>
    </div>
  );
}

export default Lecture2;
