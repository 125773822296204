import Typography from "@material-ui/core/Typography";
import { useEffect } from "react";

export default function NotFound() {
  useEffect(() => {
    document.title = "Nie znaleziono strony";
  }, []);

  return (
    <>
      <Typography variant="h5" component="h1">
        Błąd. Nie znaleziono strony.
      </Typography>
    </>
  );
}
