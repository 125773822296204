import Typography from "@material-ui/core/Typography";
import MessageIcon from "@material-ui/icons/Message";
import SubjectIcon from "@material-ui/icons/Subject";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import InfoIcon from "@material-ui/icons/Info";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { useStyles, getModalStyle } from "./helpers";
import { Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Lecture4 from "../Recordings/Lecture4";
import { useState } from "react";

function C() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.container}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-with-lecture"
        aria-describedby="lecture-recording-with-presentation"
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={classes.close} onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <Lecture4 />
        </div>
      </Modal>

      <Typography variant="h6" noWrap>
        <img
          alt="Zdjęcie z wykładu"
          className={classes.lectureImg}
          src={process.env.PUBLIC_URL + "/images/lectures/4_zdj.jpg"}
        />
      </Typography>
      <br />
      <Typography paragraph align="left">
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <SubjectIcon />{" "}
          <b>
            <i>Tożsamość narodowa zawarta we frazeologizmach</i>
          </b>
        </Typography>
        dr Jan Zgrzywa
        <Typography variant="body2">
          11.12.2020, Uniwersytet Komeńskiego, warsztaty
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h2"
          className={classes.wrapIcon}
          gutterBottom
        >
          <OndemandVideoIcon />{" "}
          <b>
            <i>Nagranie wykładu</i>
          </b>
        </Typography>
        <Typography variant="body2">
          <Button variant="outlined" color="primary" onClick={handleOpen}>
            <PlayCircleOutlineIcon /> &nbsp; Wykład + prezentacja
          </Button>
        </Typography>
        <br />
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <InfoIcon /> Opis
        </Typography>
        <Typography variant="body1" align="justify">
          Trzecie z cyklu spotkań odbywających się w Bratysławie składające się
          z części teoretycznej i praktycznej było jednocześnie ostatnim
          spotkaniem w semestrze zimowym. Na zajęciach studenci pracowali ze
          współczesnymi tekstami stylizowanymi przede wszystkim na język w
          odmianie mówionej (teksty utworów muzycznych – Łony, Lao Che, Taco
          Hemingwaya, Dawida Podsiadły). Frazeologia w utworach tych autorów
          włączona zostaje w gry językowe, w których bardzo często wyzyskuje się
          ich metaforyczność, ale też i dosłowność. Nierzadko autorzy ci
          uciekają się do modyfikacji stałych związków, co świadczy o żywotności
          tych konstrukcji we współczesnej polszczyźnie. Celem ćwiczeń było
          zwrócenie uwagi ich uczestnikom na żywotność frazeologizmów we
          współczesnej polszczyźnie, którą autorzy tekstów literackich
          wykorzystują jako tworzywo do wyrażenia osobistych spostrzeżeń na
          temat świata. Ponadto podczas wspólnej interpretacji funkcjonalności
          poszczególnych związków frazeologicznych w konkretnym otoczeniu
          tekstowym studenci zapoznali się z aktualnymi problemami kultury
          polskiej i społeczeństwa polskiego.
        </Typography>
        <br />
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <MessageIcon /> Z anonimowych ankiet studenckich
        </Typography>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Dziękuję bardzo za frazeologizmy slangowe!”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Student</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Prowadzenie zajęć wydało mi się bardzo ciekawe. Podane w tekstach
          przykłady były dla mnie pożyteczne. W ogóle zajęcie oceniam bardzo
          pozytywnie.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Były to na pewno bardzo interesujące zajęcia. Bardzo się mi podobał
          styl prowadzenia zajęć i tak samo komunikacja z kolegami.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Student</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Podobało mi się, jak były te zajęcia wyjątkowe, oglądanie krótkich
          filmików, interaktywna rozmowa ze studentami, praca z tekstami.
          Doceniam przygotowanie wszystkich materiałów, naprawdę ciekawe.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Dziękujemy bardzo za ciekawe zajęcia. Jakieś zadania były trudne,
          inne na odwrót łatwe. Myślę, że jedynym minusem jest to, że zajęcia
          trwały długo i potem było trudno skoncentrować się.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Było to bardzo interesujące. Lubiłem pracować ze słownikami i
          wypowiadać się na temat tekstów.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Student</div>
          </div>
        </Paper>
      </Typography>
    </div>
  );
}

export default C;
