import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuBookIcon from "@material-ui/icons/MenuBook";

import A from "./A";
import B from "./B";
import C from "./C";
import D from "./D";
import E from "./E";
import F from "./F";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: 20,
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
      marginBottom: 20,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const getAccordion = (
  id: string,
  title: ReactElement,
  content: ReactElement,
  expanded: string | false,
  handleChange: (
    which: string
  ) => (event: ChangeEvent<{}>, expanded: boolean) => void,
  classes: any
) => (
  <Accordion square expanded={expanded === id} onChange={handleChange(id)}>
    <AccordionSummary
      aria-controls={`${id}-content`}
      id={`${id}-header`}
      expandIcon={<ExpandMoreIcon />}
    >
      <Typography style={{ display: "flex", alignItems: "center" }}>
        <MenuBookIcon
          fontSize={"small"}
          style={{ marginRight: 15 }}
          className={classes.hiddenOnSm}
        />
      </Typography>{" "}
      <Typography align="left">{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>{content}</AccordionDetails>
  </Accordion>
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hiddenOnSm: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  })
);

function Lectures() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
      document.title = "Wykłady i warsztaty";
    }, []);
  
    return (
    <>
      <Typography variant="h5" component="h1">
        Wykłady i warsztaty
      </Typography>
      <Typography variant="subtitle2" gutterBottom style={{ marginBottom: 20 }}>
        poprowadzone w ramach projektu
      </Typography>

      {getAccordion(
        "A",
        <>
          Wprowadzenie do polskiej frazeologii{" "}
          <Typography variant="body2">
            dr hab. Gabriela Dziamska-Lenart, prof. UAM
          </Typography>
        </>,
        <A />,
        expanded,
        handleChange,
        classes
      )}
      {getAccordion(
        "B",
        <>
          Przeszłość Polski i dawna kultura w zwierciadle frazeologii{" "}
          <Typography variant="body2">
            dr hab. Gabriela Dziamska-Lenart, prof. UAM i dr Ewelina
            Woźniak-Wrzesińska
          </Typography>
        </>,
        <B />,
        expanded,
        handleChange,
        classes
      )}
      {getAccordion(
        "C",
        <>
          Tożsamość narodowa zawarta we frazeologizmach{" "}
          <Typography variant="body2">dr Jan Zgrzywa</Typography>
        </>,
        <C />,
        expanded,
        handleChange,
        classes
      )}
      {getAccordion(
        "D",
        <>
          Wprowadzenie do leksykografii frazeologicznej{" "}
          <Typography variant="body2">dr Piotr Fliciński</Typography>
        </>,
        <D />,
        expanded,
        handleChange,
        classes
      )}
      {getAccordion(
        "E",
        <>
          Frazeologia w literaturze{" "}
          <Typography variant="body2">dr Jan Zgrzywa</Typography>
        </>,
        <E />,
        expanded,
        handleChange,
        classes
      )}
      {getAccordion(
        "F",
        <>
          Konsultacje dla studentów{" "}
          <Typography variant="body2">
            dr Zuzana Obertová, dr Magdalena Zakrzewska-Verdugo, dr Ewelina
            Woźniak-Wrzesińska, dr Jan Zgrzywa
          </Typography>
        </>,
        <F />,
        expanded,
        handleChange,
        classes
      )}
    </>
  );
}

export default Lectures;
