import { useState } from "react";
import {
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { useDrag, useDrop } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";

import { DndProvider } from 'react-dnd-multi-backend';
// import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/cjs/HTML5toTouch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      position: "relative",
    },
    minHeight: {
      minHeight: 100,
    },
    chip: {
      height: "auto",
      paddingTop: "10px",
      paddingBottom: "10px",
      margin: theme.spacing(1),
      "& > *": {
        whiteSpace: "break-spaces",
      },
    },
    chipHovered: {
      "&:hover": {
        background: "rgba(255,255,255,0.1)",
      },
    },
    left: {
      alignSelf: "center",
      [theme.breakpoints.down("xs")]: {
        order: 2,
      },
    },
    right: {
      alignSelf: "center",
      [theme.breakpoints.down("xs")]: {
        order: 1,
      },
    },
  })
);

const highlightOverlay = (color: "ok" | "not-ok" | "neutral" = "ok") => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      zIndex: 1,
      opacity: 0.1,
      backgroundColor:
        color === "ok" ? "green" : color === "neutral" ? "gray" : "red",
    }}
  />
);

const summaryOK = "Wspaniale! Wszystko poprawnie! Dobra robota!";
const summaryNotOK =
  "Niestety, nie wszystkie odpowiedzi są poprawne, spróbuj ponownie!";

function ExerciseFunc({
  data,
  answers,
  uniqueItemTypeName,
}: {
  data: { a: string; b: string }[];
  answers: { a: number; b: number }[];
  uniqueItemTypeName: string;
}) {
  const classes = useStyles();

  const [left, setLeft] = useState<
    { l1: string; chipId: number | undefined; correct: undefined | boolean }[]
  >(data.map((d) => ({ l1: d.a, chipId: undefined, correct: undefined })));
  const [refreshLeft, setRefreshLeft] = useState(false);
  const [locked, setLocked] = useState(false);

  const [right, setRight] = useState(data.map((d, i) => i));
  const [summary, setSummary] = useState(summaryOK);

  const chips = data.map((d) => B(d.b, locked, uniqueItemTypeName));

  const getIndexOfChip = (content: string) =>
    data.map((d) => d.b).indexOf(content);

  const fromRightToLeft = (
    element: { l1: string; itemContent: string },
    right: number[]
  ) => {
    const chipId = getIndexOfChip(element.itemContent);

    left.forEach((e) => {
      if (e.chipId === chipId) {
        e.chipId = undefined;
      }
      if (e.l1 === element.l1) {
        e.chipId = chipId;
      }
    });

    setRight(right.filter((r) => chipId !== r));
  };

  const fromLeftToRight = (itemContent: string) => {
    const chipId = getIndexOfChip(itemContent);

    left.forEach((e) => {
      if (e.chipId === chipId) {
        e.chipId = undefined;
      }
    });

    setRight([...right, chipId]);
  };

  const [{ isOverRight, canDrop }, dropRight] = useDrop(
    () => ({
      accept: uniqueItemTypeName,
      canDrop: (item) => !right.includes(getIndexOfChip(item.content)),
      drop: (item: { content: string }) => fromLeftToRight(item.content),
      collect: (monitor) => ({
        isOverRight: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [right]
  );

  const restart = () => {
    left.forEach((e) => {
      e.chipId = undefined;
      e.correct = undefined;
    });
    setRight(data.map((d, i) => i));
    setLocked(false);
  };

  const check = () => {
    setSummary(summaryOK);
    left.forEach((e, i) => {
      if (answers[i].b === e.chipId) {
        e.correct = true;
      } else {
        e.correct = false;
        setSummary(summaryNotOK);
      }
    });
    setRefreshLeft(!refreshLeft);
    setLocked(true);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
      ></Grid>

      <div className={classes.root}>
        <Grid item xs={12} style={{ marginBottom: 15 }}>
          <Paper className={classes.paper}>
            {!locked && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<DoneAllIcon />}
                onClick={check}
                style={{ margin: "0 20px" }}
                disabled={right.length > 0}
              >
                Sprawdź poprawność
              </Button>
            )}
            <Button
              variant="outlined"
              color="primary"
              startIcon={<RotateLeftIcon />}
              onClick={restart}
            >
              Zacznij od nowa
            </Button>
            {locked && (
              <Typography variant="h6" style={{ margin: "20px 0" }}>
                {summary}
              </Typography>
            )}
          </Paper>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} className={classes.left}>
            <Paper className={classes.paper}>
              {right.length > 0 ? (
                <span style={{ opacity: 0.6, display: "block" }}>
                  Upuść tutaj:
                </span>
              ) : (
                ""
              )}
              <List className={classes.root}>
                {left.map((l) =>
                  A(
                    l,
                    chips,
                    fromRightToLeft,
                    right,
                    refreshLeft,
                    uniqueItemTypeName
                  )
                )}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.right}>
            <Paper
              ref={dropRight}
              className={`${classes.paper} ${classes.minHeight}`}
            >
              {isOverRight && canDrop && highlightOverlay("ok")}
              {isOverRight && !canDrop && highlightOverlay("neutral")}

              {right.length > 0 ? (
                <span style={{ opacity: 0.7, display: "block" }}>
                  Przeciągnij stąd:
                </span>
              ) : (
                ""
              )}
              {right.map((r) => chips[r])}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

const A = (
  content: {
    l1: string;
    chipId: number | undefined;
    correct: boolean | undefined;
  },
  chips: JSX.Element[],
  fromRightToLeft: any,
  right: number[],
  refreshLeft: boolean,
  uniqueItemTypeName: string
) => {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: uniqueItemTypeName,
      canDrop: (item) => content.chipId === undefined,
      drop: (item: { content: string }) =>
        fromRightToLeft({ l1: content.l1, itemContent: item.content }, right),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [content, right]
  );

  return (
    <div key={content.l1}>
      <ListItem ref={drop} style={{ minHeight: 60 }}>
        <ListItemText
          /*primary="Photos"*/ secondary={content.l1}
          id={content.l1}
        />
        {content.chipId !== undefined && chips[content.chipId]}
        {content.correct === true && highlightOverlay("ok")}
        {content.correct === false && highlightOverlay("not-ok")}
        {isOver && canDrop && highlightOverlay("ok")}
        {isOver && !canDrop && highlightOverlay("neutral")}
      </ListItem>
      <Divider />
    </div>
  );
};

const B = (content: string, locked: boolean, uniqueItemTypeName: string) => {
  const classes = useStyles();

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: uniqueItemTypeName,
      item: { content },
      canDrag: !locked,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
        canDrag: !!monitor.canDrag(),
      }),
    }),
    [locked]
  );

  return (
    <Chip
      id={content}
      key={content}
      ref={drag}
      label={content}
      className={`${classes.chip} ${!locked ? classes.chipHovered : ""}`}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: !locked ? "move" : "default",
      }}
    />
  );
};

const Exercise = ({
  data,
  answers,
  mb = true,
  uniqueItemTypeName,
}: {
  data: { a: string; b: string }[];
  answers: { a: number; b: number }[];
  mb: boolean;
  uniqueItemTypeName: string;
}) => {
  return (
    <div style={{ marginBottom: mb ? 50 : 15 }}>
      <DndProvider options={HTML5toTouch}>
        <ExerciseFunc
          data={data}
          answers={answers}
          uniqueItemTypeName={uniqueItemTypeName}
        />
      </DndProvider>
    </div>
  );
};

export default Exercise;
