import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import { Accordion, AccordionSummary, useStyles, TT } from "./helpers";

export default function Team() {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Avatar
        alt="dr Zuzana Obertová"
        src={process.env.PUBLIC_URL + "/images/team/D.jpg"}
        className={classes.avatar}
      />
      <CardContent>
        <Typography variant="h6" component="h2">
          dr Zuzana Obertová
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Katedra Filologii Słowiańskich
          <br />
          Uniwersytet Komeńskiego w Bratysławie
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          href="mailto:zuzana.obertova@uniba.sk"
        >
          E-mail
        </Button>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          href="https://orcid.org/0000-0002-5766-4081"
          target="_blank"
        >
          ORCID
        </Button>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          href="https://fphil.uniba.sk/katedry-a-odborne-pracoviska/ksf/clenovia-katedry/zuzana-obertova/"
          target="_blank"
        >
          WWW
        </Button>
      </CardActions>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Więcej</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Card className={classes.photoCard}>
                <img
                  className={classes.photo}
                  alt="zdjęcie uczestnika"
                  src={process.env.PUBLIC_URL + "/images/team/D1.jpg"}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="justify"
                className={classes.personDesc}
              >
                Jestem człowiekiem{" "}
                <TT
                  title={
                    <>
                      <i>ktoś</i> jest do tańca i do różańca: ‘ktoś, kto ma
                      wiele zalet, jest bardzo towarzyski, a zarazem pracowity i
                      zaradny, ktoś, na kogo można liczyć w różnych sytuacjach’
                    </>
                  }
                  label="do tańca i do różańca"
                />{" "}
                – na moich zajęciach z języka, literatury i kultury polskiej
                pilnie pracujemy, ale zawsze jest też ciekawie i wesoło. Jako
                adiunkt na Uniwersytecie Komeńskiego{" "}
                <TT
                  title={
                    <>
                      <i>ktoś</i> leży do góry brzuchem: ‘ktoś odpoczywa
                      pasywnie, nic nie robi, leniuchuje’
                    </>
                  }
                  label="nie leżę do góry brzuchem"
                />
                : pracuję nad projektami naukowymi, rozwijam swoje kompetencje
                pedagogiczne, organizuję imprezy i wycieczki dla studentów. Od
                czasu do czasu tłumaczę symultanicznie na konferencjach. Lubię
                grać na skrzypcach. Nigdy nie{" "}
                <TT
                  title={
                    <>
                      <i>ktoś</i> wycofał się rakiem: ‘ktoś nie dotrzymuje
                      danego słowa, rezygnuje z jakiegoś działania’
                    </>
                  }
                  label="wycofuję się rakiem"
                />{" "}
                i to samo radzę swoim studentom – wszystko da się zrobić, jeśli
                się chce.
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
