import Typography from "@material-ui/core/Typography";
import MessageIcon from "@material-ui/icons/Message";
import SubjectIcon from "@material-ui/icons/Subject";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import InfoIcon from "@material-ui/icons/Info";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { useStyles, getModalStyle } from "./helpers";
import { Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Lecture2 from "../Recordings/Lecture2";
import Lecture3 from "../Recordings/Lecture3";
import { useState } from "react";

function B() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [modalStyle] = useState(getModalStyle);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
  };

  return (
    <div className={classes.container}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-with-lecture"
        aria-describedby="lecture-recording-with-presentation"
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={classes.close} onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <Lecture2 />
        </div>
      </Modal>

      <Modal
        open={open2}
        onClose={handleClose}
        aria-labelledby="modal-with-lecture"
        aria-describedby="lecture-recording-with-presentation"
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={classes.close} onClick={() => setOpen2(false)}>
            <CloseIcon />
          </div>
          <Lecture3 />
        </div>
      </Modal>

      <Typography variant="h6" noWrap>
        <img
          alt="Zdjęcie z wykładu 1"
          className={classes.lectureImg}
          src={process.env.PUBLIC_URL + "/images/lectures/2_zdj.jpg"}
        />
        <img
          alt="Zdjęcie z wykładu 2"
          className={classes.lectureImg}
          src={process.env.PUBLIC_URL + "/images/lectures/3_zdj.jpg"}
        />
      </Typography>
      <br />
      <Typography paragraph align="left">
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <SubjectIcon />{" "}
          <b>
            <i>Przeszłość Polski i dawna kultura w zwierciadle frazeologii </i>
          </b>
        </Typography>
        dr hab. Gabriela Dziamska-Lenart, prof. UAM i dr Ewelina
        Woźniak-Wrzesińska
        <Typography variant="body2">
          19-20.11.2020, Uniwersytet Komeńskiego, warsztaty
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h2"
          className={classes.wrapIcon}
          gutterBottom
        >
          <OndemandVideoIcon />{" "}
          <b>
            <i>Nagrania wykładów</i>
          </b>
        </Typography>
        <Typography variant="body2">
          <Button variant="outlined" color="primary" onClick={handleOpen}>
            <PlayCircleOutlineIcon /> &nbsp; 1. Wykład + prezentacja
          </Button>
          <br />
          <br />
          <Button variant="outlined" color="primary" onClick={handleOpen2}>
            <PlayCircleOutlineIcon /> &nbsp; 2. Wykład + prezentacja
          </Button>
        </Typography>
        <br />
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <InfoIcon /> Opis
        </Typography>
        <Typography variant="body1" align="justify">
          Warsztaty miały następujące cele ogólne:
          <br />
          a. przedstawienie studentom tego, jak dużym zasobem leksykalnym języka
          są frazeologizmy,
          <br />
          b. wyjaśnienie uczestnikom, że frazeologizmy – choć nawiązują do
          przeszłości – są żywym elementem rzeczywistości językowej,
          <br />
          c. zaprezentowanie studentom, że w skrzydlatych słowach,
          frazeologizmach czy przysłowiach ukryte są stereotypowe obrazy
          kulturowe (kobiety, mężczyzny, miłości) – to podczas drugiego dnia zajęć.
          <br />
          <br />
          W szczególności zajęcia miały doprowadzić do:
          <br />
          a. zapoznania studentów ze zbiorem popularnych w polszczyźnie przysłów
          i frazeologizmów, w których utrwaliły się dawne realia
          społeczno-kulturowe,
          <br />
          b. wzmocnienia u studentów kompetencji komunikacyjnej poprzez
          wzbogacenie ich leksykonu o frazeologizmy używane w poprawnej formie
          gramatycznej i w odpowiedniej sytuacji komunikacyjnej,
          <br />
          c. uświadomienia słuchaczom, że frazeologia jest odbiciem kultury.
        </Typography>
        <br />
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <MessageIcon /> Z anonimowych ankiet studenckich
        </Typography>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Chciałabym podziękować za ciekawe lekcje. Bardzo interesujące było
          słuchać polskich piosenek i poznać nowe polskie frazeologizmy. Myślę
          że były wyjaśniane dobrze.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Zajęcia były bardzo interesujące. Dzięki pani profesor już znałam
          niektóre frazeologizmy. Materiały ilustracyjne oraz wykłady są ciekawe
          i zrozumiałe.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Student</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Z zajęć jestem zadowolona, podobało mi się i nauczyłam się czegoś
          nowego. Myślę, że niektóre zadania były nieco trudniejsze, zwłaszcza
          te z piosenkami, ale ogólnie bardzo polubiłam frazeologię i cieszę
          się, że zapisałam się na te zajęcia.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Zajęcia były bardzo ciekawe, dobrze przygotowane i dowiedziałam się
          nowych informacji o kulturze Polski.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Lekcje są bardzo interesujące, ale chciałbym więcej teorii, ponieważ
          już jest wiele frazeologizmów. Nie wiedziałem, że tyle jest
          frazeologii.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Myślę, że słuchanie piosenek zajęło dużo czasu, ale były zabawne.
          Podoba się mi zadanie domowe.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Student</div>
          </div>
        </Paper>
      </Typography>
    </div>
  );
}

export default B;
