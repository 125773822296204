import { ReactElement, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import VocabularySearch from "../pages_elements/VocabularySearch";
import VocabularyTable from "../pages_elements/VocabularyTable";
import DB from "../db/db.json";
import { useHistory } from "react-router-dom";
import Flag from "react-world-flags";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuBookIcon from "@material-ui/icons/MenuBook";

const db: string[][] = DB;

const prettifyElement = (e: string) => {
  e = e
    .replace(/[{]/g, "<b>")
    .replace(/[}]/g, "</b>")
    .replace(/[[]/g, "<i>")
    .replace(/[\]]/g, "</i>")
    .replace(/\s/g, " ") // all white spaces replace by space
    .replace(/([2-9]. )/g, "<br>$1"); // make new lines when found "2. ", "3. " ... "9. "
  return { __html: e };
};
const clearElement = (e: string) => {
  e = e.replace(/[{}[]]/g, "").replace(/\s/g, " "); // all white spaces replace by space
  return e;
};

const prepareElement = (
  element: string
): { jsx: ReactElement; string: string } => ({
  jsx: (
    <Typography variant="body2" gutterBottom>
      <div dangerouslySetInnerHTML={prettifyElement(element)} />
    </Typography>
  ),
  string: clearElement(element),
});

const prepareRows = () =>
  db?.map((line) => [...line.map((element) => prepareElement(element))]);

// exchange languages SK->PL
const prepareInvertedRows = () =>
  db?.map((line) => [
    prepareElement(line[5]),
    prepareElement(line[6]),
    prepareElement(line[7]),
    prepareElement(line[8]),
    { jsx: <></>, string: "" },

    prepareElement(line[0]),
    prepareElement(line[1]),
    prepareElement(line[2]),
    prepareElement(line[3]),
    prepareElement(line[4]),
  ]);

// filter po 2wszystkich polach:
// const filter = (
//   row: { jsx: ReactElement; string: string }[],
//   searchString: string
// ) => {
//   let found = false;
//   row.forEach((element) => {
//     if (element.string.toLowerCase().includes(searchString.toLowerCase())) {
//       found = true;
//       return;
//     }
//   });
//   return found;
// };

// filtruje tylko po związku frazeologiocznym (pierwsza kolumna zawsze)
const filter = (
  row: { jsx: ReactElement; string: string }[],
  searchString: string
) => {
  let found = false;
  if (row[0].string.toLowerCase().includes(searchString.toLowerCase())) {
    found = true;
  }
  return found;
};

const getFlag = (inverted: boolean) => (
  <Flag
    code={inverted ? "sk" : "pl"}
    height="11"
    style={{ marginRight: "3px", border: "1px solid rgba(0,0,0,0.3)" }}
  />
);

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: 20,
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
      marginBottom: 20,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const Vocabulary = (props: { inverted: boolean; title: string }) => {
  let history = useHistory();

  const [searchString, setSearchString] = useState<string>();
  const [expanded, setExpanded] = useState(false);
  const makeSearch = (searchString: string | undefined) => {
    setSearchString(searchString);
    history.push({
      pathname: history.location.pathname,
      search: `s=${searchString}`,
    });
  };

  // let { urlSearchString } = useParams<RouteParams>();
  let urlSearchString = decodeURI(history.location.search.replace("?s=", ""));

  useEffect(() => {
    if (urlSearchString !== `${searchString}`) makeSearch(urlSearchString);
  }, [urlSearchString, history.location]);

  let rows;
  if (!props.inverted) rows = prepareRows();
  else rows = prepareInvertedRows(); // exchange languages SK->PL

  useEffect(() => {
    document.title = props.title;
  }, [props]);

  return (
    <>
      <Typography variant="h5" component="h1" gutterBottom>
        {props.title}
      </Typography>

      <Accordion
        square
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            <MenuBookIcon fontSize={"small"} style={{ marginRight: 10 }} />
          </Typography>{" "}
          <Typography>Powstanie słownika</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" align="justify">
            Zaprezentowany mały słowniczek frazeologiczny polsko-słowacki /
            słowacko-polski jest pokłosiem zajęć przeprowadzony dla studentów
            Studiów Środkowoeuropejskich z Uniwersytetu Komeńskiego w
            Bratysławie. Podczas cyklu warsztatów słuchacze poznali różne –
            kulturotwórcze – funkcje frazeologizmów. Wzięli ponadto udział w
            zajęciach o charakterze frazeograficznym przeprowadzonych przez pana
            dra Flicińskiego. Na podstawie zdobytej wiedzy studenci zmierzyli
            się z pracą filologiczno-translatoryczną. Selekcji frazeologizmów
            dokonała dr Obertová, która z listy związków frazeologicznych
            udostępnionej przez dra Flicińskiego wybrała te, które mają
            ekwiwalenty w języku słowackim. Punkt wyjścia stanowiły więc polskie
            artykuły hasłowe. Ich postać została opracowana przez zespół autorów
            Słownika frazeologicznego z ćwiczeniami dla uczących się języka
            polskiego jako obcego (niektóre ilustracje są tożsame ze wskazanym
            leksykonem). Autorami słowackich tłumaczeń są natomiast studenci w
            osobach: Veronika Beregszásziová, Anastasiia Danyliuk, Nikola
            Horváthová, Stepan Kaliukanov, Viliam Kardos, Terézia Királyová,
            Katarina Kostić, Vivien Lovasová, Lucia Ranincová, Patrícia
            Velčická, Nina Zavodska, Margaréta Štrbová. Hasła sprawdziła i
            poprawiła dr Obertová.
            <br />
            <br />
            Jak się okazało – z glottodydaktycznego punktu widzenia niebagatelną
            rolę odgrywa praca nad tłumaczeniami haseł słownikowych jako nauka
            poprzez działanie, a więc jakby mimochodem. Wtedy studenci
            wykroczyli poza laboratoryjne poznawanie polszczyzny na rzecz jej
            praktycznego doświadczania.
            <br />
            <br />
            Opracowując tłumaczenia do słownika, studenci poszukiwali elementów
            wspólnych dla języka polskiego i słowackiego, związków wyrazowych o
            podobnej budowie, analogicznej funkcji, a także frazeologizmów o
            odmiennej budowie i różniących się semantycznie w języku wyjściowym
            i docelowym. Taki model postępowania dydaktycznego, został
            wykorzystany z powodzeniem w pracy z grupami, których członkowie
            posługują się różnymi językami natywnymi. Spojrzenie na polską
            frazeologię na tle frazeologizmów innojęzycznych tym bardziej
            uwypukliło cechy charakterystyczne kultury polskiej. Mamy też
            nadzieję, że zaproponowany przez nas Projekt zostanie w przyszłości
            rozszerzony przy współpracy z większą grupą badaczy i ośrodków,
            również z innych krajów lub wykorzystany jako baza do stworzenia
            skryptu dla studentów czy gry edukacyjnej.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <VocabularySearch
        urlSearchString={searchString}
        searchCallback={makeSearch}
      />
      <VocabularyTable
        rows={rows.filter(
          (row, i) => !searchString || i === 0 || filter(row, searchString)
        )}
        lang1={getFlag(props.inverted)}
        lang2={getFlag(!props.inverted)}
      />
    </>
  );
};

export default Vocabulary;
