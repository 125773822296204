import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import { Button, ButtonGroup, TextField, useMediaQuery } from "@material-ui/core";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        borderBottom: "unset",
      },
    },
    paper: {
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
    },
    buttonGroup: {
      width: "100%",
    },
    searchInput: {
      width: "100%",
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
    },
  })
);

export default function VocabularySearch(props: {
  searchCallback: (searchString: string | undefined) => void;
  urlSearchString: string | undefined;
}) {
  const { searchCallback, urlSearchString } = props;
  const classes = useStyles();
  const [searchString, setSearchString] = useState<string>();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setSearchString(urlSearchString);
  }, [urlSearchString]);

  return (
    <Paper className={classes.paper}>
      <form
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          searchCallback(searchString);
        }}
      >
        <ButtonGroup
          disableElevation
          variant="outlined"
          color="primary"
          className={classes.searchInput}
        >
          <TextField
            id="outlined-basic"
            label="Wpisz frazę..."
            variant="outlined"
            fullWidth
            className={classes.searchInput}
            value={searchString}
            onChange={(v) => setSearchString(v.currentTarget.value)}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={!isSmall ? <SearchIcon /> : ""}
            onClick={() => searchCallback(searchString)}
          >
            Szukaj
          </Button>
        </ButtonGroup>
      </form>
    </Paper>
  );
}
