import React from "react";
import "./App.css";
import Template from "./template/Template";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Team from "./pages/Team/Team";
import Vocabulary from "./pages/Vocabulary";
import PaperVocabulary from "./pages/PaperVocabulary";
import Lectures from "./pages/Lectures/Lectures";
import Exercises from "./pages/Exercise/Exercises";
import Presentation from "./pages/Recordings";
import StudentsMaterials from "./pages/StudentsMaterials";
import A1 from "./pages/Exercise/A1";
import A2B1 from "./pages/Exercise/A2B1";
import B2 from "./pages/Exercise/B2";
import C1 from "./pages/Exercise/C1";
import Igraszki from "./pages/Exercise/Igraszki";
import NotFound from "./pages/NotFound";
import { useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import ScrollToTop from "./ScrollToTop";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [themeType, setThemeType] = useState<"light" | "dark">();

  const { REACT_APP_URL_PATH } = process.env;

  const urlPath = undefined;

  const theme = React.useMemo(() => {
    const schemaType = themeType
      ? themeType
      : prefersDarkMode
      ? "dark"
      : "light";
    const isLight = schemaType === "light";
    return createMuiTheme({
      overrides: {
        MuiLink: {
          root: {
            color: isLight ? "#2B4D99" : "#2196F3",
          },
        },
      },
      palette: {
        type: schemaType,
        primary: {
          main: isLight ? "#002d69" : "#FFF",
          light: isLight ? "#0049a4" : "#FFF",
        },
        secondary: {
          main: isLight ? "#2b4d99" : "#AAA",
        },
      },
    });
  }, [prefersDarkMode, themeType]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router basename={urlPath}>
          <Template setThemeType={setThemeType} urlPath={REACT_APP_URL_PATH}>
            <ScrollToTop />
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/zespol">
                <Team />
              </Route>
              <Route path="/slownik">
                <Vocabulary
                  inverted={false}
                  title={"Polsko-słowacki słownik związków frazeologicznych"}
                />
              </Route>
              {/* <Route path="/slownik?s=:urlSearchString">
                <Vocabulary
                  inverted={false}
                  title={"Polsko-słowacki słownik związków frazeologicznych"}
                />
              </Route> */}
              <Route exact path="/slownik-slowacki">
                <Vocabulary
                  inverted={true}
                  title={"Słowacko-polski słownik związków frazeologicznych"}
                />
              </Route>
              <Route exact path="/materialy-studentow">
                <StudentsMaterials />
              </Route>
              {/* <Route path="/slownik-slowacki?s=:urlSearchString">
                <Vocabulary
                  inverted={true}
                  title={"Słowacko-polski słownik związków frazeologicznych"}
                />
              </Route> */}
              <Route exact path="/nagrania">
                <Presentation />
              </Route>
              <Route exact path="/cwiczenia">
                <Exercises />
              </Route>
              <Route path="/slownik-papierowy">
                <PaperVocabulary />
              </Route>
              <Route path="/wyklady-warsztaty">
                <Lectures />
              </Route>

              <Route path="/cwiczenia-a1">
                <A1 />
              </Route>
              <Route path="/cwiczenia-a2-b1">
                <A2B1 />
              </Route>
              <Route path="/cwiczenia-b2">
                <B2 />
              </Route>
              <Route path="/cwiczenia-c1">
                <C1 />
              </Route>
              <Route path="/cwiczenia-igraszki-z-ogniem">
                <Igraszki />
              </Route>

              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </Template>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
