import Typography from "@material-ui/core/Typography";
import MessageIcon from "@material-ui/icons/Message";
import SubjectIcon from "@material-ui/icons/Subject";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import InfoIcon from "@material-ui/icons/Info";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { useStyles, getModalStyle } from "./helpers";
import { Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Lecture5 from "../Recordings/Lecture5";
import { useState } from "react";

function E() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.container}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-with-lecture"
        aria-describedby="lecture-recording-with-presentation"
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={classes.close} onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <Lecture5 />
        </div>
      </Modal>

      <Typography variant="h6" noWrap>
        <img
          alt="Zdjęcie z wykładu"
          className={classes.lectureImg}
          src={process.env.PUBLIC_URL + "/images/lectures/5_zdj.jpg"}
        />
      </Typography>
      <br />
      <Typography paragraph align="left">
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <SubjectIcon />{" "}
          <b>
            <i>Frazeologia w literaturze</i>
          </b>
        </Typography>
        dr Jan Zgrzywa
        <Typography variant="body2">
          15.04.2021, Uniwersytet Komeńskiego, warsztaty
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h2"
          className={classes.wrapIcon}
          gutterBottom
        >
          <OndemandVideoIcon />{" "}
          <b>
            <i>Nagranie wykładu</i>
          </b>
        </Typography>
        <Typography variant="body2">
          <Button variant="outlined" color="primary" onClick={handleOpen}>
            <PlayCircleOutlineIcon /> &nbsp; Wykład + prezentacja
          </Button>
        </Typography>
        <br />
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <InfoIcon /> Opis
        </Typography>
        <Typography variant="body1" align="justify">
          Podczas zajęć studenci interpretowali wybrane fragmenty powieści Olgi
          Tokarczuk, w których zawarte były frazeologizmy. Skupili się na pracy
          warsztatowo-filologicznej. Ich zadanie polegało na weryfikowaniu
          znaczeń związków frazeologicznych, np. w{" "}
          <i>Wielkim słowniku języka polskiego PAN</i> pod redakcją Piotra
          Żmigrodzkiego. Następnie sprawdzali, do jakich zmian znaczenia
          dochodzi, jeśli związek frazeologiczny zostanie użyty w innym
          kontekście, np. literackim. Tym sposobem słuchacze poznali nowe
          zjawiska – defrazeologizacji oraz neosemantyzacji. Celem warsztatów
          było ukazanie, że związki frazeologiczne mogą być twórczo przetwarzane
          w różnych tekstach kultury.
        </Typography>
        <br />
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <MessageIcon /> Z anonimowych ankiet studenckich
        </Typography>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Chyba jedyną wadą było, że zajęcia trwały długo. Dziękuję państwu za
          zajęcia w dobrym nastroju.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Zajęcia były bardzo ciekawe, podobał mi się interaktywny sposób
          nauczania i że mogliśmy użyć naszej wyobraźni, aby wymyślić historie a
          później je omówić. Były przypadki, gdy podane frazy oznaczały coś
          zupełnie innego niż myślałam.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Ciekawe zajęcia, bardzo mi pomogły w pisaniu mojej pracy
          licencjackiej.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Student</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Zajęcia były interesujące i na pewno naukowe. Dowiedziałam się więcej
          o wyszukiwaniu frazeologizmów w internecie oraz o nowych
          frazeologizmach, których wcześniej nie znałam.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „Bardzo dziękuję wszystkim prowadzącym za te zajęcia, dowiedziałam się
          dużo ciekawych i interesujących informacji o frazeologizmach i też to,
          jak je zrozumieć, jestem zadowolona, że się zapisałam na ten
          przedmiot!”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
        <br />
        <Paper elevation={3} className={classes.testimonial} variant="outlined">
          <FormatQuoteIcon />
          „To były bardzo ciekawe zajęcia. Temat modyfikacji frazeologizmów –
          szczególnie w twórczości literackiej – jest bardzo interesujący.
          Większość frazeologizmów, które analizowaliśmy na wykładach, nigdy
          wcześniej nie słyszałam, więc nauczyłam się wielu nowych rzeczy. Po
          zajęciach zdecydowanie chciałam dowiedzieć się jeszcze więcej o
          twórczości Olgi Tokarczuk i przeczytać jej książki.”
          <div>
            <Avatar src="/broken-image.jpg" /> <div>Studentka</div>
          </div>
        </Paper>
      </Typography>
    </div>
  );
}

export default E;
