import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import { Accordion, AccordionSummary, useStyles, TT } from "./helpers";

export default function Team() {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Avatar
        alt="dr Jan Zgrzywa"
        src={process.env.PUBLIC_URL + "/images/team/C.jpg"}
        className={classes.avatar}
      />
      <CardContent>
        <Typography variant="h6" component="h2">
          dr Jan Zgrzywa
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Pracownia Leksykograficzna
          <br />
          Uniwersytet im. Adama Mickiewicza w Poznaniu
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          href="mailto:zgrzywa@amu.edu.pl"
        >
          E-mail
        </Button>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          href="https://orcid.org/0000-0002-3067-583X"
          target="_blank"
        >
          ORCID
        </Button>
      </CardActions>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Więcej</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Card className={classes.photoCard}>
                <img
                  className={classes.photo}
                  alt="zdjęcie uczestnika"
                  src={process.env.PUBLIC_URL + "/images/team/C1.jpg"}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="justify"
                className={classes.personDesc}
              >
                Urodziłem się w Poznaniu i… zanim ponownie trafiłem do tego
                miasta na studia, odbyłem naukę w dwóch przedszkolach, czterech
                szkołach podstawowych i dwóch średnich. Wiele wskazuje na to, że
                mam w sobie coś z bieguna, bo moje życie to ruch realizujący się
                w sporcie i w pracy. Tych ostatnich aktywności było sporo, a ich
                rozpiętość szeroka: od sprzątacza, przez traktorzystę,
                sprzedawcę, kierowcę, tłumacza aż do zarządzającego ludźmi.
                Filologię{" "}
                <TT
                  title={
                    <>
                      <i>ktoś</i> odkrywa, odkrył w sobie <i>coś</i>: ‘ktoś
                      czuje powołanie do czegoś, wie, że chce czymś się
                      zajmować’
                    </>
                  }
                  label="odkryłem w sobie"
                />{" "}
                na prawie, które dla niej porzuciłem. Mój zachwyt nad słowem
                trwa do dziś. I nad życiem: Żyj, nie marudź! – to moja dewiza.
                Jestem z natury samotnikiem, ale lubię wracać do domu, kiedy
                mogę powiedzieć: <i>Cześć!</i>, do członków mojej rodziny. Uwielbiam:
                rozmowy z J. i z A., spacery z Sawaną, szybką jazdę na rowerze,
                dobrą muzykę, czytanie, gotowanie, spektakle w małych teatrach,
                konwersację z młodymi ludźmi na lekcjach w szkole lub na
                zajęciach uniwersyteckich.
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
