import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CommentIcon from "@material-ui/icons/Comment";
import PersonIcon from "@material-ui/icons/Person";
import { useMediaQuery } from "@material-ui/core";
import { useEffect } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoImg: {
      margin: "auto",
      display: "block",
      maxWidth: "300px",
      maxHeight: "100px",
    },
    button: {
      margin: theme.spacing(1),
    },
    lectureImg: {
      margin: "auto",
      marginBottom: 2,
      display: "block",
      maxWidth: "400px",
      borderRadius: 8,
      border: "1px solid black",
      borderColor: "rgba(0,0,0,0.2)",

      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
    },
    wrapIcon: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "& > svg": {
        marginRight: 10,
      },
    },
  })
);

function PaperVocabulary() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    document.title = "Materiały studentów";
  }, []);

  return (
    <>
      <Typography variant="h5" component="h1">
        Materiały studentów
      </Typography>
      <Typography variant="subtitle2" gutterBottom style={{ marginBottom: 20 }}>
        prace studentów, powstałe podczas zajęć
      </Typography>

      <Card>
        <CardContent style={{ padding: 40, paddingBottom: 20 }}>
          <Typography variant="h6" className={classes.wrapIcon} gutterBottom>
            {isSmall ? <CommentIcon /> : ""} Ilustracje „z przymrużeniem oka”
          </Typography>
          <Typography variant="h6" noWrap>
            <img
              alt="Materiały studentów 1"
              className={classes.lectureImg}
              // style={{ width: 500 }}
              src={process.env.PUBLIC_URL + "/images/students/1.PNG"}
            />
            <Typography className={classes.wrapIcon}>
              {isSmall ? <PersonIcon /> : ""} <i>Anastasiia Danyliuk</i>
            </Typography>
          </Typography>
          <br />
          <Typography variant="h6" noWrap>
            <img
              alt="Materiały studentów 2"
              className={classes.lectureImg}
              // style={{ width: 800 }}
              src={process.env.PUBLIC_URL + "/images/students/2.PNG"}
            />
            <Typography className={classes.wrapIcon}>
              {isSmall ? <PersonIcon /> : ""} <i>Margaréta Štrbová</i>
            </Typography>
          </Typography>
          <br />
          <Typography variant="h6" noWrap>
            <img
              alt="Materiały studentów 3"
              className={classes.lectureImg}
              // style={{ width: 500 }}
              src={process.env.PUBLIC_URL + "/images/students/3.PNG"}
            />
            <Typography className={classes.wrapIcon}>
              {isSmall ? <PersonIcon /> : ""} <i>Nikola Horváthová</i>
            </Typography>
          </Typography>
          <br />
          <Typography variant="h6" noWrap>
            <img
              alt="Materiały studentów 4"
              className={classes.lectureImg}
              // style={{ width: 600 }}
              src={process.env.PUBLIC_URL + "/images/students/4.PNG"}
            />
            <Typography className={classes.wrapIcon}>
              {isSmall ? <PersonIcon /> : ""} <i>Terézia Királyová</i>
            </Typography>
          </Typography>
          <br />
          <Typography variant="h6" noWrap>
            <img
              alt="Materiały studentów 5"
              className={classes.lectureImg}
              // style={{ width: 600 }}
              src={process.env.PUBLIC_URL + "/images/students/5.PNG"}
            />
            <Typography className={classes.wrapIcon}>
              {isSmall ? <PersonIcon /> : ""} <i>Viliam Kardos</i>
            </Typography>
          </Typography>
          <br />
          <Typography variant="h6" noWrap>
            <img
              alt="Materiały studentów 6"
              className={classes.lectureImg}
              // style={{ width: 500 }}
              src={process.env.PUBLIC_URL + "/images/students/6.PNG"}
            />
            <Typography className={classes.wrapIcon}>
              {isSmall ? <PersonIcon /> : ""} <i>Vivien Lovasová</i>
            </Typography>
          </Typography>
          <br />

          <br />
          <Typography variant="h6" className={classes.wrapIcon} gutterBottom>
            {isSmall ? <CommentIcon /> : ""} Opowiadanka „z przymrużeniem oka”
          </Typography>
          <Typography paragraph align="justify">
            <b>Mnie ścierpła</b> na chwile <b>skóra</b> jak na mnie popatrzył i{" "}
            <b>uderzyła mi do głowy</b> miłość. Ale on był zakochany tylko{" "}
            <b>na pół gwizdka</b>. Dlatego miałam <b>serce ściśnięte</b> i już{" "}
            <b>nie chce mieć serca</b> dla nikogo.
            <br />
            <Typography className={classes.wrapIcon}>
              {isSmall ? <PersonIcon /> : ""} <i>Margareta Štrbová</i>, II rok
              licencjackich studiów środkowoeuropejskich i
            </Typography>
            <Typography className={classes.wrapIcon}>
              {isSmall ? <PersonIcon /> : ""}
              <i>Katarina Kostić</i>, II rok magisterskich studiów
              środkowoeuropejskich
            </Typography>
            <br />
            Codziennie <b>tyrał jak wół</b>, jego kobieta zerwała z nim i jego{" "}
            <b>serce zostało ściśnięte</b>. W weekend zdecydował się, że zabawi
            się. Wypił trzy piwa w barze i już mu <b>uderzyły do głowy</b>. Nie
            pamięta nic z całego wieczoru, tylko, że kiedy wracał się do domu
            policjant mu <b>deptał w piętach</b>. Zaczął uciekać. Ponieważ był
            pijany, <b>uciekał na pół gwizdka</b> i policjant go złapał. Rano
            dowiedział się, że ukradł tarczę do rzutek, aby odpędzić smutek.
            <br />
            <Typography className={classes.wrapIcon}>
              {isSmall ? <PersonIcon /> : ""} <i>Lucia Ranincová</i>, III rok
              licencjackich studiów środkowoeuropejskich
            </Typography>
            <br />
            Jestem osobą, która codziennie <b>tyra jak wół</b>. Osobą, która
            jest perfekcjonistą, osobą emocjonalną. Różne rzeczy łatwo mnie
            stresują i często mam <b>serce ściśnięte</b> z niepokoju. Ale kiedy
            wypiję trochę więcej wina, szybko <b>uderza mi do głowy</b> i nagle
            zmieniam się w zupełnie inną, nową osobę. W osobę, która wszystko
            robi <b>na pół gwizdka</b> i która <b>nie ma serca</b>, nie ma żalu.
            <br />
            <Typography className={classes.wrapIcon}>
              {isSmall ? <PersonIcon /> : ""} <i>Terezia Kiralyová</i>, I rok
              magisterskich studiów środkowoeuropejskich
            </Typography>
            <br />
            Już długo podróżowałem, więc musiałem{" "}
            <b>rozprostować sobie kości</b>. Nagle zacząłem mieć poczucie, że
            ktoś mi <b>depcze po piętach</b>. Obejrzałem się – nikogo nie ma.
            Raptownie usłyszałem jakiś głos, i zobaczyłem, że idzie do mnie
            jakaś babcia i krzyczy, że <b>nie mam serca</b> i to{" "}
            <b>odbiło się</b> u mnie{" "}
            <b>szerokim echem. Ścisnęło się mi serce</b>, przestraszyłem się,
            uciekłem do samochodu, zamknęłam się tam i siedziałem tam trzy
            godziny.
            <br />
            <Typography className={classes.wrapIcon}>
              {isSmall ? <PersonIcon /> : ""} <i>Stepan Kaliukanov</i>, III rok
              licencjackich studiów środkowoeuropejskich
            </Typography>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default PaperVocabulary;
