import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { HashLink } from "react-router-hash-link";
import { TT } from "../Team/helpers";

import Exercise from "./Exercise";
import { Button, Link } from "@material-ui/core";
import { useEffect, useState } from "react";

const scrollWithOffset = (el: any) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -80;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoImg: {
      margin: "auto",
      display: "block",
      maxWidth: "300px",
      maxHeight: "100px",
    },
    button: {
      margin: theme.spacing(1),
    },
    lectureImg: {
      margin: "auto",
      marginBottom: 2,
      display: "block",
      maxWidth: "60%",
      borderRadius: 8,
      border: "1px solid black",
      borderColor: "rgba(0,0,0,0.2)",
    },
    wrapIcon: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "& > svg": {
        marginRight: 10,
      },
    },
    mbl: {
      marginBottom: 50,
    },
    mbs: {
      marginBottom: 15,
    },
    cardContent: {
      padding: 40,
      paddingBottom: 20,
    },
  })
);

const data1 = [
  {
    a: "Całą/pełną gębą",
    b: "być zadufanym w sobie, zarozumiałym; być przekonanym o swojej nieomylności, racji",
  },
  {
    a: "Coś chodzi komuś po głowie",
    b: "inna cecha czegoś, najczęściej niewidoczna na pierwszy rzut oka; negatywna cecha",
  },
  {
    a: "Coś komuś leży na wątrobie",
    b: "mieć jakiś pomysł, nad czymś się zastanawiać, coś planować; myśleć nad rozwiązaniem  problemu",
  },
  {
    a: "Druga/odwrotna strona medalu",
    b: "czymś się denerwować, coś nie daje komuś spokoju",
  },
  {
    a: "Dwie strony medalu",
    b: "mieć trudny problem do rozwiązania",
  },
  {
    a: "Mieć twardy orzech do zgryzienia",
    b: "mówić dużo, bez sensu, denerwować przy tym innych",
  },
  {
    a: "Mleć językiem",
    b: "o kimś nieumiejącym się zachować, o prostackich, prymitywnych manierach; o nowobogackim, który wszedł do wyższej klasy społecznej i nieumiejętnie ją naśladuje",
  },
  {
    a: "Od słowa do słowa",
    b: "podczas rozmowy wspólnie dochodzić do jakiegoś wniosku",
  },
  {
    a: "Odbić/odbijać piłeczkę",
    b: "pozytywne i negatywne cechy, skutki czegoś; szanse i zagrożenia związane z robieniem czegoś",
  },
  {
    a: "Pozjadać wszystkie rozumy",
    b: "prawdziwy, mający wszystkie właściwości, cechy",
  },
  {
    a: "Słoma komuś z butów wychodzi/wystaje",
    b: "propozycja by mówić o czymś poważnie, na serio",
  },
  {
    a: "Żarty na bok!",
    b: "zabierać głos w dyskusji, prezentować odmienne zdanie niż rozmówca",
  },
];

const answers1 = [
  { a: 0, b: 9 },
  { a: 1, b: 2 },
  { a: 2, b: 3 },
  { a: 3, b: 1 },
  { a: 4, b: 8 },
  { a: 5, b: 4 },
  { a: 6, b: 5 },
  { a: 7, b: 7 },
  { a: 8, b: 11 },
  { a: 9, b: 0 },
  { a: 10, b: 6 },
  { a: 11, b: 10 },
];

const data2 = [
  {
    a: "1) Debata była bardzo długa. Kandydaci na prezydenta …………………………………………………….",
    b: "chodzi po głowie",
  },
  {
    a: "2) Długo dyskutowaliśmy, po dwóch godzinach …………………………………………………… .",
    b: "druga strona medalu",
  },
  {
    a: "3) [...] naraz przemienił się w dyskutanta …………………………………………………….",
    b: "dwie strony medalu",
  },
  {
    a: "4) Wystarczy posłuchać, w jaki sposób próbuje prowadzić dyskusje i od razu widać, że mu …………………………………………………… .",
    b: "leżało na wątrobie",
  },
  { a: "5) To mu …………………………………………………… .", b: "ma twardy orzech do zgryzienia" },
  { a: "6) Od dawna mi …………………………………………………… .", b: "mleć ozorem" },
  { a: "7) Teraz …………………………………………………… .", b: "od słowa do słowa" },
  { a: "8) On potrafi tylko …………………………………………………… .", b: "odbijali piłeczkę" },
  {
    a: "9) Zrobiła doktorat i wydaje się jej, że …………………………………………………… .",
    b: "pełną gębą",
  },
  {
    a: "10) ……………………………………………………, potrzebujemy poważnej kampanii reklamowej.",
    b: "pozjadała wszystkie rozumy",
  },
  {
    a: "11) Chociaż jest też …………………………………………………… . [...]",
    b: "słoma z butów wystaje",
  },
  { a: "12) Zawsze są …………………………………………………… .", b: "żarty na bok" },
];

const answers2 = [
  { a: 0, b: 7 },
  { a: 1, b: 6 },
  { a: 2, b: 8 },
  { a: 3, b: 10 },
  { a: 4, b: 3 },
  { a: 5, b: 0 },
  { a: 6, b: 4 },
  { a: 7, b: 5 },
  { a: 8, b: 9 },
  { a: 9, b: 11 },
  { a: 10, b: 1 },
  { a: 11, b: 2 },
];

function Exercise2() {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    document.title = "Interaktywne ćwiczenia - Dyskutant pełną gębą";
  }, []);

  return (
    <>
      <Typography variant="h5" component="h1">
        Dyskutant pełną gębą
      </Typography>
      <Typography variant="subtitle2">ćwiczenia na poziomie B2</Typography>
      <Typography variant="body2" gutterBottom style={{ marginBottom: 20 }}>
        dr Magdalena Zakrzewska-Verdugo
      </Typography>

      <Card className={classes.mbl}>
        <CardContent className={classes.cardContent}>
          <Typography variant="body2" align="justify">
            Niniejsza lekcja przeznaczona jest dla studentów na poziomie
            zaawansowania językowego B2. Koncentruje się przede wszystkim na
            kształceniu sprawności mówienia, zarówno na podstawie wypowiedzi
            monologowej, dyskusji, jak i komentarza do przeczytanych tekstów. W
            lekcji pojawia się również bogata frazeologia, z którą uczniowie
            zostają zapoznani w ramach poszerzania zasobu słownictwa.
            <br />
            <br />
            Tematem lekcji są problemy dyskusyjne, kontrowersyjne, dlatego też
            warto wprowadzić przed właściwą częścią zajęć pewne reguły dotyczące
            dyskusji, aby uniknąć nieprzyjemnych sytuacji. Przede wszystkim
            należy powiedzieć uczestnikom, że ich poglądy nie będą oceniane pod
            względem etycznym, a jedynie pod względem poprawności językowej, a
            także poprosić o szacunek do innych dyskutantów, nieobrażanie i
            niewyśmiewanie się nawzajem. Oczywiście lektor jako moderator
            powinien stanowić tu wzór zachowania i, o ile to możliwe, wstrzymać
            się od jakichkolwiek stronniczych wypowiedzi.
            <br />
            <br />
            <b>
              <HashLink
                to="#cwiczenie-1"
                smooth
                scroll={(el) => scrollWithOffset(el)}
                className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
              >
                Ćwiczenie I
              </HashLink>
            </b>{" "}
            można zrealizować na kilka sposobów: studenci mogą na zadanie domowe
            przeczytać wszystkie opisy dylematów moralnych, a następnie na
            początku lekcji podzielić się na grupy według tego, który dylemat
            wydał im się najbliższy/najciekawszy, albo też mogą dostać
            przydzieloną tylko jedną sytuację do przeczytania i
            przedyskutowania. Podczas dyskusji w grupach (maks. 15 min) będą
            musieli przygotować argumenty uzasadniające dokonanie jednego i
            drugiego wyboru, które następnie przedstawią na forum. Po
            prezentacji każdej grupy należy pozostawić przestrzeń na komentarze
            i pytania słuchaczy.
            <br />
            <br />
            <b>
              <HashLink
                to="#cwiczenie-2"
                smooth
                scroll={(el) => scrollWithOffset(el)}
                className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
              >
                Ćwiczenie II
              </HashLink>
            </b>{" "}
            odnosi się do związków frazeologicznych obecnych w tekstach z
            ćwiczenia I. Studenci są proszeni o to, aby połączyć lewą i prawą
            kolumnę, czyli frazeologizmy oraz ich znaczenie. Odpowiedzi można
            znaleźć w kluczu, a także w słowniczku frazeologicznym.
            <br />
            <br />
            <b>
              <HashLink
                to="#cwiczenie-3"
                smooth
                scroll={(el) => scrollWithOffset(el)}
                className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
              >
                Ćwiczenie III
              </HashLink>
            </b>{" "}
            odnosi się do użycia frazeologizmów w kontekście – studenci nie
            powinni mieć problemu z uzupełnieniem zdań, ponieważ w ćwiczeniu I
            pojawiły się już ich przykłady użycia, zaś w ćwiczeniu II –
            definicje i formy kanoniczne. Poprawne odpowiedzi znajdują się w
            kluczu.
            <br />
            <br />
            <b>
              <HashLink
                to="#cwiczenie-4"
                smooth
                scroll={(el) => scrollWithOffset(el)}
                className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
              >
                Ćwiczenie IV
              </HashLink>
            </b>{" "}
            to ponownie zadanie komunikacyjne – studenci poproszeni są o to,
            żeby wybrali jeden temat dyskusyjny i spróbowali wyjaśnić, dlaczego
            wzbudza on kontrowersje społeczne. Należy przy tym wykorzystać co
            najmniej dwa nowo poznane związki frazeologiczne. W zależności od
            liczebności grupy, studenci mogą pracować indywidualnie lub w
            parach/grupach. Można również zadać to ćwiczenie jako zadanie domowe
            – do przygotowania ustnego lub pisemnego.
            <br />
            <br />
            Ostatnie{" "}
            <b>
              <HashLink
                to="#cwiczenie-5"
                smooth
                scroll={(el) => scrollWithOffset(el)}
                className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
              >
                ćwiczenie V
              </HashLink>
            </b>{" "}
            może stanowić podstawę do dyskusji – odnosi się bowiem do
            kontrowersyjnych tematów prezentowanych przez polskich respondentów.
            Można poprosić studentów o to, aby wypowiedzieli się, czy sądzą
            (mogą również poszukać informacji o tym w Internecie), że ich rodacy
            odpowiedzieliby podobnie na takie pytania, ewentualnie z czego
            wynikałyby różnice, albo czy odpowiedzi udzielone na niektóre
            pytania ich zaskoczyły (np. duże poparcie dla kary śmierci pomimo
            tego, że jest ona prawnie zakazana). Wykorzystanie quizu na lekcji
            zależy od inwencji lektora, liczebności grupy oraz czasu, którym się
            dysponuje. Jednostka lekcyjna jest jednak przewidziana na co
            najmniej 90 min.
            <br />
            <br />
            Niniejsza lekcja jest przeznaczona do wykorzystania ze studentami na
            poziomie zaawansowania A2/B1. Jest ona zaplanowana jako nawiązanie
            do lekcji pod tym samym tytułem znajdującej się w publikacji „Polski
            na A. Część II. Podręcznik języka polskiego dla Słowaków“ (2014)
            autorstwa Moniki Krzempek. Lekcja ta tematycznie związana jest z
            turystyką, a także rozszerza zasób słownictwa studentów przede
            wszystkim o aspekt frazeologiczny. Cała lekcja oparta jest na jednym
            adaptowanym tekście autentycznym dotyczącym ekoturystyki (odnośnik
            znajduje się pod lekcją).
            <br />
            <br />
          </Typography>
        </CardContent>
      </Card>
      <Card className={classes.mbs} id="cwiczenie-1">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie I.A
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            CZYTANIE I MÓWIENIE: Dylematy moralne. Wybierz jedną sytuację, a
            następnie porozmawiaj w grupie nad argumentami przemawiającymi za
            jednym i drugim wyborem. Spróbujcie zająć wspólne stanowisko. Co
            byście zrobili na miejscu bohatera?
          </Typography>
          <br />
          <Typography variant="body2" align="justify">
            1. Grzegorz pewnego dnia dowiedział się, że jego przyjaciel,
            Krzysztof, choruje na nieuleczalną chorobę. Diagnozy lekarzy są
            jednoznaczne: nie ma na nią lekarstwa, a w ostatnim stadium
            pacjentom towarzyszy okropny ból. Podczas odwiedzin w szpitalu{" "}
            <TT
              title={
                <>‘podczas rozmowy wspólnie dochodzić do jakiegoś wniosku’</>
              }
              label="od słowa do słowa"
            />{" "}
            przyjaciel prosi Grzegorza, aby pomógł mu ulżyć w cierpieniu i
            załatwił mu marihuanę, która podobno działa przeciwbólowo. Grzegorz
            chciałby pomóc przyjacielowi, ale{" "}
            <TT
              title={<>‘czymś się denerwować, coś nie daje komuś spokoju’</>}
              label="leży mu na wątrobie"
            />
            , że zakup i spożycie marihuany jest nielegalne w jego kraju. Jeśli
            zostanie złapany z marihuaną w kieszeni albo podczas kontaktu z
            dilerem, może nawet pójść do więzienia. Co byś zrobił(a) na jego
            miejscu?
            <br />
            <br />
            2. Grażyna prowadzi swoją firmę w Polsce, ale zarejestrowała ją w
            Czechach, gdzie podatki dla przedsiębiorców są niższe. Zwróciła się
            po pomoc w złożeniu zeznania podatkowego do swojego przyjaciela,
            Kazimierza, który z zawodu jest księgowym. Podczas przygotowania
            deklaracji Kazimierz zorientował się, że Grażyna nie tylko
            zaoszczędza dużo na podatkach ze względu na to, że płaci je w
            Czechach, ale jeszcze nie zawsze wystawia swoim klientom faktury, do
            czego jest zobowiązana przez prawo. Kazimierz nie chce{" "}
            <TT
              title={<>‘mówić dużo, bez sensu, denerwować przy tym innych’</>}
              label="mleć jęzorem"
            />{" "}
            i donosić do urzędu skarbowego, ale uważa, że oszukuje ona swoje
            państwo. Porozmawiał o tym z Grażyną, ale ona twierdzi, że
            zaoszczędzone pieniądze przekazuje na dobroczynność – wysyła kilka
            tysięcy złotych na schronisko dla bezdomnych zwierząt oraz dom
            dziecka. Z jednej strony Kazimierz cieszy się, że Grażyna pomaga
            potrzebującym, ale z drugiej strony{" "}
            <TT
              title={
                <>
                  ‘mieć jakiś pomysł, nad czymś się zastanawiać, coś planować;
                  myśleć nad rozwiązaniem problemu’
                </>
              }
              label="chodzi mu po głowie"
            />
            , że może powinna robić to legalnie. Co poradzisz Grażynie i
            Kazimierzowi?
            <br />
            <br />
            3. Jacek jest naukowcem w wielkiej firmie farmaceutycznej. Został
            szefem zespołu badawczego, który ma przed sobą wspaniałe zadanie –
            wynaleźć skuteczny lek przeciw koronawirusowi. Jego zespół jest na
            dobrej drodze do osiągnięcia sukcesu, potrzebuje już tylko
            przeprowadzić testy potwierdzające skuteczność lekarstwa. Jacek{" "}
            <TT
              title={<>‘mieć trudny problem do rozwiązania’</>}
              label="ma jednak trudny orzech do zgryzienia"
            />
            : może przeprowadzić testy za pomocą modelowania komputerowego,
            które nie przewiduje efektów działania leków w 100%, ale z dużym
            prawdopodobieństwem, albo przeprowadzić testy na zwierzętach, które
            również mają{" "}
            <TT
              title={
                <>
                  ‘pozytywne i negatywne cechy, skutki czegoś; szanse i
                  zagrożenia związane z robieniem czegoś’
                </>
              }
              label="dwie strony medalu"
            />
            : z jednej strony są dla zwierząt laboratoryjnych bardzo bolesne,
            często śmiertelne, lecz z drugiej strony – są w 100% efektywne i
            pozwalają wykluczyć jakiekolwiek niebezpieczeństwo dla ludzi. Co
            powinien zrobić Jacek?
            <br />
            <br />
            4. Bożena jest szefową organizacji pozarządowej, która pomaga
            uchodźcom. Pewnego razu do schroniska, które prowadzi wraz z
            wolontariuszami, zostaje przyjęta rodzina z kraju, w którym toczy
            się krwawa wojna. Są to rodzice oraz pięcioro niepełnoletnich
            dzieci, z których najmłodsze to jeszcze niemowlę, a najstarsze to
            nastolatek z niepełnosprawnością ruchową. Jednocześnie do Bożeny
            dzwoni znajomy celnik, który mówi jej, że dostał niepotwierdzony
            sygnał, że ojciec rodziny może być członkiem międzynarodowej
            organizacji terrorystycznej, więc najbezpieczniej będzie, jeśli
            Bożena poinformuje o tym policję imigracyjną, która odeśle rodzinę z
            powrotem do ich kraju. Bożena wie, że ani ona, ani jej znajomy nie{" "}
            <TT
              title={
                <>
                  ‘być zadufanym w sobie, zarozumiałym; być przekonanym o swojej
                  nieomylności, racji’
                </>
              }
              label="pozjadali wszystkich rozumów"
            />{" "}
            i informacja może być tylko plotką. Co powinna zrobić Bożena: pozbyć
            się problemu, czy{" "}
            <TT
              title={
                <>
                  ‘zabierać głos w dyskusji, prezentować odmienne zdanie niż
                  rozmówca’
                </>
              }
              label="odbić piłeczkę"
            />{" "}
            w dyskusji ze znajomym i kierować się przede wszystkim dobrem
            dzieci, które w kraju ogarniętym wojną mogą nie mieć warunków do
            rozwoju?
          </Typography>
        </CardContent>
      </Card>
      <Card className={classes.mbl}>
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie I.B
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            REBUS: odgadnij, co to za związek frazeologiczny!
          </Typography>
          <br />
          <Typography variant="h6" noWrap>
            <img
              alt="Rebus"
              className={classes.lectureImg}
              src={process.env.PUBLIC_URL + "/images/rebus.png"}
            />
            <br />
            {!isVisible && (
              <Button variant="outlined" onClick={() => setIsVisible(true)}>
                Pokaż odpowiedź
              </Button>
            )}
            {isVisible && (
              <>
                <Typography variant="body1">Odpowiedź: </Typography>
                <Typography variant="h5" noWrap>
                  Dwie strony medalu
                </Typography>
              </>
            )}
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.mbs} id="cwiczenie-2">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie II
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            LEKSYKA: Połącz związki frazeologiczne, z których większość pojawiła
            się w powyższych tekstach z ich definicjami
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data1}
        answers={answers1}
        mb={true}
        uniqueItemTypeName="c1"
      />

      <Card className={classes.mbs} id="cwiczenie-3">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie III
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            LEKSYKA: Uzupełnij poniższe zdania związkami frazeologicznymi z{" "}
            <HashLink
              to="#cwiczenie-2"
              smooth
              scroll={(el) => scrollWithOffset(el)}
              className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
            >
              ćwiczenia II
            </HashLink>
            . Uwaga! Każdy frazeologizm może zostać użyty tylko jeden raz!
          </Typography>
          <Typography variant="body2" align="justify">
            <br />
            <br />
            1. Debata była bardzo długa. Kandydaci na prezydenta
            ……………………………………………………<sup>1</sup>. i nie mogli skończyć. Na szczęście
            mieli ograniczony czas wypowiedzi.
            <br />
            <br />
            2. Długo dyskutowaliśmy, po dwóch godzinach ……………………………………………………
            <sup>2</sup>. udało się nam dojść do porozumienia. Teraz możemy
            zacząć działać – wszystko ustalone!
            <br />
            <br />
            3. Ku zaskoczeniu wszystkich, z nieśmiałego, cichego,
            przestraszonego człowieczka, naraz przemienił się w dyskutanta
            ……………………………………………………<sup>3</sup>. Widocznie albo ktoś go
            zdenerwował, albo był to dla niego szczególnie ważny temat. Trzeba
            przyznać, że używał naprawdę świetnych argumentów.
            <br />
            <br />
            4. Myślał, że ubranie za kilka tysięcy złotych od razu zrobi z niego
            ikonę mody, ale tak łatwo to w życiu nie ma. Wystarczy posłuchać, w
            jaki sposób próbuje prowadzić dyskusje i od razu widać, że mu
            ……………………………………………………<sup>4</sup>. Nie potrafi powiedzieć jednego
            zdania bez błędów i wulgaryzmów.
            <br />
            <br />
            5. Nie mógł przestać myśleć o ostatniej bezsensownej dyskusji z
            Sylwią. Powinien był nic nie mówić i po prostu przyznać jej rację.
            To mu ……………………………………………………<sup>5</sup>. i nie wiedział, co powinien
            zrobić, by znowu zaczęli żyć w dobrych stosunkach.
            <br />
            <br />
            6. Od dawna mi ……………………………………………………<sup>6</sup>. pomysł napisania
            książki złożonej z piosenek, do których skomponowałbym wraz z
            Andrzejem muzykę. Moglibyśmy nawet koncertować z tym materiałem, a
            podczas występów trochę improwizować.
            <br />
            <br />
            7. Ola dostała stypendium, o którym zawsze marzyła – na
            uniwersytecie w USA. Ma jednak fantastycznego chłopaka, którego
            kocha i z którym nie chce się rozstawać. Nie wie, co robić. Teraz
            ……………………………………………………<sup>7</sup>.
            <br />
            <br />
            8. On potrafi tylko ……………………………………………………<sup>8</sup>. Dużo obiecuje,
            mało robi, z jego wypowiedzi nie wynika nic ciekawego. Obawiam się,
            że nasz projekt upadnie.
            <br />
            <br />
            9. Ostatnio Karolina jest nie do zniesienia. Zrobiła doktorat i
            wydaje się jej, że ……………………………………………………<sup>9</sup>. W każdej
            dyskusji próbuje udowodnić, że na wszystkim zna się lepiej niż jej
            rozmówca.
            <br />
            <br />
            10. Problem segregowania śmieci nie jest śmieszny.
            ……………………………………………………<sup>10</sup>, potrzebujemy poważnej kampanii
            reklamowej.
            <br />
            <br />
            11. Wolę egzamin pisemny od ustnego. Ten pierwszy zdaje mi się
            lepiej. Chociaż jest też ……………………………………………………<sup>11</sup>: na
            egzaminie pisemnym można zrobić błędy, a one z pewnością pogorszą
            ocenę.
            <br />
            <br />
            12. Zawsze są ……………………………………………………<sup>12</sup>. Fakt faktem,
            niezależność finansowa od rodziców w czasie studiów daje mnóstwo
            wolności i satysfakcji, ale wymaga też dużo samodyscypliny, żeby
            pogodzić studiowanie z pracą. Nie wszyscy to potrafią.
            <br />
            <br />
            Na podstawie:
            <br />
            <Link
              href="https://repozytorium.amu.edu.pl/bitstream/10593/25561/1/Piotr%20Flici%c5%84ski_Wspolczesny_slownik_frazeologiczny_author%27s%20version.pdf"
              target="_blank"
              rel="noreferrer"
            >
              https://repozytorium.amu.edu.pl/bitstream/10593/25561/1/Piotr%20Flici%c5%84ski_Wspolczesny_slownik_frazeologiczny_author%27s%20version.pdf
            </Link>
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data2}
        answers={answers2}
        mb={true}
        uniqueItemTypeName="c2"
      />

      <Card className={classes.mbl} id="cwiczenie-4">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie IV
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            MÓWIENIE: Poniżej znajdują się inne problemy dyskusyjne. Wybierz
            jeden z nich i spróbuj wyjaśnić, dlaczego wzbudzają takie
            kontrowersje w wielu społeczeństwach. W wyjaśnieniu wykorzystaj
            przynajmniej 2 z powyższych związków frazeologicznych.
          </Typography>
          <Typography variant="body2" align="justify">
            <br />• kara śmierci
            <br />• klonowanie
            <br />• wolność/cenzura w sieci/mediach
            <br />• wolność ekonomiczna (powszechna/prywatna służba zdrowia,
            wysokie/niskie podatki)
            <br />• adopcja dzieci przez pary jednopłciowe
            <br />• legalizacja twardych narkotyków
            <br />• legalizacja prostytucji
            <br />• eutanazja
            <br />• aborcja
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.mbs} id="cwiczenie-5">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie V
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            QUIZ: Sprawdź, czy Twoje poglądy na kontrowersyjne tematy są zbieżne
            z poglądami innych internautów:
            <br />
            <Link
              href="https://www.quizme.pl/quiz/preference_start/1207198048"
              target="_blank"
              rel="noreferrer"
            >
              https://www.quizme.pl/quiz/preference_start/1207198048
            </Link>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default Exercise2;
