import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PeopleIcon from "@material-ui/icons/People";
import SendIcon from "@material-ui/icons/Send";
import ContactsIcon from "@material-ui/icons/Contacts";
import MailIcon from "@material-ui/icons/Mail";
import InfoIcon from "@material-ui/icons/Info";
import Button from "@material-ui/core/Button";
import { Box, useMediaQuery } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { useEffect } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoImg: {
      margin: "20px auto",
      display: "block",
      maxWidth: 300,
      maxHeight: 100,
      minWidth: 190,
      [theme.breakpoints.down("xs")]: {
        maxWidth: "90%",
      },
    },
    wrapIcon: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "& > svg": {
        marginRight: 10,
      },
    },
    button: {
      margin: theme.spacing(1),
      marginLeft: 0,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 5,
        paddingRight: 5,
      },
    },
    mainCard: {
      padding: 40,
      [theme.breakpoints.down("xs")]: {
        padding: 20,
      },
    },
  })
);

function Home() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    document.title = "Współczesna kultura polska we frazeologii";
  }, []);

  return (
    <>
      <Typography variant="h5" component="h1">
        Współczesna kultura polska we frazeologii
      </Typography>
      <Typography variant="subtitle2" gutterBottom style={{ marginBottom: 20 }}>
        (numer projektu: PJP/PJP/2020/1/00015)
        <br />
        październik 2020 – październik 2021
      </Typography>
      <Card>
        <CardContent className={classes.mainCard}>
          <Typography paragraph align="left">
            <Typography
              variant="h6"
              component="h2"
              className={classes.wrapIcon}
            >
              <PeopleIcon /> Zespół
            </Typography>
            dr hab. Gabriela Dziamska-Lenart, prof. UAM{" "}
            <Typography variant="body2">
              (Pracownia Leksykograficzna UAM)
            </Typography>
            <br />
            do 23 kwietnia 2021 r.: dr Piotr Fliciński{" "}
            <Typography variant="body2">
              (Zakład Frazeologii i Kultury Języka Polskiego UAM)
            </Typography>
            <br />
            dr Zuzana Obertová{" "}
            <Typography variant="body2">
              (Katedra Filologii Słowiańskich, Uniwersytet Komeńskiego w
              Bratysławie)
            </Typography>
            <br />
            dr Ewelina Woźniak-Wrzesińska{" "}
            <Typography variant="body2">
              (Zakład Frazeologii i Kultury Języka Polskiego UAM, koordynatorka)
            </Typography>
            <br />
            dr Magdalena Zakrzewska-Verdugo{" "}
            <Typography variant="body2">
              (Katedra Filologii Słowiańskich, Uniwersytet Komeńskiego w
              Bratysławie)
            </Typography>
            <br /> dr Jan Zgrzywa{" "}
            <Typography variant="body2">
              (Pracownia Leksykograficzna UAM)
            </Typography>
            <br />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ContactsIcon />}
              href="#/zespol"
            >
              Więcej o zespole
            </Button>
            <br />
            <br />
            <Typography
              variant="h6"
              component="h2"
              className={classes.wrapIcon}
            >
              <MailIcon /> Szybki kontakt
            </Typography>
            {/* <a href="mailto:ewewoz@amu.edu.pl">ewewoz@amu.edu.pl</a>
            <br />
            <a href="mailto:magdalena.zakrzewska@uniba.sk">
              magdalena.zakrzewska@uniba.sk
            </a> */}
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              startIcon={isSmall ? <SendIcon /> : undefined}
              href="mailto:ewewoz@amu.edu.pl"
            >
              ewewoz@amu.edu.pl
            </Button>
            <br />
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              startIcon={isSmall ? <SendIcon /> : undefined}
              href="mailto:magdalena.zakrzewska@uniba.sk"
            >
              magdalena.zakrzewska@uniba.sk
            </Button>
            <br />
            <br />
            <Typography
              variant="h6"
              component="h2"
              className={classes.wrapIcon}
            >
              <InfoIcon /> Informacje
            </Typography>
            <Typography paragraph align="justify">
              Projekt uzyskał finansowanie w konkursie{" "}
              <i>Promocja Języka Polskiego </i>
              <Link
                href="https://nawa.gov.pl/"
                target="_blank"
                rel="noreferrer"
              >
                Narodowej Agencji Wymiany Akademickiej
              </Link>{" "}
              i był realizowany przez badaczki i badaczy z{" "}
              <Link
                href="https://wfpik.amu.edu.pl/strona-glowna/wfpik/aktualnosci"
                target="_blank"
                rel="noreferrer"
              >
                Wydziału Filologii Polskiej i Klasycznej
              </Link>{" "}
              Uniwersytetu im. Adama Mickiewicza w Poznaniu oraz z{" "}
              <Link
                href="https://fphil.uniba.sk/ksf/"
                target="_blank"
                rel="noreferrer"
              >
                Katedry Filologii Słowiańskich
              </Link>{" "}
              Uniwersytetu Komeńskiego w Bratysławie.
            </Typography>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              alignContent="flex-start"
              alignItems="flex-start"
              p={1}
              m={1}
              style={{ maxWidth: "100%", background: "white", borderRadius: 5 }}
            >
              <Box alignSelf="flex-start" style={{ margin: "0 5px" }}>
                <Typography variant="h6">
                  <img
                    className={classes.logoImg}
                    alt="LOGO główne"
                    src={process.env.PUBLIC_URL + "/images/main_logo.svg"}
                  />
                </Typography>
                <Typography variant="h6">
                  <img
                    className={classes.logoImg}
                    alt="LOGO NAWA"
                    src={process.env.PUBLIC_URL + "/images/nawa_logo.svg"}
                  />
                </Typography>
                <Typography variant="h6">
                  <img
                    className={classes.logoImg}
                    // style={{ maxWidth: "160px", maxHeight: "160px" }}
                    alt="LOGO UK"
                    src={process.env.PUBLIC_URL + "/images/UK_dark.png"}
                  />
                </Typography>
              </Box>
              {/* <Box alignSelf="flex-start" style={{ margin: "0 5px" }}>
                <Typography variant="h6">
                  <img
                    className={classes.logoImg}
                    style={{ maxWidth: "160px", maxHeight: "160px" }}
                    alt="LOGO"
                    src={process.env.PUBLIC_URL + "/images/FiFUK.svg"}
                  />
                </Typography>
              </Box> */}
            </Box>
            {/* <Box>
              <Typography variant="h6">
                <img
                  className={classes.logoImg}
                  alt="LOGO"
                  src={
                    process.env.PUBLIC_URL +
                    (theme.palette.type === "light"
                      ? "/images/main_logo.svg"
                      : "/images/main_logo_white.svg")
                  }
                />
              </Typography>
              <Typography variant="h6">
                <img
                  className={classes.logoImg}
                  alt="LOGO"
                  src={
                    process.env.PUBLIC_URL +
                    (theme.palette.type === "light"
                      ? "/images/nawa_logo.svg"
                      : "/images/nawa_logo_white.svg")
                  }
                />
              </Typography>
            </Box> */}
            <Typography paragraph align="justify">
              Podczas zaplanowanych działań przeprowadziliśmy dla studentów
              bratysławskiej slawistyki serię{" "}
              <Link href="#/wyklady-warsztaty">
                wykładów oraz warsztatów na temat polskiej frazeologii i
                frazeografii
              </Link>
              . Dwusemestralne zajęcia poszerzyły kompetencję frazeologiczną
              uczestników, a co za tym idzie – pragmalingwistyczną. Zwieńczeniem
              projektowej pracy studentów jest{" "}
              <b>
                słowniczek frazeologiczny{" "}
                <Link href="#/slownik">polsko-słowacki</Link> /{" "}
                <Link href="#/slownik-slowacki">słowacko-polski</Link>
              </b>
              , który może w przyszłości posłużyć m.in. jako inspiracja do
              poszerzonych badań komparatystycznych lub jako punkt wyjścia do
              stworzenia gry edukacyjnej.
              <br />
              Konfrontacja ze studentami i owocne dyskusje w zespole pozwoliły
              nam pogłębić refleksję na temat roli frazeologii w nauce i
              nauczaniu języka polskiego jako obcego. Efektem tych rozważań jest{" "}
              <b>
                <i>
                  {" "}
                  <Link
                    href="https://www.wydawnictworys.com/media/products/1dd91cf45af225ded23cc589936fe7f0/attachments/pl_PL/slownik-frazeologiczny-z-cwiczeniami-dla-uczacych-sie-jezyka-polskiego-jako-obcego-online-format-pdf.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Słownik frazeologiczny z ćwiczeniami dla uczących się języka
                    polskiego jako obcego
                  </Link>
                </i>
              </b>
              , który wypełnia lukę na polskim rynku wydawniczym, wzbogacając go
              o swoisty frazeologiczny leksykon minimum. Dodatek autorstwa
              wykładowczyń z Bratysławy obejmuje nie tylko zadania dla różnych
              poziomów znajomości polszczyzny (A1 – C1), lecz także wprawki
              tłumaczeniowe (polsko-słowackie).
              <br />
              Nie możemy nie wspomnieć, że sytuacja epidemiczna skutkowała
              istotnymi zmianami w projekcie. Wykłady i warsztaty prowadziliśmy
              zdalnie. Dostosowaliśmy więc materiał do interaktywnych potrzeb
              słuchaczy, co zachęciło nas (dr Zuzanę Obertovą oraz dr Magdalenę
              Zakrzewską-Verdugo) do stworzenia{" "}
              <Link href="#/cwiczenia">szeregu dodatkowych ćwiczeń</Link>
              .
              <br />
              Mimo intensywnych prac, których efekty prezentujemy w treści tej
              witryny, nie uważamy, że projekt wyczerpuje zagadnienie roli
              frazeologii w nauczaniu języka polskiego jako obcego. Wręcz
              przeciwnie – jedynie sygnalizuje obszary, które naszym zdaniem
              warto poddać oglądowi, by móc w glottodydaktyce sukcesywnie
              wykorzystywać frazeologię i jej potencjał: kulturowy (zob.
              warsztaty <i><b>Wprowadzenie do polskiej frazeologii</b></i>,{" "}
              <i>
                <b>
                  {" "}
                  Przeszłość Polski i dawna kultura w zwierciadle frazeologii,
                  Tożsamość narodowa zawarta we frazeologizmach
                </b>
              </i>
              ), obrazotwórczy (zob. warsztaty
              <i>
                <b> Frazeologia w literaturze</b>
              </i>
              ) czy językowy (zob. warsztaty
              <i>
                <b> Wprowadzenie do leksykografii frazeologicznej</b>
              </i>
              ).
            </Typography>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default Home;
