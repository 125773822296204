import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion, AccordionSummary, useStyles, TT } from "./helpers";
import { Grid } from "@material-ui/core";

export default function Team() {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Avatar
        alt="dr Piotr Fliciński"
        src={process.env.PUBLIC_URL + "/images/team/B1.jpg"}
        className={classes.avatar}
      />
      <CardContent>
        <Typography variant="h6" component="h2">
          dr Piotr Fliciński (do 23 kwietnia w projekcie)
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Zakład Frazeologii i Kultury Języka Polskiego
          <br />
          Uniwersytet im. Adama Mickiewicza w Poznaniu
        </Typography>
      </CardContent>

      <CardActions>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          href="mailto:flinta@amu.edu.pl"
        >
          E-mail
        </Button>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          href="https://orcid.org/0000-0002-6910-4882"
          target="_blank"
        >
          ORCID
        </Button>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          href="http://kjp.amu.edu.pl/pf.html"
          target="_blank"
        >
          WWW
        </Button>
      </CardActions>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Więcej</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Card className={classes.photoCard}>
                <img
                  className={classes.photo}
                  alt="zdjęcie uczestnika"
                  src={process.env.PUBLIC_URL + "/images/team/B1.jpg"}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="justify"
                className={classes.personDesc}
              >
                Istnieje duże prawdopodobieństwo, że{" "}
                <TT
                  title={
                    <>
                      <i>ktoś</i> dobrze zna się na <i>czymś</i>: ‘ktoś jest
                      specjalistą w jakiejś dziedzinie’
                    </>
                  }
                  label="dobrze znam się"
                />{" "}
                na językowych wielowyrazowcach, które charakteryzują się
                nieregularnością semantyczną bądź gramatyczną. Gdybym się mylił,
                cóż… Zostałbym z pewnością gwiazdą rocka.
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
