import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { HashLink } from "react-router-hash-link";
import { Accordion, AccordionSummary, TT } from "../Team/helpers";

import Exercise from "./Exercise";
import { Chip, Link } from "@material-ui/core";
import { useEffect } from "react";

const scrollWithOffset = (el: any) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -80;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoImg: {
      margin: "auto",
      display: "block",
      maxWidth: "300px",
      maxHeight: "100px",
    },
    button: {
      margin: theme.spacing(1),
    },
    lectureImg: {
      margin: "auto",
      marginBottom: 2,
      display: "block",
      maxWidth: "60%",
      borderRadius: 8,
      border: "1px solid black",
      borderColor: "rgba(0,0,0,0.2)",
    },
    wrapIcon: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "& > svg": {
        marginRight: 10,
      },
    },
    mbl: {
      marginBottom: 50,
    },
    mbs: {
      marginBottom: 15,
    },
    cardContent: {
      padding: 40,
      paddingBottom: 20,
    },
  })
);

const data1 = [
  {
    a: "Co jest najważniejszą cechą frazeologizmu z punktu widzenia przekładu?",
    b: "Często nie ma odpowiedników z takimi samymi komponentami.",
  },
  {
    a: "Co ma na myśli autor, pisząc, że większość frazeologizmów nie ma odpowiedników w języku przekładu?",
    b: "Tłumaczymy sens i styl danego frazeologizmu za pomocą środków wypowiedzi typowych dla języka docelowego.",
  },
  {
    a: "Jak stosuje się ekwiwalencję funkcjonalną w przekładzie frazeologizmów?",
    b: "Znaczenie frazeologizmu nie jest sumą znaczeń poszczególnych słów.",
  },
];

const answers1 = [
  { a: 0, b: 2 },
  { a: 1, b: 0 },
  { a: 2, b: 1 },
];

const data2 = [
  {
    a: "tajniak",
    b: "osoba, która uważa, że wszystko wie najlepiej",
  },
  {
    a: "prochowiec",
    b: "radio informujące bez cenzury o wydarzeniach w krajach komunistycznych w drugiej połowie XX wieku",
  },
  {
    a: "szyfr",
    b: "cienki płaszcz",
  },
  {
    a: "centymetr kwadratowy",
    b: "tajny funkcjonariusz policji (potocznie)",
  },
  {
    a: "chusteczka",
    b: "cm²",
  },
  {
    a: "mądrala",
    b: "kawałek papieru lub tkaniny",
  },
  {
    a: "Wolna Europa",
    b: "kod ukrywający tajną informację",
  },
  {
    a: "ktoś igra z ogniem",
    b: "ktoś robi coś niebezpiecznego",
  },
];

const answers2 = [
  { a: 0, b: 3 },
  { a: 1, b: 2 },
  { a: 2, b: 6 },
  { a: 3, b: 4 },
  { a: 4, b: 5 },
  { a: 5, b: 0 },
  { a: 6, b: 1 },
  { a: 7, b: 7 },
];

const data3 = [
  {
    a: "Frazeologizm „ktoś ma do kogoś interes” oznacza, że …………….",
    b: "ktoś prosi kogoś (zazwyczaj rodzica) o zezwolenie na ślub",
  },
  {
    a: "Frazeologizm „ktoś wziął się w garść” oznacza, że …………….",
    b: "ktoś chce z kimś rozwiązać jakiś problem, jakąś sprawę",
  },
  {
    a: "Frazeologizm „ktoś prosi o czyjąś rękę” oznacza, że …………….",
    b: "ktoś przezwyciężył chwilę słabości",
  },
];

const answers3 = [
  { a: 0, b: 1 },
  { a: 1, b: 2 },
  { a: 2, b: 0 },
];

const data4 = [
  {
    a: "Warszawa",
    b: "wyrzucić z ust ślinę",
  },
  {
    a: "koleina",
    b: "mówić wulgaryzmy",
  },
  {
    a: "kląć",
    b: "ślad po kołach samochodów na drodze",
  },
  {
    a: "splunąć",
    b: "pozostać przy swoim zdaniu",
  },
  {
    a: "uprzeć się (ona uparła się)",
    b: "polska marka samochodu",
  },
];

const answers4 = [
  { a: 0, b: 4 },
  { a: 1, b: 2 },
  { a: 2, b: 1 },
  { a: 3, b: 0 },
  { a: 4, b: 3 },
];

function Exercise2() {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Interaktywne ćwiczenia - Igraszki z ogniem";
  }, []);

  return (
    <>
      <Typography variant="h5" component="h1">
        Igraszki z ogniem w przekładzie literackim
      </Typography>
      <Typography variant="subtitle2">ćwiczenia na poziomie C1</Typography>
      <Typography variant="body2" gutterBottom style={{ marginBottom: 20 }}>
        dr Zuzana Obertová
      </Typography>

      <Card className={classes.mbl}>
        <CardContent className={classes.cardContent}>
          <Typography variant="body2" align="justify">
            Proponowany materiał dydaktyczny na temat frazeologii w przekładzie
            literackim jest przeznaczony dla osób uczących się języka polskiego
            jako obcego na poziomie celującym w C1. Lekcja przedstawia
            podstawowe strategie w tłumaczeniu kanonicznych postaci
            frazeologizmów i przygotowuje studentów do ich rozpoznania w tekście
            literackim.
            <br />
            <br />
            Lekcja rozpoczyna się od krótkiego wstępu teoretycznego opartego na
            artykule K. Lipińskiego. Aby ułatwić studentom jego odbiór, tekst
            ten został skrócony i uproszczony. Po nim następują pytania
            kontrolne, które z jednej strony sprawdzają, na ile przeczytany
            fragment został zrozumiany, a z drugiej strony zwracają uwagę na
            kluczowe informacje w nim zawarte. Studenci uczący się języka
            polskiego w Bratysławie poznają w ramach kierunku{" "}
            <i>studia środkowoeuropejskie</i> podstawy frazeologii słowackiej, a
            także słowacką szkołę przekładu, a niniejsze lekcje są wprowadzeniem
            do teorii polskiej. Mogą one stanowić niezbędne tło teoretyczne dla
            ćwiczeń przekładowych z języka polskiego na jakikolwiek inny język.
            <br />
            <br />
            Kolejną część przedstawionego materiału stanowi praca z tekstem
            literackim. Lekcja zawiera dwa fragmenty powieści Olgi Tokarczuk
            <i> Prawiek i inne czasy</i>, w których znajdują się frazeologizmy w
            postaci kanonicznej. Ćwiczenia leksykalne wyjaśniają znaczenie
            realiów lub potencjalnie nieznanych wyrazów, wspierając w ten sposób
            pierwszy etap procesu przekładu – zrozumienie tekstu wyjściowego.
            <br />
            <br />
            Następnie studenci powinni napisać własne przekłady analizowanych
            fragmentów i zaprezentować je na forum grupy. Nauczyciel prowadzi
            dyskusję o odpowiednich, mniej odpowiednich czy też błędnych
            rozwiązaniach oraz zachęca studentów do wzajemnego komentowania
            przekładów. Zdolność do komentowania i oceniania przekładu studenci
            rozwijają także w ostatnim zadaniu, w którym mają do dyspozycji
            wydane na Słowacji przekłady analizowanych urywków.
            <br />
            <br />
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.mbs} id="cwiczenie-1">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie I
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę przeczytać poniższy tekst i odpowiedzieć na pytania pod nim.
          </Typography>
          <Typography variant="body2" align="justify">
            Trudno nie przyznać, że to właśnie frazeologizmy sprawiają tłumaczom
            szczególne trudności. <i>Eo ipso</i> (czyli z samej swej istoty) są
            to wyrażenia i zwroty typowe dla danego języka, a zatem – zazwyczaj
            – niemające odpowiedników w języku przekładu. Są to związki wyrazowe
            znacznie silniejsze niż kolokacje – znaczenie całości nie wynika ze
            znaczeń poszczególnych składników, innymi słowy – frazeologizm
            funkcjonuje jako całość. Mówiąc na przykład: „Tu leży pies
            pogrzebany”, nie mamy na myśli żadnego psiego nagrobka. Wyrażenia i
            zwroty frazeologiczne są najczęściej zbudowane na zasadzie
            obrazowych porównań czy metafor, odwołują się też do funkcjonujących
            w danej wspólnocie komunikacyjnej cytatów czy utrwalonych przez
            tradycję powiedzeń. Większość z nich nie ma w języku docelowym
            odpowiedników zbudowanych z analogicznych elementów, wręcz
            przeciwnie – idiomy stanowią ekwiwalenty jako całość. Oznacza to, że
            ekwiwalencja osiągnięta zostaje na poziomie całego wyrażenia,
            niezależnie od jego składowych części. Istotny jest sens danego
            obrazu, metafory czy porównania. Kryterium ekwiwalencji stanowi w
            tym przypadku możliwość zastosowania w analogicznej sytuacji
            (ekwiwalencja funkcjonalna).*
            <br />
            <br />* Na podstawie: Krzysztof Lipiński: <i>Vademecum tłumacza</i>.
            Kraków: Idea, 2000, s. 92 – 93.
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data1}
        answers={answers1}
        mb={true}
        uniqueItemTypeName="c1"
      />

      <Card className={classes.mbl} id="cwiczenie-2">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie II
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę przeczytać poniższy fragment powieści Olgi Tokarczuk{" "}
            <i>Prawiek i inne czasy</i> i zaznaczyć w nim frazeologizm.
          </Typography>
          <Typography variant="body2" align="justify">
            <br /> „Wiosną następnego roku tajniacy w prochowcach zabrali
            Izydora do Taszowa. Zaświecili mu lampą w oczy.
            <br />– Szyfr – powiedział jeden z nich.
            <br />– Co „szyfr”? – zapytał Izydor.
            <br />
            Drugi uderzył go w twarz otwartą dłonią. (…)
            <br />– Sprawdziliśmy wszystkimi dostępnymi metodami każde słowo,
            każdy centymetr kwadratowy listu i koperty. (…) Analizowaliśmy każdą
            literkę, każdy przecinek i kropkę…
            <br />– Nic nie znaleźliśmy – powiedział drugi, ten, który bił.
            <br />– Tam nie ma żadnego szyfru – powiedział cicho Izydor i
            chusteczką otarł krew spod nosa. (…)
            <br />– Słuchaj, mądralo. Wysłałeś dwadzieścia sześć listów do
            Wolnej Europy. W większości z nich były puste kartki. Igrałeś z
            ogniem. I doigrałeś się.
            <br />– Powiedz nam po prostu, jak szyfrowałeś. I koniec. Pójdziesz
            do domu.”*
            <br />
            <br />* Olga Tokarczuk: <i>Prawiek i inne czasy</i>. Warszawa:
            W.A.B., 1998, s. 223 – 224.
          </Typography>
        </CardContent>
      </Card>

      {/* <Card className={classes.mbs} id="cwiczenie-2">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie II
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            LEKSYKA: Połącz związki frazeologiczne, z których większość pojawiła
            się w powyższych tekstach z ich definicjami
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data1}
        answers={answers1}
        mb={true}
        uniqueItemTypeName="c1"
      /> */}

      <Card className={classes.mbs} id="cwiczenie-3">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie III
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę dopasować słowa z tekstu do ich definicji, a następnie
            poszukać ich odpowiedników w języku słowackim.
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data2}
        answers={answers2}
        mb={true}
        uniqueItemTypeName="c2"
      />

      <Card className={classes.mbl} id="cwiczenie-4">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie IV
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę przetłumaczyć podany wyżej fragment powieści na język
            słowacki.
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.mbl} id="cwiczenie-5">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie V
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę porównać swoje tłumaczenie z wydanym na Słowacji przekładem
            Karola Chmela.
          </Typography>
          <Typography variant="body2" align="left" gutterBottom>
            „Na jar nasledujúceho roku odviezli tajní v baloniakoch Izidora do
            Tašova. Svietili mu do očí stolnou lampou.
            <br />– Šifra, - povedal jeden z nich.
            <br />– Čo „šifra“? – spýtal sa Izidor.
            <br />
            Druhý mu dal otvorenou dlaňou zaucho. (...)
            <br />– Všetkými dostupnými metódami sme preskúmali každé slovo,
            každý štvorcový centimeter listu a obálky. (...) Analyzovali sme
            každé písmenko, každú čiarku a bodku...
            <br />– Nič sme nenašli, – dokončil druhý, ten, čo ho bil.
            <br />– Nie je tam žiadna šifra, - ozval sa ticho Izidor a utrel si
            vreckovkou krv spod nosa. (...)
            <br />– Počúvaj, ty fiškus. Poslal si dvadsaťšesť listov do
            Slobodnej Európy. Vo väčšine z nich boli prázdne strany. Hral si sa
            s ohňom. A teraz si sa dohral.
            <br />– Jednoducho nám povedz, akú šifru si používal. A bodka.
            Pustíme ťa domov.“*
            <br />
            <br />* Olga Tokarczuk: <i>Pravek a iné časy</i>. Preklad Karol
            Chmel. Bratislava: Premedia, 2015, s. 175 - 176.
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.mbl} id="cwiczenie-6">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie VI
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę skomentować słowacki przekład. Jakich ekwiwalentów użył
            tłumacz? Czy są one odpowiednie zarówno pod względem treści jak i
            stylu?
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.mbl} id="cwiczenie-7">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie VII
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę przeczytać poniższy fragment powieści Olgi Tokarczuk{" "}
            <i>Prawiek i inne czasy</i> i zaznaczyć w nim frazeologizmy.
          </Typography>
          <Typography variant="body2" align="left" gutterBottom>
            „Warszawa Uklei dojechała tak daleko, jak tylko się dało. Potem
            Ukleja musiał wysiąść i ostatnie metry szedł na piechotę. Potykał
            się na koleinach leśnej drogi i klął. W końcu stanął przed na wpół
            rozwaloną chałupą Kłoski i splunął ze złością. – Dobra kobieto,
            pozwólcie tutaj, mam do was interes! – zawołał. Kłoska wyszła przed
            dom i spojrzała prosto w zaczerwienione oczy Uklei. – Nie dam ci
            jej. Na chwilę stracił pewność siebie, ale zaraz wziął się w garść.
            – Ona już jest moja – powiedział spokojnie. – Tylko uparła się, że
            musisz ją pobłogosławić. Mam cię poprosić o jej rękę. – Nie dam ci
            jej. Ukleja odwrócił się w stronę samochodu i krzyknął: – Ruta! (…)
            Ruta zatrzymała się przy Uklei i niepewnie wsunęła rękę pod jego
            ramię. Ten gest do końca ośmielił Ukleję.”*
            <br />
            <br />* Olga Tokarczuk: <i>Prawiek i inne czasy</i>. Warszawa:
            W.A.B., 1998, s. 176 – 177.
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.mbs} id="cwiczenie-8">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie VIII
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Jakie znaczenie mają podane niżej frazeologizmy? Proszę połączyć
            zdania z wyrazami po prawej stronie.
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data3}
        answers={answers3}
        mb={true}
        uniqueItemTypeName="c3"
      />

      <Card className={classes.mbs} id="cwiczenie-9">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie IX
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę dopasować słowa z tekstu do ich definicji, a następnie
            poszukać ich odpowiedników w języku słowackim.
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data4}
        answers={answers4}
        mb={true}
        uniqueItemTypeName="c4"
      />

      <Card className={classes.mbl} id="cwiczenie-10">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie X
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę przetłumaczyć podany wyżej fragment powieści na język
            słowacki.
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.mbl} id="cwiczenie-11">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie XI
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę porównać swoje tłumaczenie z wydanym na Słowacji przekładem
            Karola Chmela.
          </Typography>
          <Typography variant="body2" align="left" gutterBottom>
            Uklejova Varšava došla tak ďaleko, ako sa len dalo. Potom musel
            Ukleja vystúpiť z auta a posledné metre prešiel pešo. Potkýnal sa na
            vymletých koľajách lesnej cesty a nadával. Nakoniec zastal pred
            napoly zvalenou Kláskinou chalupou a zlostne si odpľul.
            <br />– Dobrá žena, poďte sem, mám pre vás ponuku, - zavolal.
            <br />
            Kláska vyšla pred dom a pozrela sa rovno do Uklejových začervenaných
            očí.
            <br />– Nedám ti ju.
            <br />
            Na chvíľu zneistel, no vzápätí akoby sa spamätal.
            <br />– Ona už je moja, - vyhlásil pokojne. – Len sa zaťala, že jej
            musíš dať požehnanie. Mám ťa požiadať o jej ruku.
            <br />– Nedám ti ju.
            <br />
            Ukleja sa otočil k autu a zakričal:
            <br />– Ruta! (...)
            <br />
            Ruta sa zastavila pri Uklejovi a neisto zasunula ruku pod jeho
            pazuchu. To gesto Ukleju definitívne posmelilo. *
            <br />
            <br />* Olga Tokarczuk: <i>Prawiek i inne czasy</i>. Warszawa:
            W.A.B., 1998, s. 138 – 139.
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.mbs} id="cwiczenie-11">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie XII
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę skomentować słowacki przekład. Jakich ekwiwalentów użył
            tłumacz? Czy są one odpowiednie zarówno pod względem treści jak i
            stylu?
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default Exercise2;
