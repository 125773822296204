import React, { ReactElement, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useMediaQuery } from "@material-ui/core";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props: {
  row: { jsx: ReactElement; string: string }[];
  headers: { jsx: ReactElement; string: string }[];
  lang1: string | ReactElement | undefined;
  lang2: string | ReactElement | undefined;
}) {
  const { row, headers, lang1, lang2 } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row[0].jsx}
        </TableCell>
        <TableCell align="right">{row[1].jsx}</TableCell>
        <TableCell align="right">{row[5].jsx}</TableCell>
        <TableCell align="right">{row[6].jsx}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit={false}>
            <Box margin={1}>
              <Table size="small" aria-label="details">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {lang1} {headers[2].string}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{row[2].jsx}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table size="small" aria-label="details">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {lang1} {headers[3].string}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{row[3].jsx}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {headers[4].string && (
                <Table size="small" aria-label="details">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {lang1} {headers[4].string}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Link
                          href={row[4].string}
                          target="_blank"
                          color="inherit"
                        >
                          Kliknij, aby przejść do zewnętrznej strony
                        </Link>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
              <div style={{ marginBottom: "20px" }} />

              <Table size="small" aria-label="details">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {lang2} {headers[7].string}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{row[7].jsx}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table size="small" aria-label="details">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {lang2} {headers[8].string}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{row[8].jsx}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {headers[9] && headers[9].string && (
                <Table size="small" aria-label="details">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {lang2} {headers[9].string}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Link
                          href={row[9].string}
                          target="_blank"
                          color="inherit"
                        >
                          Kliknij, aby przejść do zewnętrznej strony
                        </Link>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const firstUpperCase = (headers: { jsx: ReactElement; string: string }[]) =>
  headers?.map((field) => ({
    ...field,
    string: field.string.charAt(0).toUpperCase() + field.string.slice(1),
  }));

export default function VocabularyTable(props: {
  rows: { jsx: ReactElement; string: string }[][];
  lang1: string | ReactElement | undefined;
  lang2: string | ReactElement | undefined;
}) {
  const { rows, lang1, lang2 } = props;
  let headers = rows[0] || [{ jsx: <></>, string: "" }];
  const data = rows.slice(1, rows.length + 1);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    setPage(0);
  }, [props.rows.length]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // first letter uppercase
  if (headers) headers = firstUpperCase(headers);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Paper style={{ paddingBottom: 10 }}>
      <TableContainer>
        <Table
          aria-label="collapsible table"
          size={isSmall ? "small" : "medium"}
        >
          <caption>
            Lista związków frazeologicznych, wraz ze schematem składniowym,
            definicją oraz przykładem w języku polskim i słowackim.
          </caption>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                {lang1} {headers[0].string}
              </TableCell>
              <TableCell align="right">
                {lang1} {headers[1].string}
              </TableCell>
              <TableCell align="right">
                {lang2} {headers[5].string}
              </TableCell>
              <TableCell align="right">
                {lang2} {headers[6].string}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <Row
                  key={row[0].string}
                  row={row}
                  headers={headers}
                  lang1={lang1}
                  lang2={lang2}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={{ margin: 10 }}
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count }) =>
          "" + from + "-" + to + " z " + count
        }
        labelRowsPerPage="Wpisów na stronie:"
        nextIconButtonText="Następna strona"
        backIconButtonText="Poprzednia strona"
      />
    </Paper>
  );
}
