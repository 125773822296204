import React, { useCallback, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import MessageIcon from "@material-ui/icons/Message";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import { HashLink } from "react-router-hash-link";

import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useHistory,
} from "react-router-dom";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
  fade,
} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { Grid, useMediaQuery } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import SendIcon from "@material-ui/icons/Send";
import Button from "@material-ui/core/Button";

const scrollWithOffset = (el: any) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -80;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

const useForceUpdate = () => {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick((tick) => tick + 1);
  }, []);
  return update;
};

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("xl")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      background: theme.palette.type === "light" ? "#fff" : "#000",
      color: theme.palette.type === "light" ? "#333" : "#fff",
      [theme.breakpoints.up("xl")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    footer: {
      boxShadow: `0px -2px 4px -1px rgba(0,0,0,0.2),0px -4px 5px 0px rgba(0,0,0,0.14),0px -1px 10px 0px rgba(0,0,0,0.12)`,
      background: theme.palette.type === "light" ? "white" : "black",
      overflow: "hidden",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("xl")]: {
        display: "none",
      },
    },
    title: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    logo: {
      marginRight: theme.spacing(4),
    },
    nawaLogo: {
      flexGrow: 1,
    },
    logoImg: {
      display: "block",
      maxWidth: 200,
      // maxHeight: 100,
    },
    mainLogo: {
      display: "block",
      maxWidth: 160,
      maxHeight: 60,
      margin: 10,
      marginLeft: 0,
    },

    search: {
      position: "relative",
      marginRight: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(
        theme.palette.type === "light"
          ? theme.palette.common.black
          : theme.palette.common.white,
        0.15
      ),
      "&:hover": {
        backgroundColor: fade(
          theme.palette.type === "light"
            ? theme.palette.common.black
            : theme.palette.common.white,
          0.25
        ),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 10,
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },

    sensejIcon: {
      position: "relative",
      marginRight: theme.spacing(2),
      "& > a": {
        marginLeft: 0,
        fontWeight: 300,
        color: theme.palette.type === "light" ? "black" : "white",
        textDecoration: "none",
        opacity: 0.4,
      },
      "& > a:hover": {
        opacity: 0.8,
      },
      // [theme.breakpoints.down("sm")]: {
      //   display: "none",
      // },
    },
    tt: {
      fontSize: 14,
      background: "rgba(60, 60, 60, 0.9)",
      border: "1px solid rgba(255, 255, 255, 0.2)",
      fontWeight: 400,
      padding: 10,
    },
    avatar: {
      marginRight: 3,
    },
    button: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginBottom: 0,
    },
  })
);

const changeStyleMode = (
  currentMode: "light" | "dark",
  setThemeType: React.Dispatch<
    React.SetStateAction<"dark" | "light" | undefined>
  >
) => {
  if (currentMode === "light") setThemeType("dark");
  else setThemeType("light");
};

interface Props {
  children: JSX.Element | JSX.Element[];
  setThemeType: React.Dispatch<
    React.SetStateAction<"dark" | "light" | undefined>
  >;
  urlPath: string | undefined;
}

export default function ResponsiveDrawer(props: Props) {
  const { children, setThemeType } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let history = useHistory();
  const forceUpdate = useForceUpdate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  interface ListItemLinkProps {
    icon?: React.ReactElement;
    primary: string;
    to: string;
  }

  function ListItemLink(props: ListItemLinkProps) {
    const { icon, primary, to } = props;

    const renderLink = React.useMemo(
      () =>
        React.forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
          <RouterLink to={to} ref={ref} {...itemProps} />
        )),
      [to]
    );

    return (
      <li>
        <ListItem button component={renderLink}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />
        </ListItem>
      </li>
    );
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItemLink to="/" primary="Strona główna" icon={<HomeIcon />} />
        <ListItemLink to="/zespol" primary="Zespół" icon={<PeopleIcon />} />
        <ListItemLink
          to="/wyklady-warsztaty"
          primary="Wykłady i warsztaty"
          icon={<MessageIcon />}
        />
        <ListItemLink
          to="/slownik"
          primary="Słowniczek frazeologiczny polsko-słowacki"
          icon={<MenuBookIcon />}
        />
        <ListItemLink
          to="/slownik-slowacki"
          primary="Słowniczek frazeologiczny słowacko-polski"
          icon={<MenuBookIcon />}
        />
        <ListItemLink
          to="/cwiczenia"
          primary="Ćwiczenia"
          icon={<BorderColorIcon />}
        />
        <ListItemLink
          to="/slownik-papierowy"
          primary="Słownik frazeologiczny z ćwiczeniami"
          icon={<MenuBookIcon />}
        />
        <ListItemLink
          to="/materialy-studentow"
          primary="Materiały studentów"
          icon={<EmojiEmotionsIcon />}
        />

        {/* {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem button key={text} href="/" component={"a"}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <Link to="/zespol">
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          </Link>
        ))} */}
      </List>
    </div>
  );

  const onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      history.push({
        pathname: "/slownik",
        search: `s=${event.target.value}`,
        state: `s=${event.target.value}`,
      });
      forceUpdate();
    }
  };

  const removeGutters = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <div className={classes.mainContainer}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>

            <Typography className={classes.title} variant="h6" noWrap>
              <img
                className={classes.mainLogo}
                alt="LOGO główne"
                src={
                  process.env.PUBLIC_URL + theme.palette.type === "light"
                    ? process.env.PUBLIC_URL + "/images/main_logo.svg"
                    : process.env.PUBLIC_URL + "/images/main_logo_white.svg"
                }
              />
            </Typography>

            {!history.location.pathname.includes("slownik") ||
            history.location.pathname.includes("slownik-papierowy") ? (
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Szukaj..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  onKeyUp={(e) => onKeyUp(e)}
                  inputProps={{ "aria-label": "search" }}
                />
              </div>
            ) : (
              <></>
            )}

            <IconButton
              color="inherit"
              aria-label="style mode switch"
              onClick={(v) => changeStyleMode(theme.palette.type, setThemeType)}
            >
              {theme.palette.type === "light" ? (
                <Brightness4Icon />
              ) : (
                <Brightness7Icon />
              )}
            </IconButton>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="main menu">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden xlUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden lgDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>

        <Container disableGutters={removeGutters}>
          <Box my={2}>
            <main className={classes.content}>
              <div className={classes.toolbar} />
              {children}
            </main>
          </Box>
        </Container>
      </div>

      <AppBar position="relative" className={classes.footer}>
        <Toolbar>
          <Grid container spacing={2} style={{ padding: 30 }}>
            <Grid item xs={12} md={4}>
              <Grid
                container
                style={{
                  alignContent: "flex-start",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <Grid
                  item
                  alignContent="center"
                  style={{ margin: "5px 5px", padding: "0 50px" }}
                >
                  <Typography variant="h6">
                    <img
                      className={classes.logoImg}
                      alt="LOGO główne"
                      src={
                        process.env.PUBLIC_URL +
                        (theme.palette.type === "light"
                          ? "/images/main_logo.svg"
                          : "/images/main_logo_white.svg")
                      }
                    />
                  </Typography>
                </Grid>
                <Grid style={{ margin: "25px 5px", padding: "0 50px" }}>
                  <Typography variant="h6">
                    <img
                      className={classes.logoImg}
                      style={{ maxWidth: 300 }}
                      alt="LOGO NAWA"
                      src={
                        process.env.PUBLIC_URL +
                        (theme.palette.type === "light"
                          ? "/images/nawa_logo.svg"
                          : "/images/nawa_logo_white.svg")
                      }
                    />
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="h6">
                    <img
                      className={classes.logoImg}
                      style={{ maxWidth: 300 }}
                      alt="LOGO UK"
                      src={
                        process.env.PUBLIC_URL +
                        (theme.palette.type === "light"
                          ? "/images/UK_dark.png"
                          : "/images/UK_light.png")
                      }
                    />
                    {/* <img
                      className={classes.logoImg}
                      style={{ maxWidth: "130px", maxHeight: "160px" }}
                      alt="LOGO"
                      src={process.env.PUBLIC_URL + "/images/UK.svg"}
                    /> */}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={8}>
              <ScopedCssBaseline style={{ background: "none" }}>
                <Typography variant="body1" align="left">
                  <b>Współczesna kultura polska we frazeologii</b>
                </Typography>
                <Typography variant="body2" align="justify" gutterBottom>
                  Projekt uzyskał finansowanie w konkursie{" "}
                  <i>Promocja Języka Polskiego</i> Narodowej Agencji Wymiany
                  Akademickiej i był realizowany przez badaczki i badaczy z
                  Wydziału Filologii Polskiej i Klasycznej Uniwersytetu im.
                  Adama Mickiewicza w Poznaniu oraz z Katedry Filologii
                  Słowiańskich Uniwersytetu Komeńskiego w Bratysławie.
                </Typography>

                <Typography variant="body1" align="left" gutterBottom>
                  <b>Zespół</b>
                </Typography>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="left"
                  alignContent="flex-start"
                  alignItems="flex-start"
                >
                  <Tooltip
                    enterTouchDelay={1}
                    leaveTouchDelay={5000}
                    TransitionComponent={Zoom}
                    title="dr hab. Gabriela Dziamska-Lenart, prof. UAM"
                    placement="top"
                    classes={{ tooltip: classes.tt }}
                  >
                    <HashLink
                      to="/zespol#dziamska-lenart"
                      smooth
                      scroll={(el) => scrollWithOffset(el)}
                      className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
                    >
                      <Avatar
                        alt="dr hab. Gabriela Dziamska-Lenart, prof. UAM"
                        src={process.env.PUBLIC_URL + "/images/team/A.jpg"}
                        className={classes.avatar}
                      />
                    </HashLink>
                  </Tooltip>

                  <Tooltip
                    enterTouchDelay={1}
                    leaveTouchDelay={5000}
                    TransitionComponent={Zoom}
                    title="dr Piotr Fliciński"
                    placement="top"
                    classes={{ tooltip: classes.tt }}
                  >
                    <HashLink
                      to="/zespol#flicinski"
                      smooth
                      scroll={(el) => scrollWithOffset(el)}
                      className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
                    >
                      <Avatar
                        alt="dr Piotr Fliciński"
                        src={process.env.PUBLIC_URL + "/images/team/B1.jpg"}
                        className={classes.avatar}
                      />
                    </HashLink>
                  </Tooltip>

                  <Tooltip
                    enterTouchDelay={1}
                    leaveTouchDelay={5000}
                    TransitionComponent={Zoom}
                    title="dr Zuzana Obertová"
                    placement="top"
                    classes={{ tooltip: classes.tt }}
                  >
                    <HashLink
                      to="/zespol#obertova"
                      smooth
                      scroll={(el) => scrollWithOffset(el)}
                      className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
                    >
                      <Avatar
                        alt="dr Zuzana Obertová"
                        src={process.env.PUBLIC_URL + "/images/team/D.jpg"}
                        className={classes.avatar}
                      />
                    </HashLink>
                  </Tooltip>

                  <Tooltip
                    enterTouchDelay={1}
                    leaveTouchDelay={5000}
                    TransitionComponent={Zoom}
                    title="dr Ewelina Woźniak-Wrzesińska"
                    placement="top"
                    classes={{ tooltip: classes.tt }}
                  >
                    <HashLink
                      to="/zespol#wozniak-wrzesinska"
                      smooth
                      scroll={(el) => scrollWithOffset(el)}
                      className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
                    >
                      <Avatar
                        alt="dr Ewelina Woźniak-Wrzesińska"
                        src={process.env.PUBLIC_URL + "/images/team/F.jpg"}
                        className={classes.avatar}
                      />
                    </HashLink>
                  </Tooltip>

                  <Tooltip
                    enterTouchDelay={1}
                    leaveTouchDelay={5000}
                    TransitionComponent={Zoom}
                    title="dr Magdalena Zakrzewska-Verdugo"
                    placement="top"
                    classes={{ tooltip: classes.tt }}
                  >
                    <HashLink
                      to="/zespol#zakrzewska-verdugo"
                      smooth
                      scroll={(el) => scrollWithOffset(el)}
                      className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
                    >
                      <Avatar
                        alt="dr Magdalena Zakrzewska-Verdugo"
                        src={process.env.PUBLIC_URL + "/images/team/E.jpg"}
                        className={classes.avatar}
                      />
                    </HashLink>
                  </Tooltip>

                  <Tooltip
                    enterTouchDelay={1}
                    leaveTouchDelay={5000}
                    TransitionComponent={Zoom}
                    title="dr Jan Zgrzywa"
                    placement="top"
                    classes={{ tooltip: classes.tt }}
                  >
                    <HashLink
                      to="/zespol#zgrzywa"
                      smooth
                      scroll={(el) => scrollWithOffset(el)}
                      className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
                    >
                      <Avatar
                        alt="dr Jan Zgrzywa"
                        src={process.env.PUBLIC_URL + "/images/team/C.jpg"}
                        className={classes.avatar}
                      />
                    </HashLink>
                  </Tooltip>
                </Box>
                <Typography gutterBottom> </Typography>
                <Typography variant="body1" align="left" gutterBottom>
                  <b>Kontakt</b>
                  <br />
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    startIcon={isSmall ? <SendIcon /> : undefined}
                    href="mailto:ewewoz@amu.edu.pl"
                    size="small"
                  >
                    ewewoz@amu.edu.pl
                  </Button>
                  <br />
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    startIcon={isSmall ? <SendIcon /> : undefined}
                    href="mailto:magdalena.zakrzewska@uniba.sk"
                    size="small"
                  >
                    magdalena.zakrzewska@uniba.sk
                  </Button>
                </Typography>
              </ScopedCssBaseline>
            </Grid>
          </Grid>
          {/* 
          <Typography variant="h6" noWrap className={classes.logo}>
            <img
              className={classes.logoImg}
              alt="LOGO"
              src={
                process.env.PUBLIC_URL +
                (theme.palette.type === "light"
                  ? "/images/main_logo.svg"
                  : "/images/main_logo_white.svg")
              }
            />
          </Typography>
          <Typography variant="h6" noWrap className={classes.nawaLogo}>
            <img
              className={classes.logoImg}
              alt="LOGO"
              src={
                process.env.PUBLIC_URL +
                (theme.palette.type === "light"
                  ? "/images/nawa_logo.svg"
                  : "/images/nawa_logo_white.svg")
              }
            />
          </Typography> */}
        </Toolbar>
      </AppBar>

      <AppBar
        position="relative"
        style={{
          background:
            theme.palette.type === "light" ? "rgb(232, 232, 232)" : "#303030",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            className={classes.nawaLogo}
          ></Typography>
          <Typography variant="caption" noWrap className={classes.sensejIcon}>
            <a href="https://sensej.pl">
              made with <span>&hearts;</span> by
              <img
                className={classes.logoImg}
                alt="LOGO sensej.pl"
                src={
                  process.env.PUBLIC_URL +
                  (theme.palette.type === "light"
                    ? "/images/sensej_dark.png"
                    : "/images/sensej_light.png")
                }
                width={85}
              />
            </a>
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
}
