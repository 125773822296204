import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: "100%",
      maxWidth: 1170,
      maxHeight: "100%",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 4,
      textAlign: "center",
      overflowY: "auto",
      [theme.breakpoints.down("xs")]: {
        padding: "none",
      },
    },
    modalHeader: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    close: {
      position: "absolute",
      fontSize: 36,
      right: 36,
      transform: "scale(1.5)",
      "&:hover": {
        color: "#AAA",
        cursor: "pointer",
      },
    },
    logoImg: {
      margin: "auto",
      display: "block",
      maxWidth: "300px",
      maxHeight: "100px",
    },
    wrapIcon: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "& > svg": {
        marginRight: 10,
      },
      [theme.breakpoints.down("sm")]: {
        "& > svg": {
          display: "none",
        },
      },
    },
    button: {
      margin: theme.spacing(1),
      marginLeft: 0,
    },
    lectureImg: {
      margin: "auto",
      display: "block",
      maxWidth: "60%",
      borderRadius: 8,
      border: "1px solid black",
      borderColor: "rgba(0,0,0,0.2)",
      [theme.breakpoints.down("md")]: {
        maxWidth: "75%",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "90%",
      },
    },
    testimonial: {
      fontSize: "1.2rem",
      fontStyle: "italic",
      fontWeight: 300,
      padding: "30px 70px 20px",
      position: "relative",
      "& > div": {
        display: "flex",
        justifyContent: "flex-end",
        fontStyle: "normal",
        fontWeight: 400,
        "& > *": {
          display: "flex",
          alignItems: "center",
          margin: theme.spacing(1),
        },
      },
      "& > .MuiSvgIcon-root": {
        position: "absolute",
        fontSize: 120,
        opacity: 0.1,
        bottom: -22,
        left: 0,
        transform: "rotate(7deg)",
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
        padding: "30px 30px 20px",
      },
    },
    container: {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        padding: 40,
      },
    },
  })
);
