import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import { Accordion, AccordionSummary, useStyles, TT } from "./helpers";

export default function Team() {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Avatar
        alt="dr Magdalena Zakrzewska-Verdugo"
        src={process.env.PUBLIC_URL + "/images/team/E.jpg"}
        className={classes.avatar}
      />
      <CardContent>
        <Typography variant="h6" component="h2">
          dr Magdalena Zakrzewska-Verdugo
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Katedra Filologii Słowiańskich
          <br />
          Uniwersytet Komeńskiego w Bratysławie
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          href="mailto:magdalena.zakrzewska@uniba.sk"
        >
          E-mail
        </Button>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          href="https://orcid.org/0000-0002-2568-087X"
          target="_blank"
        >
          ORCID
        </Button>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          href="https://fphil.uniba.sk/katedry-a-odborne-pracoviska/ksf/clenovia-katedry/magdalena-zakrzewska-verdugo/"
          target="_blank"
        >
          WWW
        </Button>
      </CardActions>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Więcej</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Card className={classes.photoCard}>
                <img
                  className={classes.photo}
                  alt="zdjęcie uczestnika"
                  src={process.env.PUBLIC_URL + "/images/team/E1.jpg"}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="justify"
                className={classes.personDesc}
              >
                <TT
                  title={
                    <>
                      <i>ktoś</i> urodził się pod szczęśliwą gwiazdą: ‘ktoś ma
                      szczęście w życiu, odnosi sukcesy, ma powodzenie’
                    </>
                  }
                  label="Urodziłam się pod szczęśliwą gwiazdą"
                />{" "}
                w Bolesławcu… bo akurat zamknęli szpital w moim rodzinnym
                mieście.{" "}
                <TT
                  title={
                    <>
                      <i>ktoś</i> rośnie, urósł jak na drożdżach: ‘o dziecku:
                      szybko rośnie’
                    </>
                  }
                  label="Rosłam jak na drożdżach"
                />{" "}
                w stolicy Dolnego Śląska, gdzie w końcu ukończyłam polonistykę o
                specjalności nauczanie polskiego jako obcego na Uniwersytecie
                Wrocławskim i{" "}
                <TT
                  title={
                    <>
                      <i>ktoś</i> wyfrunął w świat: ‘ktoś opuścił dom rodzinny,
                      rozpoczął samodzielne życie’
                    </>
                  }
                  label="wyfrunęłam w świat!"
                />{" "}
                Hiszpańskiego męża znalazłam w Finlandii, poślubiłam go w
                Polsce, doktorat ukończyłam w Czechach, zaś od 2017 r. uczę
                języka polskiego na Uniwersytecie Komeńskiego w Bratysławie na
                Słowacji, gdzie też przyszedł na świat mój syn. W swojej pracy{" "}
                <TT
                  title={
                    <>
                      <i>ktoś</i> czuje się jak ryba w wodzie: ‘ktoś jest
                      specjalistą w jakiejś dziedzinie i tym się zajmuje, czuje
                      się dzięki temu bardzo swobodnie’
                    </>
                  }
                  label="czuję się jak ryba w wodzie"
                />
                .{" "}
                <TT
                  title={
                    <>
                      <i>ktoś</i> daje się, dał się pokroić za coś: ‘ktoś broni
                      czegoś za wszelką cenę’
                    </>
                  }
                  label="Dałabym się pokroić"
                />{" "}
                za swoją rodzinę i psa Kluskę. Uwielbiam czytać, uprawiać jogę i{" "}
                <TT
                  title={
                    <>
                      (<i>ktoś</i> odpoczywa, przebywa, znajduje się, jest itp.){" "}
                      na łonie natury / przyrody: ‘ktoś spędza czas w otoczeniu
                      przyrody, na świeżym powietrzu’
                    </>
                  }
                  label="spędzać czas na łonie natury"
                />
                .
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
