import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MessageIcon from "@material-ui/icons/Message";
import SubjectIcon from "@material-ui/icons/Subject";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import InfoIcon from "@material-ui/icons/Info";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { useStyles } from "./helpers";

function F() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h6" noWrap>
        <img
          alt="Zdjęcie z wykładu"
          className={classes.lectureImg}
          src={process.env.PUBLIC_URL + "/images/lectures/6_zdj.jpg"}
        />
      </Typography>
      <br />
      <Typography paragraph align="left">
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <SubjectIcon />{" "}
          <b>
            <i>Konsultacje dla studentów</i>
          </b>
        </Typography>
        dr Zuzana Obertová, dr Magdalena Zakrzewska-Verdugo, dr Ewelina
        Woźniak-Wrzesińska, dr Jan Zgrzywa
        <Typography variant="body2">
          16.04.2021, Uniwersytet Komeńskiego, konsultacje
        </Typography>
        <br />
        <Typography variant="h6" component="h2" className={classes.wrapIcon}>
          <InfoIcon /> Opis
        </Typography>
        <Typography variant="body1" align="justify">
          Studenci omówili wątpliwości dotyczące stworzonych przez siebie haseł
          w języku słowackim. Zespół badaczy-dydaktyków w formie bezpośrednich,
          prowadzonych ad hoc, dyskusji poddał weryfikacji zaprezentowany
          materiał, analizując i oceniając wraz ze studentem poszczególne
          elementy artykułu hasłowego, zwracając szczególną uwagę trafność
          definicji.
        </Typography>
      </Typography>
    </div>
  );
}

export default F;
