import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { HashLink } from "react-router-hash-link";

import Exercise from "./Exercise";
import { Chip, Link } from "@material-ui/core";
import { useEffect } from "react";

const scrollWithOffset = (el: any) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -80;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoImg: {
      margin: "auto",
      display: "block",
      maxWidth: "300px",
      maxHeight: "100px",
    },
    button: {
      margin: theme.spacing(1),
    },
    lectureImg: {
      margin: "auto",
      marginBottom: 2,
      display: "block",
      maxWidth: "60%",
      borderRadius: 8,
      border: "1px solid black",
      borderColor: "rgba(0,0,0,0.2)",
    },
    wrapIcon: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "& > svg": {
        marginRight: 10,
      },
    },
    mbl: {
      marginBottom: 50,
    },
    mbs: {
      marginBottom: 15,
    },
    cardContent: {
      padding: 40,
      paddingBottom: 20,
    },
  })
);

const data1 = [
  {
    a: "1) Jeśli chcesz …………………………, musisz być eko [...]",
    b: "drapaczu chmur",
  },
  {
    a: "2) [...] nie …………………………… idee filozofii less waste lub zero waste [...]",
    b: "dziurę zabitą dechami",
  },
  {
    a: "3) Jeśli wolisz miasto i uważasz wieś za ……………………………, to mamy też kolejną propozycję.",
    b: "iść z duchem czasu",
  },
  {
    a: "4) [...] nawet jeśli mieszkasz w ………………………………….",
    b: "jesteś głuchy i ślepy na",
  },
  {
    a: "5) Taka forma wypoczynku może być atrakcyjna szczególnie dla tych, którzy na co dzień ………………………………………….",
    b: "lać jak z cebra",
  },
  {
    a: "6) No i nieważne, jaka jest pogoda: na zewnątrz może ………………………………………… – nic nie szkodzi.",
    b: "są gośćmi we własnym domu",
  },
];

const answers1 = [
  { a: 0, b: 2 },
  { a: 1, b: 3 },
  { a: 2, b: 1 },
  { a: 3, b: 0 },
  { a: 4, b: 5 },
  { a: 5, b: 4 },
];

const data2 = [
  {
    a: "1) [...] na pewno łamiesz sobie …………………………………………………… nad tym [...]",
    b: "apetyt",
  },
  {
    a: "2) Na zdrowy …………………………………… podstawowym ekogadżetem nie tylko w podróży powinna być butelka na wodę z filtrem.",
    b: "dolarów",
  },
  {
    a: "3) [...] przecież w podróży często mamy wilczy …………………………………………………………………….",
    b: "głowę",
  },
  {
    a: "4) I już możesz jeść, aż się ………………………………………………… trzęsą!",
    b: "łonie",
  },
  {
    a: "5) Jeśli są biodegradowalne, możesz się nimi umyć nawet podczas kąpieli na ……………………………………………………… natury.",
    b: "rozum",
  },
  {
    a: "6) Jeśli nie musisz wyglądać w podróży jak milion ………………………………………………………, zainwestuj w ubrania z recyklingu.",
    b: "uszy",
  },
];

const answers2 = [
  { a: 0, b: 2 },
  { a: 1, b: 4 },
  { a: 2, b: 0 },
  { a: 3, b: 5 },
  { a: 4, b: 3 },
  { a: 5, b: 1 },
];

const data3 = [
  { a: "drapacz chmur", b: "ktoś jest nowoczesny, zna najnowsze trendy" },
  { a: "dziura zabita dechami", b: "ktoś rzadko przebywa w swoim domu" },
  { a: "ktoś je, aż się uszy trzęsą", b: "ktoś jest bardzo dobrze ubrany" },
  {
    a: "ktoś idzie z duchem czasu",
    b: "ktoś nie chce o czymś niczego wiedzieć; komuś jest wszystko jedno",
  },
  { a: "ktoś jest głuchy i ślepy na coś", b: "w otoczeniu przyrody " },
  { a: "ktoś wygląda jak milion dolarów", b: "ktoś je z apetytem" },
  { a: "leje jak z cebra", b: "pada silny deszcz" },
  { a: "ktoś łamie sobie nad czymś głowę", b: "ktoś jest bardzo głodny" },
  { a: "ktoś ma wilczy apetyt", b: "logicznie, wszystko na coś wskazuje" },
  { a: "na łonie natury", b: "wieś; miejsce, w którym nic się nie dzieje" },
  { a: "na zdrowy rozum", b: "ktoś długo rozmyśla o czymś" },
  {
    a: "ktoś jest gościem we własnym domu",
    b: "wieżowiec, blok, bardzo wysoki budynek",
  },
];

const answers3 = [
  { a: 0, b: 11 },
  { a: 1, b: 9 },
  { a: 2, b: 5 },
  { a: 3, b: 0 },
  { a: 4, b: 3 },
  { a: 5, b: 2 },
  { a: 6, b: 6 },
  { a: 7, b: 10 },
  { a: 8, b: 7 },
  { a: 9, b: 4 },
  { a: 10, b: 8 },
  { a: 11, b: 1 },
];

const data4 = [
  {
    a: "1) Powinieneś/powinnaś …………………………………… kilku podstawowych reguł",
    b: "dokarmiać",
  },
  {
    a: "2) Nie …………………………… śmieci na szlakach turystycznych czy plażach",
    b: "kupować",
  },
  {
    a: "3) Na pieszych wędrówkach zawsze ……………………… wyznaczonych szlaków",
    b: "mieć",
  },
  { a: "4) ……………………………………… przy sobie ekogadżety!", b: "przestrzegać" },
  {
    a: "5) Zamiast komercyjnych pamiątek, ………………………… produkty lokalne",
    b: "trzymać się",
  },
  { a: "6) Nie ………………………………… dzikich zwierząt.", b: "wybierać" },
  {
    a: "7)  ………………………………… miejsca oddalone od wielkich skupisk miejskich.",
    b: "zostawiać",
  },
];

const answers4 = [
  { a: 0, b: 3 },
  { a: 1, b: 6 },
  { a: 2, b: 4 },
  { a: 3, b: 2 },
  { a: 4, b: 1 },
  { a: 5, b: 0 },
  { a: 6, b: 5 },
];

function Exercise2() {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Interaktywne ćwiczenia - Wakacyjne podróże";
  }, []);

  return (
    <>
      <Typography variant="h5" component="h1">
        Wakacyjne podróże
      </Typography>
      <Typography variant="subtitle2">ćwiczenia na poziomie A2/B1</Typography>
      <Typography variant="body2" gutterBottom style={{ marginBottom: 20 }}>
        dr Magdalena Zakrzewska-Verdugo
      </Typography>

      <Card className={classes.mbl}>
        <CardContent className={classes.cardContent}>
          <Typography variant="body2" align="justify">
            Niniejsza lekcja jest przeznaczona do wykorzystania ze studentami na
            poziomie zaawansowania A2/B1. Jest ona zaplanowana jako nawiązanie
            do lekcji pod tym samym tytułem znajdującej się w publikacji „Polski
            na A. Część II. Podręcznik języka polskiego dla Słowaków“ (2014)
            autorstwa Moniki Krzempek. Lekcja ta tematycznie związana jest z
            turystyką, a także rozszerza zasób słownictwa studentów przede
            wszystkim o aspekt frazeologiczny. Cała lekcja oparta jest na jednym
            adaptowanym tekście autentycznym dotyczącym ekoturystyki (odnośnik
            znajduje się pod lekcją).
            <br />
            <br />
            <b>
              <HashLink
                to="#cwiczenie-1"
                smooth
                scroll={(el) => scrollWithOffset(el)}
                className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
              >
                Ćwiczenie I
              </HashLink>
            </b>{" "}
            zawiera wprowadzenie do tematu ekowakacji, a także nawiązuje do
            tekstu dotyczącego agroturystyki, który pojawił się w oryginalnej
            lekcji w podręczniku. Zadaniem studentów jest uzupełnić tekst
            podanymi w ramce frazeologizmami. Odpowiedzi do tej części zadania
            znajdują się w kluczu. Po lekturze i wyjaśnieniu nowych wyrażeń
            można porozmawiać ze studentami, jaka forma ekourlopu byłaby dla
            nich bardziej atrakcyjna: pobyt w gospodarstwie agroturystycznym czy
            staycation. Swoją odpowiedź powinni uzasadnić.
            <br />
            <br />
            <b>
              <HashLink
                to="#cwiczenie-2"
                smooth
                scroll={(el) => scrollWithOffset(el)}
                className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
              >
                Ćwiczenie II
              </HashLink>
            </b>{" "}
            oparte jest na dalszej części tego samego tekstu internetowego.
            Tutaj studenci muszą uzupełnić frazeologizmy odpowiednimi
            rzeczownikami. Do tej części ćwiczenia odpowiedzi znajdują się w
            kluczu. Następnie będą poproszeni o to, żeby wybrali sobie jeden
            dodatkowy ekogadżet i spróbowali wyjaśnić, jak można go wykorzystać
            w podróży. Można poprosić uczestników również o zaproponowanie
            dodatkowych ekologicznych gadżetów.
            <br />
            <br />
            <b>
              <HashLink
                to="#cwiczenie-3"
                smooth
                scroll={(el) => scrollWithOffset(el)}
                className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
              >
                Ćwiczenie III
              </HashLink>
            </b>{" "}
            stanowi podsumowanie frazeologii zawartej w ćwiczeniu I i II.
            Studenci muszą dopasować definicje do frazeologizmów, które znalazły
            się w poprzednich ćwiczeniach. Jeśli mają problem ze zrozumieniem
            któregoś z tych wyrażeń, powinni jeszcze raz przeczytać przykłady
            zawarte w tych ćwiczeniach. Odpowiedzi do tej części zadania można
            znaleźć w kluczu. Następnie studenci powinni ułożyć swoje własne
            krótkie historyjki wakacyjne z wykorzystaniem frazeologii. Należy
            pozostawić im na to kilka minut, ponieważ na tym poziomie
            zaawansowania spontaniczna dłuższa wypowiedź może jeszcze sprawiać
            problem.
            <br />
            <br />
            <b>
              <HashLink
                to="#cwiczenie-4"
                smooth
                scroll={(el) => scrollWithOffset(el)}
                className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
              >
                Ćwiczenie IV
              </HashLink>
            </b>{" "}
            rozszerza zakres słownictwa studentów o konstrukcje czasownikowe.
            Odpowiedzi do tej części zadania znajdują się w kluczu. Można
            następnie poprosić uczestników zajęć o to, aby zaproponowali kolejne
            reguły, których powinien przestrzegać ekoturysta. Tę część zadania
            można zrealizować w grupach lub parach w zależności od liczby
            kursantów. Jeśli czas jednostki lekcyjnej się kończy, można poprosić
            studentów o to, aby zrealizowali to zadanie w formie pisemnej jako
            zadanie domowe.
            <br />
            <br />
          </Typography>
        </CardContent>
      </Card>
      <Card className={classes.mbs} id="cwiczenie-1">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie I.A
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            LEKSYKA I CZYTANIE: Proszę przeczytać tekst o ekowakacjach i
            uzupełnić go brakującymi frazeologizmami z ramki:
          </Typography>
          <Typography variant="body2" align="justify">
            <b>Ekoturystyka: wyjątkowe ekologiczne wakacje</b>
            <br />
            Jeśli chcesz …………………………<sup>1</sup>, musisz być eko i podróżować w
            sposób niezakłócający środowiska naturalnego.
            <br />
            <br />
            <b>Co to jest ekoturystyka?</b>
            <br />W ekoturystyce chodzi o to, aby zachować naturalne środowisko.
            Jeśli prowadzisz ekologiczny dom i nie ……………………………<sup>2</sup> idee
            filozofii less waste lub zero waste, ekoturystyka jest dla Ciebie.
            Innymi słowy – zwiedzaj i w tym samym czasie dbaj o środowisko!
            Ekoturystyka jest formą aktywnego zwiedzania regionów o wysokich
            walorach przyrodniczych, krajobrazowych i kulturowych.
            <br />
            <br />
            <b>Formy ekoturystyki</b>
            <br />
            Główna forma ekoturystyki to oczywiście wakacje w gospodarstwie
            agroturystycznym! Jeśli wolisz miasto i uważasz wieś za ……………………………
            <sup>3</sup>, to mamy też dalszą propozycję. Inną, równie eko formą
            spędzania czasu jest tak zwane staycation. Staycation to po prostu
            urlop spędzany z rodziną w zaciszu domowym – i to nawet jeśli
            mieszkasz w …………………………………<sup>4</sup>. Taka forma wypoczynku może
            być atrakcyjna szczególnie dla tych, którzy na co dzień
            …………………………………………
            <sup>5</sup>. No i nieważne, jaka jest pogoda – na zewnątrz może
            …………………………………………<sup>6</sup> – nic nie szkodzi.
          </Typography>
        </CardContent>
      </Card>

      <Exercise
        data={data1}
        answers={answers1}
        mb={false}
        uniqueItemTypeName="c1"
      />
      <Card className={classes.mbl}>
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie I.B
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            MÓWIENIE: Jaką formę wypoczynku byś wybrał(a): pobyt w gospodarstwie
            agroturystycznym czy staycation? Co byś robił(a) podczas takich
            ekowakacji?
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.mbs} id="cwiczenie-2">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie II.A
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            II. CZYTANIE, LEKSYKA I GRAMATYKA: Przeczytaj poniższy tekst i
            uzupełnij go słowami z ramki tak, by powstały frazeologizmy:
          </Typography>
          <Typography variant="body2" align="justify">
            <br />
            <b>5 ekogadżetów każdego ekoturysty</b>
            <br />
            Jeśli bliskie są Ci trendy eko, na pewno łamiesz sobie
            ……………………………………………………<sup>1</sup> nad tym, jak oszczędzać wodę lub
            ograniczyć produkcję plastiku i innych substancji, które będą
            rozkładały się tysiące lat. Poniżej przykłady gadżetów, które doceni
            każdy ekoturysta:
            <br />
            <br />
            1. Na zdrowy ……………………………………<sup>2</sup> podstawowym ekogadżetem nie
            tylko w podróży powinna być butelka na wodę z filtrem. Nie tylko
            ograniczasz wytwarzanie plastiku, ale także oszczędzasz na kupnie
            wody butelkowanej. Dodatkowo w podróży nie musisz nosić ciężkiej
            butli z piciem, gdyż zasoby wody uzupełnisz w każdym miejscu.
            <br />
            <br />
            2. Kolejną przydatną rzeczą zarówno w życiu codziennym, jak i w
            ekourystyce są własne pojemniki na żywność! Jest to duże ułatwienie,
            gdy chcesz zabrać jedzenie na wynos – przecież w podróży często mamy
            wilczy ……………………………………………………………………<sup>3</sup>.
            <br />
            <br />
            3. Jeśli masz własne pojemniki na jedzenie, możesz dodać do kompletu
            sztućce. I już możesz jeść, aż się …………………………………………………<sup>
              4
            </sup>{" "}
            trzęsą!
            <br />
            <br />
            4. W podróży sprawdzą się również ekologiczne środki czystości,
            takie jak mydło czy szampon w kostce. Jeśli są biodegradowalne,
            możesz się nimi umyć nawet podczas kąpieli na ………………………………………………………
            <sup>5</sup> natury. Dodatkowo zajmują niewiele miejsca, więc
            zmieszczą się w każdym plecaku!
            <br />
            <br />
            5. Jeśli nie musisz wyglądać w podróży jak milion
            ………………………………………………………<sup>6</sup>, zainwestuj w ubrania z
            recyklingu.
          </Typography>
        </CardContent>
      </Card>

      <Exercise
        data={data2}
        answers={answers2}
        mb={false}
        uniqueItemTypeName="c2"
      />
      <Card className={classes.mbl}>
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie II.B
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Poniżej znajdują się jeszcze inne pomysły na ekogadżety. Zastanów
            się, jak można je wykorzystać podczas podróży.
            <br />
            <br />
            <Chip
              label="wielorazowe słomki do napojów"
              variant="outlined"
              style={{ marginRight: 5, marginBottom: 10 }}
            />
            <Chip
              label="składana torba z materiału"
              variant="outlined"
              style={{ marginRight: 5, marginBottom: 10 }}
            />
            <Chip
              label="tabletki do mycia zębów"
              variant="outlined"
              style={{ marginRight: 5, marginBottom: 10 }}
            />
            <Chip
              label="plastry biodegradowalne"
              variant="outlined"
              style={{ marginRight: 5, marginBottom: 10 }}
            />
            <Chip
              label="bambusowa szczoteczka do zębów"
              variant="outlined"
              style={{ marginRight: 5, marginBottom: 10 }}
            />
            <Chip
              label="naturalny dezodorant w kostce"
              variant="outlined"
              style={{ marginRight: 5, marginBottom: 10 }}
            />
            <Chip
              label="woskowijki spożywcze"
              variant="outlined"
              style={{ marginRight: 5, marginBottom: 10 }}
            />
            <br />
            <br />
            Czy znasz jakieś inne ekologiczne produkty, które mogą być przydatne
            podczas podróży?
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.mbs} id="cwiczenie-3">
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" align="left" className={classes.wrapIcon}>
            <BorderColorIcon /> Ćwiczenie III.A
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            LEKSYKA: Połącz frazeologizmy z powyższego ćwiczenia z ich
            definicjami:
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data3}
        answers={answers3}
        mb={false}
        uniqueItemTypeName="c3"
      />
      <Card className={classes.mbl}>
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie III.B
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę opowiedzieć własną wakacyjną przygodę. Postaraj się, by w
            Twojej opowieści pojawiły się co najmniej dwa z powyższych
            frazeologizmów.
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.mbs} id="cwiczenie-4">
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" align="left" className={classes.wrapIcon}>
            <BorderColorIcon /> Ćwiczenie IV.A
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę uzupełnić poniższy tekst czasownikami w bezokoliczniku:
          </Typography>
          <Typography variant="body2" align="justify">
            <br />
            <b>Jak być ekoturystą?</b>
            <br />
            Powinieneś/powinnaś ……………………………………<sup>1</sup> kilku podstawowych
            reguł:
            <br />
            A. Nie ……………………………<sup>2</sup> śmieci na szlakach turystycznych czy
            plażach.
            <br />
            B. Na pieszych wędrówkach zawsze ………………………<sup>3</sup> wyznaczonych
            szlaków.
            <br />
            C. ………………………………………<sup>4</sup> przy sobie ekogadżety!
            <br />
            D. Zamiast komercyjnych pamiątek …………………………<sup>5</sup> produkty
            lokalne.
            <br />
            E. Nie …………………………………<sup>6</sup> dzikich zwierząt.
            <br />
            F. …………………………………<sup>7</sup> miejsca oddalone od wielkich skupisk
            miejskich.
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data4}
        answers={answers4}
        mb={false}
        uniqueItemTypeName="c4"
      />
      <Card className={classes.mbl}>
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie IV.B
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Jakich innych reguł powinien trzymać się ekoturysta? Proszę wymyślić
            jeszcze trzy i wykorzystać w nich dowolne frazeologizmy.
          </Typography>
        </CardContent>
      </Card>
      <Card className={classes.mbs}>
        <CardContent className={classes.cardContent}>
          <Typography variant="body1" align="left" gutterBottom>
            Wszystkie teksty w tej lekcji na podstawie:{" "}
            <Link
              href="https://www.westwing.pl/inspiration/lifestyle/podrozowanie/ekoturystyka/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.westwing.pl/inspiration/lifestyle/podrozowanie/ekoturystyka/
            </Link>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default Exercise2;
