import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import { Accordion, AccordionSummary, useStyles, TT } from "./helpers";

export default function Team() {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Avatar
        alt="dr Ewelina Woźniak-Wrzesińska"
        src={process.env.PUBLIC_URL + "/images/team/F.jpg"}
        className={classes.avatar}
      />
      <CardContent>
        <Typography variant="h6" component="h2">
          dr Ewelina Woźniak-Wrzesińska
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Zakład Frazeologii i Kultury Języka Polskiego
          <br />
          Uniwersytet im. Adama Mickiewicza w Poznaniu
          <br />
          koordynatorka
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          href="mailto:ewewoz@amu.edu.pl"
        >
          E-mail
        </Button>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          href="https://orcid.org/0000-0002-2257-3059"
          target="_blank"
        >
          ORCID
        </Button>
        <Button
          className={classes.button}
          size="small"
          color="primary"
          href="http://kjp.amu.edu.pl/eww.html"
          target="_blank"
        >
          WWW
        </Button>
      </CardActions>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Więcej</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Card className={classes.photoCard}>
                <img
                  className={classes.photo}
                  alt="zdjęcie uczestnika"
                  src={process.env.PUBLIC_URL + "/images/team/F1.jpg"}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="justify"
                className={classes.personDesc}
              >
                Jest we mnie coś z{" "}
                <TT
                  title={
                    <>
                      niespokojny duch: ‘człowiek niestabilny emocjonalnie, dużo
                      zmieniający w swoim życiu’
                    </>
                  }
                  label="niespokojnego ducha"
                />
                . Wychowałam się w malowniczej{" "}
                <TT
                  title={
                    <>
                      wieś / wiocha / dziura zabita deskami / dechami: ‘bardzo
                      mała wieś, w której nie ma sklepów, punktów usługowych,
                      edukacyjnych, rozrywkowych’
                    </>
                  }
                  label="wsi (niezabitej dechami)"
                />{" "}
                i pewnie dlatego lubię świeże powietrze. Szeroko otwieram więc{" "}
                <TT
                  title={
                    <>
                      okno na świat: ‘coś, dzięki czemu jest możliwy
                      kontakt z zagranicą i zdobywanie o niej informacji’
                    </>
                  }
                  label="okno na świat"
                />
                . Silne więzi łączą mnie z Łodzią, Poznaniem, Wrocławiem…, choć
                najlepiej czuję się{" "}
                <TT
                  title={<>o dwa kroki (skąd / dokądś): ‘niedaleko’</>}
                  label="o dwa kroki stąd"
                />
                . W pracy{" "}
                <TT
                  title={
                    <>
                      <i>ktoś</i> nie zasypia gruszek w popiele: ‘ktoś nie
                      zaniedbuje spraw wymagających załatwienia, ktoś korzysta z
                      nadarzającej się okazji’
                    </>
                  }
                  label="nie zasypiam gruszek w popiele"
                />{" "}
                i{" "}
                <TT
                  title={
                    <>
                      <i>ktoś</i> staje, stanął na głowie: ‘ktoś robi wszystko,
                      co możliwe, aby coś osiągnąć, bardzo się stara, dąży do
                      celu za wszelką cenę; wykorzystuje wszystkie możliwości,
                      aby osiągnąć cel’
                    </>
                  }
                  label="staję na głowie"
                />
                , by osiągnąć zamierzony cel. Zdarza się jednak, że w obliczu
                drugiego człowieka{" "}
                <TT
                  title={
                    <>
                      <i>ktoś</i> dał, daje za wygraną: ‘ktoś zrezygnował z
                      czegoś, przestał o coś się starać, pogodził się z porażką,
                      zrezygnował ze swoich zamiarów, ustąpił, poddał się,
                      przestał walczyć o kogoś lub o coś’
                    </>
                  }
                  label="daję za wygraną, "
                />
                zwłaszcza gdy uczymy się{" "}
                <TT
                  title={
                    <>
                      <i>ktoś</i> i <i>ktoś</i> mówią wspólnym językiem: ‘o
                      osobach mających takie samo zdanie, żyjących w zgodzie’
                    </>
                  }
                  label="mówić wspólnym językiem, "
                />
                by jak najróżniej{" "}
                <TT
                  title={
                    <>
                      <i>ktoś</i> nazywa, nazwał (rzecz / rzeczy) po imieniu:
                      ‘ktoś mówi o czymś wprost, bezpośrednio, prosto,
                      jednoznacznie’
                    </>
                  }
                  label="nazywać rzeczy po imieniu"
                />
                . A tymczasem wybieram kierunek:{" "}
                <TT
                  title={
                    <>
                      nie święci garnki lepią: ‘nie trzeba być kimś wyjątkowym,
                      żeby coś osiągnąć’
                    </>
                  }
                  label="„nie święci garnki lepią”"
                />{" "}
                –
                <TT
                  title={
                    <>
                      pożyjemy, zobaczymy: ‘za jakiś czas zobaczymy, czy to, co
                      przypuszczamy, się spełni’
                    </>
                  }
                  label="„pożyjemy, zobaczymy”"
                />
                .
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
