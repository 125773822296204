import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Exercise from "./Exercise";
import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoImg: {
      margin: "auto",
      display: "block",
      maxWidth: "300px",
      maxHeight: "100px",
    },
    button: {
      margin: theme.spacing(1),
    },
    lectureImg: {
      margin: "auto",
      marginBottom: 2,
      display: "block",
      maxWidth: "60%",
      borderRadius: 8,
      border: "1px solid black",
      borderColor: "rgba(0,0,0,0.2)",
    },
    wrapIcon: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "& > svg": {
        marginRight: 10,
      },
    },
    mbl: {
      marginBottom: 50,
    },
    mbs: {
      marginBottom: 15,
    },
    cardContent: {
      padding: 40,
      paddingBottom: 20,
    },
  })
);

const data1 = [
  {
    a: "Państwo Kowalscy mieszkają w …………… .",
    b: "płacą",
  },
  {
    a: "Państwo Kowalscy co miesiąc …………… za wynajem mieszkania.",
    b: "bloku",
  },
  {
    a: "Franek ma …………… pokój.",
    b: "mieszają",
  },
  {
    a: "Za kilka miesięcy Franek będzie miał …………… .",
    b: "zwykłego",
  },
  {
    a: "Państwo Kowalscy chcą …………… dom.",
    b: "mały",
  },
  {
    a: "Nowego domu …………… pan Kowalski.",
    b: "kupić",
  },
  {
    a: "Pan Kowalski myślał, że nowy dom znajdzie …………… .",
    b: "szuka",
  },
  {
    a: "Oferty, które pan Kowalski przeczytał, …………… mu się i już nie może na nie patrzeć.",
    b: "rodzeństwo",
  },
  {
    a: "Niektóre oferty są …………… drogie.",
    b: "łatwo",
  },
  {
    a: "Państwo Kowalscy szukają …………… domu.",
    b: "bardzo",
  },
];

const answers1 = [
  { a: 0, b: 1 },
  { a: 1, b: 0 },
  { a: 2, b: 4 },
  { a: 3, b: 7 },
  { a: 4, b: 5 },
  { a: 5, b: 6 },
  { a: 6, b: 8 },
  { a: 7, b: 2 },
  { a: 8, b: 9 },
  { a: 9, b: 3 },
];

const data2 = [
  {
    a: "…………… stają dęba",
    b: "rośnie",
  },
  {
    a: "ktoś ma dach nad ……………",
    b: "maśle",
  },
  {
    a: "coś tańczy komuś przed ……………",
    b: "głową",
  },
  {
    a: "ktoś …………… jak na drożdżach",
    b: "włosy",
  },
  {
    a: "coś idzie/pójdzie jak po ……………",
    b: "oczami",
  },
];

const answers2 = [
  { a: 0, b: 3 },
  { a: 1, b: 2 },
  { a: 2, b: 4 },
  { a: 3, b: 0 },
  { a: 4, b: 1 },
];

const data3 = [
  {
    a: "ktoś/coś rośnie jak na drożdżach",
    b: "ktoś czuje przerażenie, zdziwienie",
  },
  {
    a: "ktoś ma dach nad głową",
    b: "coś idzie bardzo dobrze, łatwo i szybko",
  },
  {
    a: "coś idzie/pójdzie jak po maśle",
    b: "ktoś/coś rośnie bardzo szybko",
  },
  {
    a: "coś tańczy komuś przed oczami",
    b: "ktoś ma miejsce, gdzie (w którym) może mieszkać",
  },
  {
    a: "włosy stają dęba",
    b: "ktoś nie widzi czegoś dobrze, ponieważ jest zmęczony",
  },
];

const answers3 = [
  { a: 0, b: 2 },
  { a: 1, b: 3 },
  { a: 2, b: 1 },
  { a: 3, b: 4 },
  { a: 4, b: 0 },
];

const data4 = [
  {
    a: "1) Jeśli kupimy tańszą kanapę, …………… dokupić do niej nowe poduszki.",
    b: "wygląda",
  },
  {
    a: "2) Na przykład te zielone poduszki świetnie do niej ……………!",
    b: "możemy",
  },
  { a: "3) …………… mi się te szare lub niebieskie.", b: "muszę" },
  { a: "4) No dobrze, ale ja jednak …………… te niebieskie?", b: "podobają" },
  { a: "5) …………… być niebieskie.", b: "wolę" },
  { a: "6) ……………, że stać nas na nowe łóżko?", b: "myślisz" },
  { a: "7) Poczekaj, …………… odliczyć kanapę i poduszki.", b: "mogą" },
  { a: "8) Popatrz, ten …………… dobrze.", b: "spróbuję" },
  { a: "9) …………… się na nim położyć...", b: "będę" },
  { a: "10) Na takim materacu …………… spać jak suseł!", b: "pasują" },
];

const answers4 = [
  { a: 0, b: 1 },
  { a: 1, b: 9 },
  { a: 2, b: 3 },
  { a: 3, b: 4 },
  { a: 4, b: 6 },
  { a: 5, b: 5 },
  { a: 6, b: 2 },
  { a: 7, b: 0 },
  { a: 8, b: 7 },
  { a: 9, b: 8 },
];

const data5 = [
  {
    a: "ktoś ma nierówno pod ……………",
    b: "kożucha",
  },
  {
    a: "kogoś stać …………… coś",
    b: "suseł",
  },
  {
    a: "spać jak ……………",
    b: "sufitem",
  },
  {
    a: "coś pasuje jak kwiatek do ……………",
    b: "na",
  },
  {
    a: "coś jest w …………… raz",
    b: "sam",
  },
];

const answers5 = [
  { a: 0, b: 2 },
  { a: 1, b: 3 },
  { a: 2, b: 1 },
  { a: 3, b: 0 },
  { a: 4, b: 4 },
];

const data6 = [
  {
    a: "coś pasuje jak kwiatek do kożucha",
    b: "ktoś jest szalony",
  },
  {
    a: "ktoś ma nierówno pod sufitem",
    b: "coś jest takie, jakie powinno być",
  },
  {
    a: "kogoś stać na coś",
    b: "ktoś śpi bardzo dobrze, śpi głębokim snem",
  },
  {
    a: "coś jest w sam raz",
    b: "coś do czegoś w ogóle nie pasuje",
  },
  {
    a: "ktoś śpi jak suseł",
    b: "ktoś może sobie coś kupić, bo ma pieniądze",
  },
];

const answers6 = [
  { a: 0, b: 3 },
  { a: 1, b: 0 },
  { a: 2, b: 4 },
  { a: 3, b: 1 },
  { a: 4, b: 2 },
];

const data7 = [
  {
    a: "ktoś/coś rośnie jak na drożdżach",
    b: "stawać",
  },
  {
    a: "kogoś stać na coś",
    b: "rosnąć",
  },
  {
    a: "coś idzie/pójdzie jak po maśle",
    b: "iść/pójść",
  },
  {
    a: "coś tańczy komuś przed oczami",
    b: "pasować",
  },
  {
    a: "włosy stają dęba",
    b: "mieć",
  },
  {
    a: "coś pasuje jak kwiatek do kożucha",
    b: "spać",
  },
  {
    a: "ktoś ma nierówno pod sufitem",
    b: "stać",
  },
  {
    a: "coś jest w sam raz",
    b: "tańczyć",
  },
  {
    a: "ktoś śpi jak suseł",
    b: "być",
  },
];

const answers7 = [
  { a: 0, b: 1 },
  { a: 1, b: 6 },
  { a: 2, b: 2 },
  { a: 3, b: 7 },
  { a: 4, b: 0 },
  { a: 5, b: 3 },
  { a: 6, b: 4 },
  { a: 7, b: 8 },
  { a: 8, b: 5 },
];

function Exercise1() {
  const classes = useStyles();
  const [isPlaying, setIsPlaying] = useState(false);
  const [viiiAnswer, setViiiAnswer] = useState(false);
  const [ixAnswer, setIxAnswer] = useState(false);

  const [audio] = useState(
    new Audio(process.env.PUBLIC_URL + `/audio/cwiczenie-4.mp3`)
  );

  const runAudio = () => {
    if (!isPlaying) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    document.title = "Interaktywne ćwiczenia - Dach nad głową";
  }, []);

  return (
    <>
      <Typography variant="h5" component="h1">
        Dach nad głową
      </Typography>
      <Typography variant="subtitle2">ćwiczenia na poziomie A1</Typography>
      <Typography variant="body2" gutterBottom style={{ marginBottom: 20 }}>
        dr Zuzana Obertová
      </Typography>

      <Card className={classes.mbl}>
        <CardContent className={classes.cardContent}>
          <Typography variant="body2" align="justify">
            Lekcja jest przeznaczona dla uczących się języka polskiego jako
            obcego na poziomie A1. Tematycznie jest związana z mieszkaniem,
            szukaniem i urządzaniem mieszkania – może zatem stanowić materiał
            uzupełniający do zajęć na ten temat. Z jednej strony uczący się mają
            okazję, żeby powtórzyć słownictwo dotyczące mieszkania, z drugiej
            strony poznają dziesięć związków frazeologicznych, osadzonych w
            danym kontekście. Ponieważ chodzi o podstawowy poziom znajomości
            języka polskiego jako obcego, frazeologizmy zostały dobrane ze
            względu na jasną motywację ich znaczenia oraz stosunkowo proste
            komponenty, z których większość należy do zakresu zagadnień
            leksykalnych dla poziomu A1.
            <br />
            <br />
            Poszczególne ćwiczenia rozwijają przede wszystkim umiejętność
            rozumienia tekstu pisanego. Ćwiczenie IV zostało uzupełnione o
            nagranie dialogu, można go zatem wykorzystać zarówno jako ćwiczenie
            do czytania, jak i do słuchania. Ćwiczenia VI – IX łączą czytanie z
            gramatyką, są one powtórzeniem odmiany czasowników w czasie
            teraźniejszym. Ostatnie ćwiczenie rozwija kompetencję pisania w
            języku polskim.
            <br />
            <br />
          </Typography>
        </CardContent>
      </Card>
      <Card className={classes.mbs}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" align="left" className={classes.wrapIcon}>
            <BorderColorIcon /> Ćwiczenie I
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę przeczytać tekst, a następnie uzupełnić zdania pod nim
            wyrazami z ramki.
          </Typography>
          <Typography variant="body2" align="justify">
            Państwo Kowalscy wynajmują trzypokojowe mieszkanie w centrum miasta.
            Mają jednego syna Franka i psa Azora. Franek ma siedem lat i rośnie
            jak na drożdżach, potrzebuje większego pokoju. Poza tym za parę
            miesięcy urodzi się im drugie dziecko. Państwo Kowalscy zdecydowali,
            że już powinni mieć własny dach nad głową, dlatego szukają domu na
            sprzedaż. Pan Kowalski przegląda oferty. Na początku myślał, że
            wszystko pójdzie jak po maśle: przeczyta kilka ofert, umówi się na
            spotkanie, z żoną zobaczą parę domów, wybiorą jeden i to wszystko.
            Jednakże już od dwóch tygodni siedzi w Internecie, zdjęcia domów
            tańczą mu przed oczami, nie pamięta, które widział, a których nie.
            Jeden dom jest za duży, drugi za mały, inny taki drogi, że włosy
            stają dęba. Państwo Kowalscy nie potrzebują nic specjalnego. Dom
            powinien być cztero- lub pięciopokojowy z tarasem i niedużym
            ogródkiem.
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data1}
        answers={answers1}
        mb={true}
        uniqueItemTypeName="c1"
      />

      <Card className={classes.mbs}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" align="left" className={classes.wrapIcon}>
            <BorderColorIcon /> Ćwiczenie II
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            W tekście pojawia się pięć frazeologizmów. Proszę je odnaleźć, a
            następnie uzupełnić brakujące części frazeologizmów wyrazami z
            ramki.
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data2}
        answers={answers2}
        mb={true}
        uniqueItemTypeName="c2"
      />

      <Card className={classes.mbs}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" align="left" className={classes.wrapIcon}>
            <BorderColorIcon /> Ćwiczenie III
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę połączyć frazeologizmy z ich definicjami.
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data3}
        answers={answers3}
        mb={true}
        uniqueItemTypeName={"c3"}
      />

      <Card className={classes.mbs}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" align="left" className={classes.wrapIcon}>
            <BorderColorIcon /> Ćwiczenie IV
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Państwo Barbara i Jan Kowalscy nareszcie kupili nowy dom i w sklepie
            meblowym zastanawiają się, jak go urządzić. Proszę przeczytać ich
            rozmowę i uzupełnić luki w dialogu czasownikami z ramki.
          </Typography>

          <Typography variant="body2" align="justify">
            B: – Ta żółta kanapa mi się podoba.
            <br />
            J: – Mnie też, poza tym ma zniżkę o 40%, to naprawdę bardzo dobra
            promocja!
            <br />
            B: – No tak, teraz musimy liczyć się z każdym groszem. Jeśli kupimy
            tańszą kanapę, ……………<sup>1</sup> dokupić do niej nowe poduszki.
            <br />
            J: – Na przykład te zielone poduszki świetnie do niej ……………
            <sup>2</sup>!
            <br />
            B: – Zielone poduszki do żółtej kanapy pasują jak kwiatek do
            kożucha! Poza tym nie podoba mi się ten materiał.
            <br />
            J: – To jakie wolisz?
            <br />
            B: – ……………<sup>3</sup> mi się te szare lub niebieskie.
            <br />
            J: – Szare? Taki nudny kolor?
            <br />
            B: – Chyba masz nierówno pod sufitem, przecież szary kolor jest
            teraz bardzo modny.
            <br />
            J: – No dobrze, ale ja jednak ……………<sup>4</sup> te niebieskie.
            <br />
            B: – ……………<sup>5</sup> być niebieskie. ……………<sup>6</sup>, że stać
            nas na nowe łóżko? Ile pieniędzy zostało nam jeszcze na umeblowanie
            domu?
            <br />
            J: – Poczekaj, ……………<sup>7</sup> odliczyć kanapę i poduszki. Hmm,
            moglibyśmy kupić tylko nowy materac.
            <br />
            B: – O tak, przecież materac jest najważniejszy. Popatrz, ten ……………
            <sup>8</sup> dobrze.
            <br />
            J: – ……………<sup>9</sup> się na nim położyć... Jest naprawdę wygodny!
            Ani miękki, ani twardy, taki w sam raz!
            <br />
            B: – Ja też spróbuję... Masz rację! Na takim materacu ……………
            <sup>10</sup> spać jak suseł! Zaraz go kupimy!
          </Typography>
          <br />
          <Button variant="outlined" onClick={runAudio}>
            {isPlaying ? (
              <>
                <PauseCircleOutlineIcon /> &nbsp; Pauza
              </>
            ) : (
              <>
                <PlayCircleOutlineIcon /> &nbsp; Odsłuchaj nagranie
              </>
            )}
          </Button>
        </CardContent>
      </Card>
      <Exercise
        data={data4}
        answers={answers4}
        mb={true}
        uniqueItemTypeName={"c4"}
      />

      <Card className={classes.mbs}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" align="left" className={classes.wrapIcon}>
            <BorderColorIcon /> Ćwiczenie V
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            W tekście pojawia się pięć frazeologizmów. Proszę je odnaleźć, a
            następnie uzupełnić brakujące części frazeologizmów wyrazami z
            ramki.
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data5}
        answers={answers5}
        mb={true}
        uniqueItemTypeName={"c5"}
      />

      <Card className={classes.mbs}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" align="left" className={classes.wrapIcon}>
            <BorderColorIcon /> Ćwiczenie VI
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę połączyć frazeologizmy z ich definicjami.
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data6}
        answers={answers6}
        mb={true}
        uniqueItemTypeName={"c6"}
      />

      <Card className={classes.mbs}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" align="left" className={classes.wrapIcon}>
            <BorderColorIcon /> Ćwiczenie VII
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę dopasować bezokolicznik do odpowiedniego frazeologizmu. Jaki
            to typ koniugacji?
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data7}
        answers={answers7}
        mb={true}
        uniqueItemTypeName={"c7"}
      />

      <Card className={classes.mbl}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" align="left" className={classes.wrapIcon}>
            <BorderColorIcon /> Ćwiczenie VIII
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę uzupełnić zdania frazeologizmami z prawidłowymi formami
            czasowników.
          </Typography>

          <Typography variant="body2" align="left">
            1. Jurek sam remontuje łazienkę, praca mu ……………, za tydzień będzie
            gotowy.
            <br />
            2. Teraz zimą jecie lody? Czy wy ……………? Zachorujecie!
            <br />
            3. Ania uczyła się całą noc do egzaminu z historii. Wszystkie daty
            …………… .
            <br />
            4. Nasze dzieci ……………, na wiosnę musimy im kupić większe buty.
            <br />
            5. Ten stolik tu ……………, postawimy go pod oknem przy ścianie.
            <br />
            6. Norbert zawsze ……………, nawet kiedy jego współlokator rozmawia z
            dziewczyną do północy.
            <br />
            7. Julia jest od roku bezrobotna, na razie ……………, ale obawia się, co
            się stanie, jeśli nie będzie miała pieniędzy na wynajem mieszkania.
            <br />
            8. To lustro mi się podoba, jest ładne i bardzo modne, ale nie ……………
            na nie.
            <br />
            9. Piotr nie chce już mieszkać w akademiku, ceny za wynajem
            mieszkania są takie drogie, że ………… .
            <br />
            10. Te krzesła …………… do naszej kuchni, nie są ani duże, ani małe.
          </Typography>

          <br />
          {!viiiAnswer && (
            <Button variant="outlined" onClick={() => setViiiAnswer(true)}>
              <VisibilityIcon /> &nbsp; Pokaż rozwiązanie
            </Button>
          )}
          {viiiAnswer && (
            <>
              <Typography variant="h6" align="left">
                Rozwiązanie:
              </Typography>
              <Typography variant="body2" align="left">
                <br />
                1. idzie jak po maśle,
                <br />
                2. nie macie równo pod sufitem,
                <br />
                3. tańczą jej przed oczami,
                <br />
                4. rosną jak na drożdżach,
                <br />
                5. pasuje jak kwiatek do kożucha,
                <br />
                6. śpi jak suseł,
                <br />
                7. ma dach nad głową,
                <br />
                8. stać mnie,
                <br />
                9. włosy stają dęba,
                <br />
                10. są w sam raz
              </Typography>
            </>
          )}
        </CardContent>
      </Card>

      <Card className={classes.mbl}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" align="left" className={classes.wrapIcon}>
            <BorderColorIcon /> Ćwiczenie IX
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            W dwóch frazeologizmach czasownik nigdy nie zmienia formy, wiesz w
            których?
          </Typography>
          <br />
          {!ixAnswer && (
            <Button variant="outlined" onClick={() => setIxAnswer(true)}>
              <VisibilityIcon /> &nbsp; Pokaż rozwiązanie
            </Button>
          )}
          {ixAnswer && (
            <>
              <Typography variant="h6" align="left">
                Rozwiązanie:
              </Typography>
              <Typography variant="body2" align="left">
                <br />
                1. włosy stają dęba,
                <br />
                2. kogoś stać na coś
              </Typography>
            </>
          )}
        </CardContent>
      </Card>

      <Card className={classes.mbs}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" align="left" className={classes.wrapIcon}>
            <BorderColorIcon /> Ćwiczenie X
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Proszę napisać własny logiczny tekst z użyciem co najmniej czterech
            frazeologizmów z tej lekcji.
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default Exercise1;
