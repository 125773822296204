import { useEffect, useState } from "react";
import { Card, useMediaQuery, useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import YouTube, { Options } from "react-youtube";
import { useStyles } from "../Lectures/helpers";

const videoId = "CrfHb1DwlOk";
const lectureNo = 3;
const slidesSeconds = [
  { s: 1, from: 18, to: 62 },
  { s: 2, from: 62, to: 80 },
  { s: 3, from: 110, to: 136 },
  { s: 4, from: 180, to: 253 },
  { s: 5, from: 257, to: 296 },
  { s: 6, from: 296, to: 311 },
  { s: 7, from: 353, to: 385 },
  { s: 8, from: 437, to: 495 },
  { s: 9, from: 520, to: 541 },
  { s: 10, from: 546, to: 561 },
  { s: 11, from: 667, to: 747 },
  { s: 12, from: 769, to: 940 },
  { s: 13, from: 940, to: 1290 },
  { s: 14, from: 1305, to: 1727 },
  { s: 15, from: 1727, to: 2125 },
  { s: 16, from: 2126, to: 2533 },
  { s: 17, from: 2534, to: 2876 },
  { s: 18, from: 2677, to: 3071 },
  { s: 19, from: 3072, to: 3608 },
  { s: 20, from: 3609, to: 3646 },
];

const handleDragStart = (e: any) => e.preventDefault();
const images = slidesSeconds.map((s) => (
  <img
    src={process.env.PUBLIC_URL + `/slides/${lectureNo}/${s.s}.jpg`}
    style={{ maxWidth: 1070, width: "100%" }}
    onDragStart={handleDragStart}
    alt="Slajd"
  />
));

const getSlideBySecond = (second: number) => {
  let result = 1;
  slidesSeconds.forEach((el) => {
    if (el.from <= second && el.to > second) result = el.s;
  });
  return result;
};

function Lecture3() {
  const [currentSecond, setCurrentSecond] = useState<number | undefined>(
    undefined
  );
  const [yt, setYt] = useState<any | undefined>(undefined);
  const [currentSlide, setCurrentSlide] = useState(0);

  const updateCurrentSecond = (ytTime: number) =>
    setCurrentSecond(Math.floor(ytTime));

  useEffect(() => {
    if (!yt) return;

    const interval = setInterval(
      () => updateCurrentSecond(yt.getCurrentTime()),
      1000
    );
    return () => clearInterval(interval);
  }, [yt]);

  useEffect(() => {
    if (currentSecond) {
      const foundedSlide = getSlideBySecond(currentSecond);
      if (foundedSlide !== currentSlide)
        setCurrentSlide(foundedSlide ? foundedSlide : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSecond]);

  const opts = (playerHeight: string): Options => ({
    width: "100%",
    height: playerHeight, //"500",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 0,
    },
  });

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const playerHeight = isXs ? "200" : isSm ? "350" : "500";

  const onReady = (event: any) => {
    setYt(event.target);
  };

  const classes = useStyles();

  return (
    <div style={{ height: "100%" }}>
      <Typography variant="h5" component="h1" className={classes.modalHeader}>
        „Piosenka jest dobra na wszystko”
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        dr hab. Gabriela Dziamska-Lenart, prof. UAM
        <br />
        dr Ewelina Woźniak-Wrzesińska
      </Typography>
      <br />

      <Card style={{ width: "100%", maxWidth: 1070, lineHeight: 0 }}>
        <YouTube id="YT3" videoId={videoId} opts={opts(playerHeight)} onReady={onReady} />
      </Card>
      <br />

      <Card style={{ width: "100%", maxWidth: 1070 }}>
        <AliceCarousel
          mouseTracking={false}
          items={images}
          activeIndex={currentSlide - 1}
          disableDotsControls
          disableButtonsControls
          autoHeight
        />
      </Card>
    </div>
  );
}

export default Lecture3;
