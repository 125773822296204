import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { HashLink } from "react-router-hash-link";
import { Accordion, AccordionSummary, TT } from "../Team/helpers";

import Exercise from "./Exercise";
import { Chip, Grid, Link } from "@material-ui/core";
import { useEffect } from "react";

const scrollWithOffset = (el: any) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -80;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoImg: {
      margin: "auto",
      display: "block",
      maxWidth: "300px",
      maxHeight: "100px",
    },
    button: {
      margin: theme.spacing(1),
    },
    lectureImg: {
      margin: "auto",
      marginBottom: 2,
      display: "block",
      maxWidth: "60%",
      borderRadius: 8,
      border: "1px solid black",
      borderColor: "rgba(0,0,0,0.2)",
    },
    wrapIcon: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "& > svg": {
        marginRight: 10,
      },
    },
    mbl: {
      marginBottom: 50,
    },
    mbs: {
      marginBottom: 15,
    },
    cardContent: {
      padding: 40,
      paddingBottom: 20,
    },
    agreement: {
      maxWidth: 700,
      margin: "auto",
    },
    abbr: {
      marginRight: 5,
      marginBottom: 10,
      "&:hover": {
        background: "rgba(128,128,128,.5)",
        cursor: "pointer",
      },
    },
  })
);

const data1 = [
  { a: "1) (.................................. i data)", b: "czasu" },
  { a: "2) UMOWA O PRACĘ NA CZAS …………………………………………", b: "dodatku" },
  {
    a: "3) ……………………………………………………… w dniu 28.04.2021 r. pomiędzy:",
    b: "Kodeksu",
  },
  {
    a: "4) „Ericsson” Sp. z o.o. z ……………………………… w Poznaniu [...]",
    b: "liczba",
  },
  {
    a: "5) [...] a Grzegorzem Brzęczyszczykiewiczem, ………………………… w Chrzęszczyżewoszycach [...]",
    b: "miejscowość",
  },
  {
    a: "6) Strony ustalają następujące ………………………………………………………… zatrudnienia:",
    b: "nieokreślony",
  },
  { a: "7) rodzaj …………………………… pracy:", b: "niepełnym" },
  {
    a: "8) Specjalista ds. mediów …………………………………………………………………… z j. słowackim",
    b: "okresu",
  },
  { a: "9) wymiar …………………………… pracy:", b: "piśmie" },
  {
    a: "10) Zasadnicze w wysokości 4000 zł brutto miesięcznie (………………………: cztery tysiące złotych)",
    b: "podpis",
  },
  {
    a: "11) ……………………………… za pracę wypłacane będzie raz w miesiącu,",
    b: "pracodawcę",
  },
  {
    a: "12) wypłata wynagrodzenia odbywać się będzie na podany na ……………………… rachunek bankowy",
    b: "siedzibą",
  },
  {
    a: "13) długość ……………………………… wypowiedzenia danej umowy wynosi 30 dni kalendarzowych.",
    b: "słownie",
  },
  {
    a: "14) dopuszczalna ………………………………… godzin pracy ponad określony w umowie wymiar czasu pracy",
    b: "społecznościowych",
  },
  { a: "15) których przekroczenie ………………………………………… pracownika", b: "uprawnia" },
  { a: "16) oprócz normalnego wynagrodzenia, do ………………………………,", b: "warunki" },
  {
    a: "17)  o którym mowa w art. 151 § 1 ……………………………… pracy",
    b: "wykonywanej",
  },
  {
    a: "18) z pracownikiem zatrudnionym w …………………………… wymiarze czasu pracy.",
    b: "wynagrodzenie",
  },
  { a: "19) (data i ...................... pracownika)", b: "zamieszkałym" },
  {
    a: "20) (podpis pracodawcy lub osoby reprezentującej …………………………)",
    b: "zawarta",
  },
];

const answers1 = [
  { a: 0, b: 4 },
  { a: 1, b: 5 },
  { a: 2, b: 19 },
  { a: 3, b: 11 },
  { a: 4, b: 18 },
  { a: 5, b: 15 },
  { a: 6, b: 16 },
  { a: 7, b: 13 },
  { a: 8, b: 0 },
  { a: 9, b: 12 },
  { a: 10, b: 17 },
  { a: 11, b: 8 },
  { a: 12, b: 7 },
  { a: 13, b: 3 },
  { a: 14, b: 14 },
  { a: 15, b: 1 },
  { a: 16, b: 2 },
  { a: 17, b: 6 },
  { a: 18, b: 9 },
  { a: 19, b: 10 },
];

const data2 = [
  { a: "sp z o o ", b: "artykuł" },
  { a: "br", b: "bieżącego roku" },
  { a: "ds", b: "dyrektor" },
  { a: "zł", b: "punkt" },
  { a: "art", b: "numer" },
  { a: "dyr", b: "złoty(ch)" },
  { a: "nr", b: "spółka z ograniczoną odpowiedzialnością" },
  { a: "pkt", b: "jak wyżej" },
  { a: "jw", b: "do spraw" },
  { a: "itd", b: "i tak dalej" },
];

const answers2 = [
  { a: 0, b: 6 },
  { a: 1, b: 1 },
  { a: 2, b: 8 },
  { a: 3, b: 5 },
  { a: 4, b: 0 },
  { a: 5, b: 2 },
  { a: 6, b: 4 },
  { a: 7, b: 3 },
  { a: 8, b: 7 },
  { a: 9, b: 9 },
];

const data3 = [
  { a: "czarno na białym", b: "coś ma sens" },
  { a: "ktoś bierze coś na swoje barki", b: "intensywnie" },
  {
    a: "na całego",
    b: "po osiągnięciu pierwszych sukcesów w jakiejś dziedzinie ktoś się nie rozwija",
  },
  { a: "coś dodaje komuś skrzydeł", b: "coś jest dla kogoś motywujące" },
  { a: "na dzień dobry", b: "ktoś zna jakiś temat bardzo dobrze" },
  {
    a: "ktoś staje na głowie",
    b: "ktoś najniżej oceniany lub coś uznawane za najmniej wartościowe",
  },
  {
    a: "ktoś zachodzi w głowę",
    b: "ktoś bardzo mocno się nad czymś zastanawia",
  },
  {
    a: "coś idzie komuś jak krew z nosa",
    b: "ktoś się kimś/czymś w ogóle nie przejmuje",
  },
  { a: "ktoś zna coś od kuchni", b: "ktoś jest bardzo zmęczony" },
  { a: "ktoś spoczywa na laurach", b: "coś przebiega wolno" },
  {
    a: "ktoś doszedł z czymś do ładu",
    b: "ktoś zobowiązuje się do wykonania czegoś",
  },
  { a: "coś ma ręce i nogi", b: "ktoś dąży do celu za wszelką cenę" },
  { a: "ktoś jest na ostatnich nogach", b: "dokładnie i jednoznacznie" },
  { a: "ktoś ma coś/kogoś w nosie", b: "ktoś coś uporządkował" },
  { a: "najsłabsze ogniwo", b: "na początku" },
];

const answers3 = [
  { a: 0, b: 12 },
  { a: 1, b: 10 },
  { a: 2, b: 1 },
  { a: 3, b: 3 },
  { a: 4, b: 14 },
  { a: 5, b: 11 },
  { a: 6, b: 6 },
  { a: 7, b: 9 },
  { a: 8, b: 4 },
  { a: 9, b: 2 },
  { a: 10, b: 13 },
  { a: 11, b: 0 },
  { a: 12, b: 8 },
  { a: 13, b: 7 },
  { a: 14, b: 5 },
];

function Exercise2() {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Interaktywne ćwiczenia - Język biznesowy";
  }, []);

  return (
    <>
      <Typography variant="h5" component="h1">
        Język biznesowy
      </Typography>
      <Typography variant="subtitle2">ćwiczenia na poziomie C1</Typography>
      <Typography variant="body2" gutterBottom style={{ marginBottom: 20 }}>
        dr Magdalena Zakrzewska-Verdugo
      </Typography>

      <Card className={classes.mbl}>
        <CardContent className={classes.cardContent}>
          <Typography variant="body2" align="justify">
            Niniejsza lekcja przeznaczona jest dla studentów uczących się języka
            polskiego na poziomie zaawansowania C1. Jest ona zaplanowana na
            jednostkę lekcyjną trwającą co najmniej 90 min. Tematycznie
            koncentruje się na pracy, a przede wszystkim na stylu urzędowym
            wykorzystywanym w dokumentach takich, jak umowa o pracę. Pojawia się
            w niej również zagadnienie poprawnego zapisu skrótów, użycie
            łączników zdaniowych, leksyka związana z planem dnia w firmie oraz
            frazeologia, dzięki której następuje przejście od stylu urzędowego
            do stylu potocznego związanego ze swobodnym opowiadaniem o swoim
            harmonogramie zajęć i komunikacją w zespole.
            <br />
            <br />
            <b>
              <HashLink
                to="#cwiczenie-1"
                smooth
                scroll={(el) => scrollWithOffset(el)}
                className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
              >
                Ćwiczenie I
              </HashLink>
            </b>{" "}
            można scharakteryzować jako koncentrujące się na pisaniu sterowanym.
            Przy okazji studenci powtarzają lub poznają nowe słownictwo
            biznesowe. Można przy okazji porozmawiać o różnych rodzajach umów
            podpisywanych w Polsce. Poprawne odpowiedzi wyświetlą się po
            rozwiązaniu zadania.
            <br />
            <br />
            <b>
              <HashLink
                to="#cwiczenie-2"
                smooth
                scroll={(el) => scrollWithOffset(el)}
                className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
              >
                Ćwiczenie II
              </HashLink>
            </b>{" "}
            polega na rozwinięciu skrótów zawartych w umowie z ćwiczenia I oraz
            uzupełnieniu kropek tam, gdzie to konieczne. Nie zawiera teorii
            zapisu skrótów, dlatego też lektor powinien odwołać się do innych
            publikacji glottodydaktycznych, np. do wyjaśnień w lekcji o Janie
            Czochralskim z interaktywnego podręcznika „Polak potrafi” dostępnego
            tu: http://fundacjareja.eu/sylwetki-zbigniew-czochralski/.
            Odpowiedzi do ćwiczenia można znaleźć w kluczu.
            <br />
            <br />
            <b>
              <HashLink
                to="#cwiczenie-3"
                smooth
                scroll={(el) => scrollWithOffset(el)}
                className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
              >
                Ćwiczenie III
              </HashLink>
            </b>{" "}
            jest zadaniem z zakresu komunikacji częściowo sterowanej. Każdy ze
            studentów powinien wybrać sobie inny dzień tygodnia z kartki w
            kalendarzu i na podstawie haseł oraz równoważników zdań sformułować
            wypowiedź składającą się ze zdań złożonych wykorzystujących
            sugerowane łączniki. Studenci będą mieli czas, aby zastanowić się
            przez chwilę (ok. 10 min) nad wypowiedzią ustną. Następnie zostaną
            poproszeni o sformułowanie podobnej wypowiedzi dotyczącej ich
            wymarzonej pracy.
            <br />
            <br />
            <b>
              <HashLink
                to="#cwiczenie-4"
                smooth
                scroll={(el) => scrollWithOffset(el)}
                className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
              >
                Ćwiczenie IV
              </HashLink>
            </b>{" "}
            wprowadza nowe frazeologizmy. Może być wykonane indywidualnie lub na
            forum grupy. Należy wybrać najlepszą definicję dla każdego związku
            frazeologicznego. Poprawne odpowiedzi można znaleźć w kluczu. Aby
            przećwiczyć wykorzystanie tych jednostek w praktyce, należy poprosić
            studentów o to, żeby jeszcze raz opowiedzieli o wybranym dniu
            specjalisty ds. sieci społecznościowych lub o wymarzonej przez
            siebie pracy, i wykorzystali w tej opowieści co najmniej kilka
            frazeologizmów. Należy zwrócić uwagę studentów na to, że frazeologia
            jest typowa dla języka potocznego, mówionego, nieurzędowego.
            <br />
            <br />
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.mbs} id="cwiczenie-1">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie I.A
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            PISANIE: Uzupełnij przykładową umowę o pracę brakującymi słowami:
          </Typography>
          <br />
          <br />

          <Grid container className={classes.agreement}>
            <Typography>
              <Grid container>
                <Grid item xs={6}>
                  <Typography align="left" variant="body2">
                    „Ericsson” Sp. z o.o.
                    <br />
                    (pieczątka firmy)
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right" variant="body2">
                    Poznań, 28.04.2021
                    <br />
                    (..................................<sup>1</sup> i data)
                  </Typography>
                </Grid>
              </Grid>
              <br />
              <br />
              <Typography variant="body2" align="justify">
                <Typography variant="h5" align="center" gutterBottom>
                  UMOWA O PRACĘ NA CZAS …………………………………………<sup>2</sup>
                </Typography>
                <br />
                <Typography variant="body2" align="left">
                  ………………………………………………………<sup>3</sup> w dniu 28.04.2021 r.
                  pomiędzy:
                  <br />
                  „Ericsson” Sp. z o.o. z ………………………………<sup>4</sup> w Poznaniu
                  <br />
                  reprezentowaną przez Janusza Borowskiego
                  <br />a Grzegorzem Brzęczyszczykiewiczem, …………………………
                  <sup>5</sup> w Chrzęszczyżewoszycach 23-456 przy ul.
                  Szczebrzeszyńskiej 1 <br />
                  na czas nieokreślony.
                  <br />
                  <br />
                  <br />
                  I. Strony ustalają następujące …………………………………………………………
                  <sup>6</sup> zatrudnienia:
                  <br />
                  1) rodzaj ……………………………<sup>7</sup> pracy:
                  <br />
                  <i>
                    Specjalista ds. mediów ……………………………………………………………………
                    <sup>8</sup> z j. słowackim
                  </i>
                  <br />
                  2) miejsce wykonywania pracy:
                  <br />
                  <i>siedziba pracodawcy</i>
                  <br />
                  3) wymiar ……………………………<sup>9</sup> pracy:
                  <br />
                  <i>pełen wymiar czasu pracy</i>
                  <br />
                  4) wynagrodzenie
                  <br />
                  <i>
                    Zasadnicze w wysokości 4000 zł brutto miesięcznie (………………………
                    <sup>10</sup> : cztery tysiące złotych) oraz premia
                    regulaminowa
                  </i>
                  <br />
                  5) inne warunki zatrudnienia
                  <i>
                    <br />• ………………………………<sup>11</sup> za pracę wypłacane będzie
                    raz w miesiącu, nie później niż 15 dnia każdego miesiąca.
                    <br />• wypłata wynagrodzenia odbywać się będzie na podany
                    na ………………………<sup>12</sup> rachunek bankowy
                    <br />• długość ………………………………<sup>13</sup> wypowiedzenia
                    danej umowy wynosi 30 dni kalendarzowych.
                    <br />
                  </i>
                  6) dopuszczalna …………………………………<sup>14</sup> godzin pracy ponad
                  określony w umowie wymiar czasu pracy, których przekroczenie
                  …………………………………………<sup>15</sup> pracownika, oprócz normalnego
                  wynagrodzenia, do ………………………………<sup>16</sup>, o którym mowa w
                  art. 151 § 1 ………………………………<sup>17</sup> pracy - dotyczy umowy o
                  pracę z pracownikiem zatrudnionym w ……………………………<sup>18</sup>{" "}
                  wymiarze czasu pracy.
                  <br />
                  <br />
                  II. Termin rozpoczęcia pracy: 4 maja 2021 r.
                </Typography>
              </Typography>
              <br />
              <br />
              <Grid container>
                <Grid item xs={6}>
                  <Typography align="left" variant="body2">
                    ……………………………………………………
                    <br />
                    (data i ......................<sup>19</sup> pracownika)
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right" variant="body2">
                    ………………………………………………………………
                    <br />
                    (podpis pracodawcy lub osoby reprezentującej …………………………
                    <sup>20</sup>)
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
          </Grid>
          <Typography variant="body2" align="left">
            <br />
            <br />
            Na podstawie:
            <br />
            <Link href="http://www.inewi.pl" target="_blank" rel="noreferrer">
              www.inewi.pl
            </Link>
            ,{" "}
            <Link
              href="https://wojtowiczp.files.wordpress.com/2014/12/u2.jpg"
              target="_blank"
              rel="noreferrer"
            >
              https://wojtowiczp.files.wordpress.com/2014/12/u2.jpg
            </Link>
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data1}
        answers={answers1}
        mb={false}
        uniqueItemTypeName="c1"
      />
      <Card className={classes.mbl}>
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie I.B
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Jakie inne typy umów znasz?
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.mbs} id="cwiczenie-2">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie II.A
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            GRAMATYKA: Rozwiń poniższe skróty przy pomocy poniższych słów:
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data2}
        answers={answers2}
        mb={false}
        uniqueItemTypeName="c2"
      />
      <Card className={classes.mbl}>
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie II.B
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Sprawdź, czy skróty wymagają kropek, czy nie:
            <br />
            <br />
            <Link
              href="https://sjp.pwn.pl/so/sp-z-o-o;4514375.html"
              target="_blank"
              rel="noreferrer"
            >
              <Chip
                label="sp z o o"
                variant="outlined"
                className={classes.abbr}
              />
            </Link>
            <Link
              href="https://sjp.pwn.pl/so/br;4416851.html"
              target="_blank"
              rel="noreferrer"
            >
              <Chip label="br" variant="outlined" className={classes.abbr} />
            </Link>
            <Link
              href="https://sjp.pwn.pl/so/ds;4428116.html"
              target="_blank"
              rel="noreferrer"
            >
              <Chip label="ds" variant="outlined" className={classes.abbr} />
            </Link>
            <Link
              href="https://sjp.pwn.pl/so/zl;4541673.html"
              target="_blank"
              rel="noreferrer"
            >
              <Chip label="zł" variant="outlined" className={classes.abbr} />
            </Link>
            <Link
              href="https://sjp.pwn.pl/so/art;4411204.html"
              target="_blank"
              rel="noreferrer"
            >
              <Chip label="art" variant="outlined" className={classes.abbr} />
            </Link>
            <Link
              href="https://sjp.pwn.pl/so/dyr;4429159.html"
              target="_blank"
              rel="noreferrer"
            >
              <Chip label="dyr" variant="outlined" className={classes.abbr} />
            </Link>
            <Link
              href="https://sjp.pwn.pl/so/nr;4476452.html"
              target="_blank"
              rel="noreferrer"
            >
              <Chip label="nr" variant="outlined" className={classes.abbr} />
            </Link>
            <Link
              href="https://sjp.pwn.pl/so/pkt;4487275.html"
              target="_blank"
              rel="noreferrer"
            >
              <Chip label="pkt" variant="outlined" className={classes.abbr} />
            </Link>
            <Link
              href="https://sjp.pwn.pl/so/jw;4446901.html"
              target="_blank"
              rel="noreferrer"
            >
              <Chip label="jw" variant="outlined" className={classes.abbr} />
            </Link>
            <Link
              href="https://sjp.pwn.pl/so/itd;4444590.html"
              target="_blank"
              rel="noreferrer"
            >
              <Chip label="itd" variant="outlined" className={classes.abbr} />
            </Link>
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.mbl} id="cwiczenie-3">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie III
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            CZYTANIE/MÓWIENIE: Popatrz na poniższą kartkę z kalendarza z
            pierwszego tygodnia pracownika ds. sieci społecznościowych.
          </Typography>
          <Typography variant="body2" align="justify">
            <br />
            Wybierz dowolny dzień tygodnia i opowiedz w rozbudowany sposób (przy
            użyciu zdań złożonych) o tym, jak myślisz, że minął specjaliście ds.
            sieci społecznościowych. Spróbuj użyć spójników takich jak:{" "}
            <i>w związku z tym, podczas, w ciągu, mimo że, chociaż</i> itp.
            <br />
            <br />
            <Typography variant="h6" noWrap>
              <img
                alt="Zadanie"
                className={classes.lectureImg}
                src={process.env.PUBLIC_URL + "/images/c1.jpg"}
              />
            </Typography>
            Źródło:{" "}
            <Link href="https://canva.com" target="_blank" rel="noreferrer">
              canva.com
            </Link>
            <br />
            <br />
            <Typography variant="body1" align="left" gutterBottom>
              Jak wyglądałby pierwszy tydzień osoby, która wykonuje Twój
              wymarzony zawód?
            </Typography>
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.mbs} id="cwiczenie-4">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie IV.A
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            LEKSYKA: Popatrz na poniższe frazeologizmy umieszczone po lewej
            stronie. Do każdego z nich dobierz poprawne znaczenie.
          </Typography>
        </CardContent>
      </Card>
      <Exercise
        data={data3}
        answers={answers3}
        mb={false}
        uniqueItemTypeName="c3"
      />
      <Card className={classes.mbs} id="cwiczenie-5">
        <CardContent className={classes.cardContent}>
          <Typography
            variant="h6"
            align="left"
            className={classes.wrapIcon}
            gutterBottom
          >
            <BorderColorIcon /> Ćwiczenie IV.B
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Wybierz dowolne frazeologizmy, które pojawiły się w tej jednostce
            lekcyjnej opowiedz o dowolnym dniu z powyższego kalendarza przy ich
            użyciu.
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default Exercise2;
